import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import './FormulaItem.css'
import { FaWindowClose } from 'react-icons/fa'

const FormulaItem = ({ id, text, index, moveItem, onDelete }) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: 'formulaItem',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      moveItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'formulaItem',
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 1 : 1 }}
      className="flex items-center space-x-1 border p-1 rounded cursor-pointer draggable-item relative bg-white"
    >
      <span className="p-4 min-w-[90px]">{text}</span>
      <button onClick={() => onDelete(index)} className="text-red-500 absolute top-0 right-0">
        <FaWindowClose />
      </button>
    </div>
  )
}

export default FormulaItem
