import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

import { modifiedChartDataAccordingly } from '../../../../../config/Utils/Utils'

const mockData = [
  {
    financial_year: null,
    financial_quarter: null,
    financial_month: null,
    actual: null || 0,
    planned: null,
    status: null,
  },
]

const CustomLineChart = ({ chartDataArray }) => {
  // console.log(chartDataArray)
  return (
    <LineChart
      width={600}
      height={300}
      data={modifiedChartDataAccordingly(
        chartDataArray === (null || undefined) ? mockData : chartDataArray,
      )}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={'time'} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="planned" stroke="#8884d8" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey="actual" stroke="#82ca9d" />
    </LineChart>
  )
}

export default CustomLineChart
