import React from "react"

import SimpleDropdown from "../../../../shared/SimpleDropdown/SimpleDropdown"
import {
  productDropdownOptions,
  geographyDropdownOptions,
} from "../../Okr utils/dropdownOptionsInitialStateMap"
function Filter({
  isEditable,
  dropdowns,
  setDropdowns,
  shouldDropdownBeEnabled,
  handleDropdownChange,
}) {
  // console.log(dropdowns)
  return (
    <>
      <div className="ml-8 mb-8">
        <div className="label font-bold p-4 underline-offset-4 ">TIMESPAN</div>
        <div className="flex items-center">
          <div className="label font-bold text-xs p-4">FROM</div>
          <SimpleDropdown
            data={dropdowns["FROM-MONTH"].options}
            isDisabled={
              !isEditable ||
              !shouldDropdownBeEnabled({
                id: "TRACKING",
                comparator: "Monthly",
                dropdowns,
              })
            }
            onChange={(selectedOption) =>
              handleDropdownChange(selectedOption, "FROM-MONTH", setDropdowns)
            }
            placeholder={dropdowns["FROM-MONTH"].placeholder}
            value={dropdowns["FROM-MONTH"].value}
          ></SimpleDropdown>
          <SimpleDropdown
            data={dropdowns["FROM-QUARTER"].options}
            isDisabled={
              !isEditable ||
              !shouldDropdownBeEnabled({
                id: "TRACKING",
                comparator: "Quarterly",
                dropdowns,
              })
            }
            placeholder={dropdowns["FROM-QUARTER"].placeholder}
            onChange={(selectedOption) =>
              handleDropdownChange(selectedOption, "FROM-QUARTER", setDropdowns)
            }
            value={dropdowns["FROM-QUARTER"].value}
          ></SimpleDropdown>
          <SimpleDropdown
            data={dropdowns["FROM-YEAR"].options}
            isDisabled={
              !isEditable ||
              !shouldDropdownBeEnabled({
                id: "TRACKING",
                comparator: "Yearly",
                dropdowns,
              })
            }
            onChange={(selectedOption) =>
              handleDropdownChange(selectedOption, "FROM-YEAR", setDropdowns)
            }
            value={dropdowns["FROM-YEAR"].value}
            placeholder={dropdowns["FROM-YEAR"].placeholder}
          ></SimpleDropdown>
          <div className="label font-bold text-xs p-4 ml-16">To</div>
          <SimpleDropdown
            data={dropdowns["TO-MONTH"].options}
            isDisabled={
              !isEditable ||
              !shouldDropdownBeEnabled({
                id: "TRACKING",
                comparator: "Monthly",
                dropdowns,
              })
            }
            onChange={(selectedOption) =>
              handleDropdownChange(selectedOption, "TO-MONTH", setDropdowns)
            }
            value={dropdowns["TO-MONTH"].value}
            placeholder={dropdowns["TO-MONTH"].placeholder}
          ></SimpleDropdown>
          <SimpleDropdown
            data={dropdowns["TO-QUARTER"].options}
            isDisabled={
              !isEditable ||
              !shouldDropdownBeEnabled({
                id: "TRACKING",
                comparator: "Quarterly",
                dropdowns,
              })
            }
            onChange={(selectedOption) =>
              handleDropdownChange(selectedOption, "TO-QUARTER", setDropdowns)
            }
            value={dropdowns["TO-QUARTER"].value}
            placeholder={dropdowns["TO-QUARTER"].placeholder}
          ></SimpleDropdown>
          <SimpleDropdown
            data={dropdowns["TO-YEAR"].options}
            isDisabled={
              !isEditable ||
              !shouldDropdownBeEnabled({
                id: "TRACKING",
                comparator: "Yearly",
                dropdowns,
              })
            }
            onChange={(selectedOption) =>
              handleDropdownChange(selectedOption, "TO-YEAR", setDropdowns)
            }
            value={dropdowns["TO-YEAR"].value}
            placeholder={dropdowns["TO-YEAR"].placeholder}
          ></SimpleDropdown>
        </div>
      </div>

      {/* <div className="ml-8 flex mb-8 flex-col">
        <div className="flex items-center">
          <div className="label font-bold p-4 underline-offset-4 mr-4">Product Dimension&nbsp;</div>
          <SimpleDropdown
            isDisabled={!isEditable}
            customStype={'w-[400px]'}
            data={dropdowns['PRODUCT-DIMENSIONS'].options}
            onChange={selectedOption =>
              handleDropdownChange(selectedOption, 'PRODUCT-DIMENSIONS', setDropdowns)
            }
            value={dropdowns['PRODUCT-DIMENSIONS'].value}
          ></SimpleDropdown>
        </div>

        <div className="flex items-center">
          <div className="label font-bold p-4 underline-offset-4 ">Geography Dimension</div>
          <SimpleDropdown
            isDisabled={!isEditable}
            customStype={'w-[400px]'}
            data={dropdowns['GEOGRAPHY-DIMENSIONS'].options}
            onChange={selectedOption =>
              handleDropdownChange(selectedOption, 'GEOGRAPHY-DIMENSIONS', setDropdowns)
            }
            value={dropdowns['GEOGRAPHY-DIMENSIONS'].value}
          ></SimpleDropdown>
        </div>
      </div> */}
    </>
  )
}

export default Filter
