import axios from 'axios'
import Cookies from 'js-cookie'

import { API_ENDPOINT } from '../config'

export const axiosUtilsGet = async restUrl => {
  const res = await axios.get(API_ENDPOINT + restUrl, { withCredentials: true })
  return res.data.data
}
export const axiosUtilsPost = async (restUrl, data) => {
  const res = await axios.post(API_ENDPOINT + restUrl, data, { withCredentials: true })
  return res.data.data
}
export const axiosUtilsDelete = async restUrl => {
  const res = await axios.delete(API_ENDPOINT + restUrl, { withCredentials: true })
  return res.data.data
}

export const axiosErrorHandler = (err, navigate) => {
  if (err.response.status === 403) {
    process.env.REACT_APP_ENV === 'local'
      ? Cookies.remove('punt_actuals_access_token', { path: '/', domain: 'localhost' })
      : Cookies.remove('punt_actuals_access_token', {
          path: '/',
          domain: '.actuals.co.in',
        })
    navigate('/login')
  }
  //400 - Bad Request wrong data
  //401 - Unauthorized
  //403 - Forbidden
  //404 - Data Not Found
  //405 - Method Not Allowed
  //500 - Internal Server Error
}
