import React from 'react'
import { FaWindowMinimize } from 'react-icons/fa'
import { useOutletContext } from 'react-router-dom'

const RevenueChart = () => {
  const [hideHomeHeader] = useOutletContext()

  return (
    <>
      <div className={`h-${hideHomeHeader ? '[85vh]' : '[72vh]'} overflow-auto relative`}>
        <div className="flex justify-center items-center">
          <div className="p-6 rounded-xl shadow-md w-4/5 bg-blue-50 relative">
            <div className="mb-4 flex justify-between items-center m-4 ">
              <div>
                <label>FREQUENCY:</label>
                <select className="ml-2 p-1 border rounded">
                  <option>MONTHLY</option>
                  <option>QUARTERLY</option>
                  <option>YEARLY</option>
                </select>
              </div>
              <div className="absolute top-4 right-4">
                <FaWindowMinimize></FaWindowMinimize>
              </div>
            </div>
            <hr className="border-2 m-4"></hr>
            <div className="mb-4 flex justify-center items-center font-bold">
              <span>RULE3: NOV REVENUE - </span>
              <span>2CR</span>
            </div>

            <div className="mb-4">
              <div className="mb-4 font-bold">
                <label>DEVIATION</label>
              </div>
              <div className="grid grid-cols-1 gap-4 m-4">
                <div>
                  <label>TYPE:</label>
                  <select className="ml-2 p-1 border rounded">
                    <option>ABSOLUTE</option>
                    <option>RELATIVE</option>
                  </select>
                </div>
                <div>
                  <label>UOM:</label>
                  <select className="ml-2 p-1 border rounded">
                    <option>PERCENTAGE</option>
                    <option>NUMBER</option>
                  </select>
                  <span className="ml-2">| 2CR</span>
                </div>
                <div>
                  <label>TYPE:</label>
                  <select className="p-1 border rounded">
                    <option>POSITIVE</option>
                    <option>NEGATIVE</option>
                  </select>
                </div>
              </div>
            </div>

            <hr className="border-2 m-4"></hr>
            <div className="mb-4 flex justify-center items-center font-bold">
              <span>RULE4: CUSTOM PLOT - </span>
              <span>2CR</span>
            </div>

            <div className="mb-4">
              <div className="mb-4 font-bold">
                <label>RANGE</label>
              </div>
              <div className="grid grid-cols-1 gap-4 m-4">
                <div>
                  <label>TYPE:</label>
                  <select className="ml-2 p-1 border rounded">
                    <option>ABSOLUTE</option>
                    <option>RELATIVE</option>
                  </select>
                </div>
                <div>
                  <label>UOM:</label>
                  <select className="ml-2 p-1 border rounded">
                    <option>PERCENTAGE</option>
                    <option>NUMBER</option>
                  </select>
                  <span className="ml-2">| 2CR</span>
                </div>
                <div>
                  <label>TYPE:</label>
                  <select className="p-1 border rounded">
                    <option>POSITIVE</option>
                    <option>NEGATIVE</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="m-4 mb-10">
              <label>FREQUENCY:</label>
              <select className="ml-2 p-1 border rounded">
                <option>MONTHLY</option>
                <option>QUARTERLY</option>
                <option>YEARLY</option>
              </select>
            </div>
            <hr className="border-2 m-4"></hr>

            <div className="mb-4 flex justify-between">
              {[
                'JAN',
                'FEB',
                'MAR',
                'APR',
                'MAY',
                'JUN',
                'JUL',
                'AUG',
                'SEP',
                'OCT',
                'NOV',
                'DEC',
              ].map(month => (
                <span key={month}>{month}</span>
              ))}
            </div>

            <div className="relative h-48 mb-4">
              <div className=" text-xs">
                <span>PLANNED</span>
              </div>
              <svg width="900" height="50" xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="25" x2="900" y2="25" stroke="black" />
              </svg>

              <div className="text-xs">
                <span>ACTUAL</span>
              </div>
              <svg width="900" height="100" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 50 L150 50 L210 10 L270 90 L330 50 L390 50 L450 10 L510 90 L570 50 L630 50 L690 10 L750 90 L810 50 L870 50"
                  stroke="red"
                  fill="none"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <button className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700 absolute bottom-10 left-[45%]">
        +ADD RULE(S)
      </button>
    </>
  )
}

export default RevenueChart
