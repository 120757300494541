/* eslint-disable max-params */
import axios from "axios"
import { useNavigate } from "react-router-dom"

import {
  axiosErrorHandler,
  axiosUtilsPost,
} from "../../../../../config/Utils/AxiosUtils"
import { validateEmail } from "../../../../../config/Utils/Utils"

export const AccessControlsHandlers = (
  saveNewDimensions,
  setSaveNewDimensions,
  setEditIndex,
  setShowMessage,
  user,
  userRoles,
  saveNewUserRole,
  setSaveNewUserRole,
  setLoading,
  updateControls,
  setUpdateControls,
  setEditUserDetailsBtn,
  showInviteModal,
  setShowInviteModal,
  setOpenViewCard,
  emailId
) => {
  const navigate = useNavigate()
  const handleEdit = (index, value, whichDimension) => {
    const newData = [...saveNewDimensions]
    if (whichDimension === "geography") {
      newData[index].geography_id = value
    } else if (whichDimension === "product") {
      newData[index].product_id = value
    } else {
      newData[index].industry_id = value
    }
    setSaveNewDimensions(newData)
  }

  const handleDelete = (index) => {
    const newData = saveNewDimensions.filter((_, i) => i !== index)
    setEditIndex(100000)
    setSaveNewDimensions(newData)
  }
  const validationWhetherNotNullAndNotDuplicate = () => {
    // if (saveNewUserRole.length === 0) {
    //   return 3
    // }
    // if (saveNewDimensions.length === 0) {
    //   return 4
    // }
    // if (
    //   !saveNewDimensions.every(
    //     (item) => item.geography_id != null && item.product_id != null
    //   )
    // ) {
    //   return 1
    // }
    // const uniqueCombinations = new Set()
    // for (const item of saveNewDimensions) {
    //   // console.log(item)
    //   const combination = `${item.geography_id}-${item.product_id}-${item.industry_id}`
    //   if (uniqueCombinations.has(combination)) {
    //     return 2
    //   }
    //   uniqueCombinations.add(combination)
    // }
    return 0
  }

  const handleSaveButton = () => {
    const userRoles = saveNewUserRole.map((item) => ({ role_id: item.role_id }))
    // console.log(saveNewDimensions)
    const validateCode = validationWhetherNotNullAndNotDuplicate()
    // console.log(validateCode)
    if (validateCode === 0) {
      setEditIndex(null)
      setShowMessage("")
      setLoading(true)

      axiosUtilsPost("/api/users/details", {
        user_id: user.id,
        user_accesses: saveNewDimensions,
        user_roles: userRoles,
      })
        .then((res) => {
          setLoading(false)
          setEditUserDetailsBtn(false)
          setUpdateControls(!updateControls)
          // console.log(res)
        })
        .catch((err) => {
          setLoading(false)
          axiosErrorHandler(err, navigate)
        })
    } else if (validateCode === 3) {
      setShowMessage("*Please give some role to the user")
    } else if (validateCode === 4) {
      setShowMessage("*Please add dimensions")
    } else if (validateCode === 1) {
      setShowMessage("*Please fill all the dimensions")
    } else {
      setShowMessage("*Duplicate geography and product combination found")
    }
  }

  const handleInviteMemberButton = () => {
    setOpenViewCard(false)
    // console.log(emailId)
    // console.log(saveNewDimensions)
    // console.log(saveNewUserRole)
    const userRoles = saveNewUserRole.map((item) => ({ role_id: item.role_id }))

    const validEmail = validateEmail(emailId)
    const validCode = validationWhetherNotNullAndNotDuplicate()
    // console.log(validCode, validEmail)
    if (validEmail && validCode === 0) {
      setLoading(true)
      axiosUtilsPost("/api/users/invite/", {
        invite_url: process.env.REACT_APP_INVITE_BASE_URL,
        email_id: emailId,
        // roles: userRoles,
        roles: [
          {
            "role_id": 1
          }
        ],
        // user_accesses: saveNewDimensions,
        user_accesses: [
          {
            "geography_id": 1,
            "product_id": 1,
            "industry_id": 1
          }
        ],

      })
        .then((res) => {
          // console.log(res)
          setShowInviteModal(false)
          setUpdateControls(!updateControls)
          setLoading(false)
        })
        .catch((err) => {
          axiosErrorHandler(err, navigate)
          setShowMessage(err.response.data.message)
          setLoading(false)
        })
    } else if (!validEmail) {
      setShowMessage("*Please add correct email address")
    } else if (validCode === 3) {
      setShowMessage("*Please give some role to the user")
    } else if (validCode === 4) {
      setShowMessage("*Please add dimensions")
    } else if (validCode === 1) {
      setShowMessage("*Please fill all the dimensions!")
    } else {
      setShowMessage("*Duplicate geography and product combinations found")
    }
  }

  const handleCancelButton = () => {
    setShowMessage("")
    setSaveNewUserRole(userRoles)
    setEditUserDetailsBtn(false)
    setSaveNewDimensions(user?.user_accesses)
  }

  const handleAddNewDimension = () => {
    setEditIndex(100000)
    setSaveNewDimensions([
      ...saveNewDimensions,
      { geography_id: null, product_id: null },
    ])
  }

  return {
    handleEdit,
    handleDelete,
    handleSaveButton,
    handleCancelButton,
    handleAddNewDimension,
    handleInviteMemberButton,
  }
}
