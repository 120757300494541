// import React, { useEffect, useState } from 'react'
// import { FaPencilAlt } from 'react-icons/fa'
// import { FaPlus, FaMinus } from 'react-icons/fa6'
// import { MdDelete } from 'react-icons/md'

// import SimpleDropdown from '../../../shared/SimpleDropdown/SimpleDropdown'
// import { AccessControlsHandlers } from '../UserManagement/AccessControls/AccessControlsHandlers'
// import DimensionsAccessControl from '../UserManagement/AccessControls/DimensionsAccessControl'

// function ExpandableRow({
//   user,
//   isOpen,
//   toggleRow,
//   dimensions,
//   setLoading,
//   updateControls,
//   setUpdateControls,
// }) {
//   // const [editable, setEditable] = useState(false)
//   const [editIndex, setEditIndex] = useState(null)
//   const [saveNewDimensions, setSaveNewDimensions] = useState(user?.user_accesses)
//   const [showMessage, setShowMessage] = useState('')
//   const { handleEdit, handleDelete, handleSaveButton, handleCancelButton, handleAddNewDimension } =
//     AccessControlsHandlers(
//       saveNewDimensions,
//       setSaveNewDimensions,
//       setEditIndex,
//       setShowMessage,
//       user,
//       setLoading,
//       updateControls,
//       setUpdateControls,
//     )
//   return (
//     <div className="border-b  mx-2">
//       <div
//         className={`p-4 flex justify-between cursor-pointer rounded-md  ${
//           isOpen ? 'bg-blue-400 text-white' : 'hover:bg-blue-100'
//         }`}
//         onClick={toggleRow}
//       >
//         <span className={`${isOpen && 'text-white'} text-gray-600 font-semibold text-base`}>
//           {user.first_name + ' ' + user.last_name}
//         </span>
//         <span>{isOpen ? '▲' : '▼'}</span>
//       </div>
//       {isOpen && (
//         <div>
//           <p className="text-red-500 my-2 mx-3">{showMessage}</p>

//           <DimensionsAccessControl
//             handleAddNewDimension={handleAddNewDimension}
//             saveNewDimensions={saveNewDimensions}
//             editIndex={editIndex}
//             setEditIndex={setEditIndex}
//             dimensions={dimensions}
//             handleEdit={handleEdit}
//             handleDelete={handleDelete}
//           />
//           <div>
//             <button
//               className={` text-white rounded-md px-4 py-2 mb-5 ml-2 mt-5 ${
//                 !(editIndex != null)
//                   ? 'bg-gray-300 pointer-events-none'
//                   : ' bg-green-500 cursor-pointer'
//               }`}
//               onClick={handleSaveButton}
//             >
//               SAVE
//             </button>
//             <button
//               className={` text-white rounded-md px-4 py-2 mb-5 ml-5 mt-5 ${
//                 !(editIndex != null)
//                   ? 'bg-gray-300 pointer-events-none'
//                   : ' bg-red-500 cursor-pointer'
//               }`}
//               onClick={handleCancelButton}
//             >
//               CANCEL
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   )
// }

// export default ExpandableRow
