import Cookies from "js-cookie"
import React, { useState } from "react"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"

function normalizePath(path) {
  return path.replace(/\/+/g, "/")
}

const Auth = () => {
  const location = useLocation()
  const normalizedPath = normalizePath(location.pathname)
  if (location.search !== "" && location.pathname.includes("home")) {
    const searchParams = new URLSearchParams(location.search)

    const userId = searchParams.get("user_id")
    const isPasswordChanged = searchParams.get("is_password_changed")

    localStorage.setItem("userId", userId)
    localStorage.setItem("isPasswordChanged", isPasswordChanged)
    // /location.setItem('','')
    return <Navigate to="/home/connect/" />
  }
  localStorage.getItem("isPasswordChanged")

  const accessToken = Cookies.get("punt_actuals_access_token")
  // const refreshToken = Cookies.get('actuals_refresh_token')
  const hasChangedPassword = JSON.parse(
    localStorage.getItem("isPasswordChanged")
  )
  const hadUpdatedProfile = false
  // // console.log(process.env.REACT_APP_ENV)
  if (!accessToken) {
    if (process.env.REACT_APP_ENV === "local") {
      return <Outlet />
    } else {
      switch (normalizedPath) {
        case "/login/":
        case "/login":
          return <Outlet />

        default:
          return <Navigate to={"/login"} />
      }
    }
  } else {
    if (hasChangedPassword) {
      return normalizedPath.includes("home") ? (
        <Outlet />
      ) : (
        <Navigate to={"/home/connect/"} />
      )
    } else {
      return normalizedPath === "/login/resetpassword/" ||
        normalizedPath === "/login/resetpassword" ? (
        <Outlet />
      ) : (
        <Navigate to={"/login/resetpassword"} />
      )
    }
  }
}

export default Auth
