import React from 'react'

function ObjectiveExpandableRow({ name, isOpen, toggleRow, data }) {
  return (
    <div className="border-b-[1px] p-2">
      <div className="p-4 flex justify-start cursor-pointer" onClick={toggleRow}>
        <span className="mr-2">{!isOpen ? ' ► ' : ' ▼ '}</span>
        <span>{name}</span>
      </div>
      {isOpen && data}
    </div>
  )
}

export default ObjectiveExpandableRow
