// store.js
import { configureStore } from '@reduxjs/toolkit'

import dataReducer from './DataSlice'
import dimensionDatareducer from './DimensionsSlice'
import userInfoReducer from './UserInfoSlice'

const reduxStore = configureStore({
  reducer: {
    dataStoreReducer: dataReducer,
    dimensionDataStoreReducer: dimensionDatareducer,
    userInfoStoreReducer: userInfoReducer,
  },
})

export default reduxStore
