// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draggable-item {
    transition: all 1s ease;
    /* Adjust the duration and easing function as needed */
  }
`, "",{"version":3,"sources":["webpack://./src/components/Home/Okr/SharedOkrComponents/FormulaBuilder/FormulaItem.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sDAAsD;EACxD","sourcesContent":[".draggable-item {\n    transition: all 1s ease;\n    /* Adjust the duration and easing function as needed */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
