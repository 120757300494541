import React, { useEffect } from 'react'

import LabelDropDown from '../../../../shared/LabelDropDown/LabelDropDown'

function Specifics({ dropdowns, setDropdowns, handleDropdownChange, isEditable }) {
  return (
    <div className="flex ml-8">
      <LabelDropDown
        isDisabled={!isEditable}
        value={dropdowns.OWNER.value}
        label={dropdowns.OWNER.title}
        data={dropdowns.OWNER.options}
        onChange={selectedOption => handleDropdownChange(selectedOption, 'OWNER', setDropdowns)}
        // placeholder={dropdowns['OWNER'].placeholder}
      ></LabelDropDown>
      <LabelDropDown
        isDisabled={!isEditable}
        value={dropdowns.VISIBLITY.value}
        label={dropdowns.VISIBLITY.title}
        data={dropdowns.VISIBLITY.options}
        // placeholder={dropdowns['VISIBLITY'].placeholder}
        onChange={selectedOption => handleDropdownChange(selectedOption, 'VISIBLITY', setDropdowns)}
      ></LabelDropDown>
      <LabelDropDown
        isDisabled={!isEditable}
        value={dropdowns.TRACKING.value}
        label={dropdowns.TRACKING.title}
        data={dropdowns.TRACKING.options}
        // placeholder={dropdowns['TRACKING'].placeholder}
        onChange={selectedOption => handleDropdownChange(selectedOption, 'TRACKING', setDropdowns)}
      ></LabelDropDown>
    </div>
  )
}

export default Specifics
