import axios from "axios"
import Cookies from "js-cookie"
import React, { useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"

import profileImage from "../../assets/images/userProfileImage.png"
import "react-toastify/dist/ReactToastify.css"
import {
  axiosErrorHandler,
  axiosUtilsPost,
} from "../../config/Utils/AxiosUtils"

function Logout({ setModalOpen, isModalOpen, userDetails }) {
  const navigate = useNavigate()
  const [profileImageUrl, setProfileImageUrl] = useState(
    userDetails?.profile_picture_url || profileImage
  )
  const handleLogoutBtn = async () => {
    toast("You are logging out!")
    axiosUtilsPost("/api/users/logout", {})
      .then((data) => {
        process.env.REACT_APP_ENV === "local"
          ? Cookies.remove("punt_actuals_access_token", {
              path: "/",
              domain: "localhost",
            })
          : Cookies.remove("punt_actuals_access_token", {
              path: "/",
              domain: ".actuals.co.in",
            })
        navigate("/login")
      })
      .catch((err) => {
        axiosErrorHandler(err, navigate)
      })
  }
  return (
    <div className="flex items-center justify-center bg-gray-200">
      <ToastContainer className={"absolute z-[200]"} />
      {isModalOpen && (
        <div className="fixed top-[60px] right-[5px] flex items-center justify-center bg-opacity-40 z-[300] border-2 shadow-lg">
          <div className="bg-white rounded-lg w-[22.5rem] p-6 relative">
            {" "}
            {/* Add relative here for positioning */}
            {/* Close Button */}
            <button
              onClick={() => setModalOpen(false)}
              className="absolute top-2 right-2 px-3 py-1.5 hover:bg-gray-300"
              aria-label="Close modal"
            >
              &times;
            </button>
            <div className="flex items-center">
              <img
                src={profileImageUrl}
                onError={() => setProfileImageUrl(profileImage)}
                alt="profile_img"
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h2 className="text-xl font-bold">
                  {userDetails?.first_name + " " + userDetails?.last_name}
                </h2>
                <p className="text-gray-500">{userDetails?.email}</p>
              </div>
            </div>
            {/* <div className="mt-6 flex justify-between w-full"> */}
            {/* <Link to="/home/settings/user/user-management">
                <button className="px-2 py-2 border rounded mr-4 w-full text-sm text-sky-700">
                  User Settings
                </button>
              </Link> */}
            {/* <Link to="/home/settings/user/themes">
                <button className="px-2 py-2 border rounded text-sm  text-sky-700">Themes</button>
              </Link> */}
            {/* </div> */}
            <button
              onClick={() => handleLogoutBtn()}
              className="mt-6 w-[19.25rem] px-4 py-2 bg-gray-200 rounded"
            >
              LOG OUT
            </button>
            <div className="flex justify-between mt-6 text-sm text-gray-500">
              <a href="https://www.actuals.co.in/privacy.html">
                <p>Privacy policy</p>
              </a>
              <a href="https://www.actuals.co.in/tnc.html">
                <p>Terms of service</p>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Logout
