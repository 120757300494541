import React from 'react'
import { FaPencilAlt, FaPlus } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'

import SimpleDropdown from '../../../../shared/SimpleDropdown/SimpleDropdown'

const DimensionsAccessControl = ({
  isDisabled,
  saveNewDimensions,
  editIndex,
  dimensions,
  handleEdit,
  handleDelete,
  handleAddNewDimension,
}) => {
  return (
    <>
      {saveNewDimensions?.length !== 0 &&
        saveNewDimensions?.map((item, index) => {
          return (
            <div
              className="flex items-center my-4"
              key={item.geography_id + '-' + item.product_id + '-' + index}
            >
              <div>
                <p className="mx-3 font-semibold">Geography </p>
                <SimpleDropdown
                  isDisabled={isDisabled}
                  customStype={'w-[200px]'}
                  data={dimensions?.geographies}
                  onChange={selectedDimension =>
                    handleEdit(index, selectedDimension.id, 'geography')
                  }
                  value={dimensions?.geographies.find(items => items.id === item.geography_id)}
                />
              </div>
              <div>
                <p className="mx-3 font-semibold">Industry</p>
                <SimpleDropdown
                  isDisabled={isDisabled}
                  customStype={'w-[200px]'}
                  data={dimensions.industries}
                  onChange={selectedDimension => handleEdit(index, selectedDimension.id, 'industry')}
                  value={dimensions?.industries.find(items => items.id === item.industry_id)}
                />
              </div>
              <div>
                <p className="mx-3 font-semibold">Business Line</p>
                <SimpleDropdown
                  isDisabled={isDisabled}
                  customStype={'w-[200px]'}
                  data={dimensions.products}
                  onChange={selectedDimension => handleEdit(index, selectedDimension.id, 'product')}
                  value={dimensions?.products.find(items => items.id === item.product_id)}
                />
              </div>

              {!isDisabled && (
                <div className="flex ">
                  {/* <FaPencilAlt
                  className="flex mx-4 cursor-pointer"
                  onClick={e => setEditIndex(index)}
                /> */}
                  <MdDelete
                    className="mx-4 cursor-pointer text-red-400 mt-4"
                    size={20}
                    onClick={e => {
                      handleDelete(index)
                    }}
                  />
                </div>
              )}
            </div>
          )
        })}
      {!isDisabled && (
        <button
          className="text-blue-500 rounded-md px-4 py-2 mb-5 ml-2 mt-5 bg-blue-100 flex"
          onClick={handleAddNewDimension}
        >
          <FaPlus size={'1.25em'} className="mx-1" />
          Add Dimensions
        </button>
      )}
    </>
  )
}

export default DimensionsAccessControl
