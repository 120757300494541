import React from 'react'
import Select from 'react-select'

const LabelDropDown = ({ label = '', data, onChange, value, placeholder, isDisabled }) => {
  return (
    <div className="mr-[1rem] flex items-center">
      <div className="label font-bold text-xs p-2">{label}</div>
      <div>
        <Select
          getOptionLabel={option => option.name}
          className="rounded p-2 m-2 w-[128px]"
          options={data}
          onChange={onChange}
          value={value}
          // value={data?.some(option => option?.id === value?.id) ? value : null}
          placeholder={placeholder}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  )
}

export default LabelDropDown
