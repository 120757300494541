import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { initializeAnalytics } from './analytics'
import LoginPage from './components/LoginPage/LoginPage'
import './index.css'

function App() {
  useEffect(() => {
    const hostname = window.location.hostname

    if (hostname.includes('demo.actuals.co.in')) {
      initializeAnalytics('G-5J52DFTWW6')
    }
  }, [])
  return <Navigate to="/login" />
}

export default App
