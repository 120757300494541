import { createSlice } from '@reduxjs/toolkit'

import profileImage from '../../assets/images/userProfileImage.png'

const initialState = {
  userDetails: {
    id: '',
    email: 'user@actuals.co.in',
    first_name: '',
    last_name: '',
    profile_picture_url: profileImage,
  },
}

const UserInfoSlice = createSlice({
  name: 'userDetailsSlice',
  initialState,
  reducers: {
    setUserDetailsFromSlice: (state, action) => {
      state.userDetails = action.payload
    },
  },
})

export const { setUserDetailsFromSlice } = UserInfoSlice.actions
export default UserInfoSlice.reducer
