import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useState, useReducer, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'

import { reducer, initialState, ACTION_TYPE } from './ChangePasswordReducerVariable'
import InputPassword from './InputPassword'
import '../ChangePassword/ChangePassword.css'
import { axiosErrorHandler, axiosUtilsPost } from '../../config/Utils/AxiosUtils'

const ChangePassword = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const navigate = useNavigate()
  const handleOnChangePassword = e => {
    // console.log(state.oldPassword, state.newPassword, state.reNewPassword)

    dispatch({ type: ACTION_TYPE.isClickedChangedPassword })
    axiosUtilsPost('/api/users/reset-password', {
      id: localStorage.getItem('userId'),
      old_password: state.oldPassword,
      new_password: state.newPassword,
      repeated_new_password: state.reNewPassword,
    })
      .then(function (response) {
        // // console.log('Response: ', response)
        // localStorage.setItem('isPasswordChanged', true)
        // dispatch({ type: ACTION_TYPE.isClickedChangedPassword })
        // if (response?.data?.status === 'error') {
        //   dispatch({ type: ACTION_TYPE.displayMessage, value: response?.data?.message })
        // } else {
        //   navigate('/home')
        // }
        // // console.log(Cookies.get('actuals_jwt'))
        // localStorage.setItem('isLoggedIn',true)
      })
      .catch(function (error) {
        axiosErrorHandler(error, navigate)
        dispatch({ type: ACTION_TYPE.isClickedChangedPassword })
        // dispatch({type:ACTION_TYPE.displayMessage, value: error?.response?.data?.message})
        // dispatch({type:ACTION_TYPE.isClickedLogInBtn})
      })
  }

  return (
    <div className="resetpassword_container_css  mt-[-50px] lg:mt-[-20px]">
      <div>
        <img
          src={require('../../assets/images/logo actuals transparent.png')}
          alt="actuals_logo"
          className="w-[200px] my-[30px]"
        />
      </div>
      <div className=" p-[20px] shadow-2xl rounded-3xl">
        <p className="font-bold text-center text-lg">Change Your Password</p>
        <div className="w-[50%]">
          <p className="mt-5 text-sm text-red-500 ">{state.displayMessage}</p>
        </div>
        <InputPassword
          type={ACTION_TYPE.oldPassword}
          name={'Old Password'}
          value={state.oldPassword}
          placeholder={'Enter your old password'}
          dispatch={dispatch}
        />
        <InputPassword
          type={ACTION_TYPE.newPassword}
          name={'New Password'}
          value={state.newPassword}
          placeholder={'Enter your new password'}
          dispatch={dispatch}
        />
        <InputPassword
          type={ACTION_TYPE.reNewPassword}
          name={'Re-Enter New Password'}
          value={state.reNewPassword}
          placeholder={'Re-Enter your new password'}
          dispatch={dispatch}
        />
        <div className="text-red-500 text-xs">
          {state.newPassword !== state.reNewPassword &&
            state.newPassword !== '' &&
            '*New Password should Match'}
          {state.newPassword === state.oldPassword &&
            state.reNewPassword === state.oldPassword &&
            state.newPassword !== '' &&
            "*New Password can't be same as Old!"}
        </div>
        <button
          disabled={!(state.newPassword === state.reNewPassword && state.newPassword !== '')}
          className="mt-[30px] mb-[10px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-[250px]  md:w-[300px] lg:w-[400px] cursor-pointer"
          onClick={handleOnChangePassword}
        >
          {!state.isClickedChangedPassword ? (
            'Change Password!'
          ) : (
            <ClipLoader color="#FFF" size={20} />
          )}
        </button>
      </div>
      <div className="bottom-[10px] fixed">© 2023 Actuals, Inc. All Right Reserved | Privacy</div>
    </div>
  )
}

export default ChangePassword
