import React, { useState } from 'react'

const InviteLinkExpired = () => {
  const searchParams = new URLSearchParams(location.search)
  const errorCode = searchParams.get('error_code')
  let message = ''

  if (errorCode == 0) {
    // user not invited
    message = 'You are not invited! Please contact your administrator. '
  } else if (errorCode == 1) {
    // user didnt accept the invitation through link
    message = 'Please accept invitation link from the mail!'
  } else if (errorCode == 2) {
    // link got expired
    message = 'Invitation link expired! Please contact your administrator.'
  } else {
    message = 'Please contact your administrator.'
  }
  return (
    <div className="flex flex-col justify-center items-center">
      <img
        src={require('../../assets/images/link_expired.png')}
        className="max-h-[500px] max-w-[500px]"
      />
      <div className="border border-gray-100 rounded-md ">
        <p className="text-gray-600 px-5 py-3 text-lg font-semibold">{message}</p>
      </div>
    </div>
  )
}

export default InviteLinkExpired
