export const initialState = {
  oldPassword: '',
  newPassword: '',
  reNewPassword: '',
  displayMessage: '',
  isClickedChangedPassword: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.oldPassword:
      return { ...state, oldPassword: action.value }
    case ACTION_TYPE.newPassword:
      return { ...state, newPassword: action.value }
    case ACTION_TYPE.reNewPassword:
      return { ...state, reNewPassword: action.value }
    case ACTION_TYPE.displayMessage:
      return { ...state, displayMessage: action.value }
    case ACTION_TYPE.isClickedChangedPassword:
      return { ...state, isClickedChangedPassword: !state.isClickedChangedPassword }
    default:
      return state
  }
}

export const ACTION_TYPE = {
  oldPassword: 'oldPassword',
  newPassword: 'newPassword',
  reNewPassword: 'reNewPassword',
  displayMessage: 'displayMessage',
  isClickedChangedPassword: 'isClickedChangedPassword',
}
