import React from 'react'

const Spinner = () => {
  return (
    <div className=" absolute top-0 left-0 h-full w-full z-20 flex justify-center items-center ">
      <img
        src={require('../../../assets/images/logo_icon_actuals.png')}
        alt="Loading"
        className="flex spinner justify-center items-center opacity-100 w-[60px] h-[60px]"
        style={{ filter: 'none' }}
      />
    </div>
  )
}

export default Spinner
