/* eslint-disable max-statements */
export const handleDropdownChange = (selectedOption, dropdownTitle, setDropdowns) => {
  // // console.log(selectedOption)
  if (dropdownTitle === 'OWNER') {
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
          ownerId: selectedOption.id,
        },
      }
    })
  }

  if (dropdownTitle === 'OBJECTIVE' || dropdownTitle === 'VISIBLITY') {
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
        },
      }
    })
  }
  if (dropdownTitle === 'TRACKING') {
    if (selectedOption.value === 'yearly') {
      setDropdowns(prevDropdowns => {
        return {
          ...prevDropdowns,
          [dropdownTitle]: {
            ...prevDropdowns[dropdownTitle],
            value: selectedOption,
          },
          'FROM-MONTH': {
            ...prevDropdowns['FROM-MONTH'],
            value: null,
          },
          'FROM-QUARTER': {
            ...prevDropdowns['FROM-QUARTER'],
            value: null,
          },
          'TO-MONTH': {
            ...prevDropdowns['TO-MONTH'],
            value: null,
          },
          'TO-QUARTER': {
            ...prevDropdowns['TO-QUARTER'],
            value: null,
          },
        }
      })
    }
    if (selectedOption.value === 'quarterly') {
      setDropdowns(prevDropdowns => {
        return {
          ...prevDropdowns,
          [dropdownTitle]: {
            ...prevDropdowns[dropdownTitle],
            value: selectedOption,
          },
          'FROM-MONTH': {
            ...prevDropdowns['FROM-MONTH'],
            value: null,
          },
          'TO-MONTH': {
            ...prevDropdowns['TO-MONTH'],
            value: null,
          },
        }
      })
    }
    if (selectedOption.value === 'monthly') {
      setDropdowns(prevDropdowns => {
        return {
          ...prevDropdowns,
          [dropdownTitle]: {
            ...prevDropdowns[dropdownTitle],
            value: selectedOption,
          },
          'FROM-QUARTER': {
            ...prevDropdowns['FROM-QUARTER'],
            value: null,
          },
          'TO-QUARTER': {
            ...prevDropdowns['TO-QUARTER'],
            value: null,
          },
        }
      })
    }
  }
  if (dropdownTitle === 'TO-MONTH') {
    // // console.log(selectedOption)
    const quarter = Math.floor((selectedOption.value + 2) / 3)
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
        },
        'TO-QUARTER': {
          ...prevDropdowns['TO-QUARTER'],
          value: { value: quarter, name: 'Q' + quarter },
        },
      }
    })
  }
  if (dropdownTitle === 'FROM-MONTH') {
    const quarter = Math.floor((selectedOption.value + 2) / 3)
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
        },
        'FROM-QUARTER': {
          ...prevDropdowns['FROM-QUARTER'],
          value: { value: quarter, name: 'Q' + quarter },
        },
      }
    })
  }
  if (dropdownTitle === 'FROM-QUARTER') {
    // console.log(selectedOption)
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
        },
      }
    })
  }
  if (dropdownTitle === 'FROM-YEAR') {
    // console.log(selectedOption)
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
        },
      }
    })
  }
  if (dropdownTitle === 'TO-QUARTER') {
    // console.log(selectedOption)
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
        },
      }
    })
  }
  if (dropdownTitle === 'TO-YEAR') {
    // console.log(selectedOption)
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
        },
      }
    })
  }
  if (dropdownTitle === 'PRODUCT-DIMENSIONS' || dropdownTitle === 'GEOGRAPHY-DIMENSIONS') {
    setDropdowns(prevDropdowns => {
      return {
        ...prevDropdowns,
        [dropdownTitle]: {
          ...prevDropdowns[dropdownTitle],
          value: selectedOption,
        },
      }
    })
  }
}

export const shouldDropdownBeEnabled = ({ id, comparator, dropdowns }) => {
  const label = dropdowns[id].value
  if (label?.value === 'yearly' && comparator === 'Yearly') {
    return true
  }
  if (label?.value === 'quarterly' && (comparator === 'Yearly' || comparator === 'Quarterly')) {
    return true
  }
  if (label?.value === 'monthly' && (comparator === 'Yearly' || comparator === 'Monthly')) {
    return true
  }
}

export const checkingNotNull = (dropdowns, formula, comparator) => {
  // const checking = false
  //false if it is null
  //true for not null
  const trackingValue = dropdowns?.TRACKING?.value?.value
  if (dropdowns.OBJECTIVE.value.trim() === '') {
    return ['*Please fill the Objective', false]
  }
  if (dropdowns.OWNER.value === null) {
    return ['*Please select owner in SPECIFICS section', false]
  }
  if (dropdowns.VISIBLITY.value === null) {
    return ['*Please select visiblity in SPECIFICS section', false]
  }
  if (dropdowns.TRACKING.value === null) {
    return ['*Please select tracking in SPECIFICS section', false]
  }
  if (formula.length === 0) {
    return ['*Please fill the formula in DEFINE section', false]
  }
  if (!isValidFormula(formula.map(item => item.slug))) {
    return ['*Please write correct formula in DEFINE section', false]
  }
  if (comparator === null) {
    return ['*Please select a comparator in DEFINE section', false]
  }
  if (
    (trackingValue === 'yearly' &&
      (dropdowns['TO-YEAR'].value === null || dropdowns['FROM-YEAR'].value === null)) ||
    (trackingValue === 'quarterly' &&
      (dropdowns['TO-YEAR'].value === null || dropdowns['FROM-YEAR'].value === null)) ||
    (trackingValue === 'monthly' &&
      (dropdowns['TO-YEAR'].value === null || dropdowns['FROM-YEAR'].value === null))
  ) {
    return ['*Please select year in FILTER section!', false]
  }
  if (
    trackingValue === 'quarterly' &&
    (dropdowns['TO-QUARTER'].value === null || dropdowns['FROM-QUARTER'].value === null)
  ) {
    return ['*Please select quarter in FILTER section!', false]
  }
  if (
    trackingValue === 'monthly' &&
    (dropdowns['TO-MONTH'].value === null || dropdowns['FROM-MONTH'].value === null)
  ) {
    return ['*Please select month in FILTER section!', false]
  }

  if (
    trackingValue === 'yearly' &&
    dropdowns['TO-YEAR'].value.value < dropdowns['FROM-YEAR'].value.value
  ) {
    return ['*start year should be less than or equal to end year', false]
  }
  if (trackingValue === 'quarterly') {
    if (dropdowns['TO-YEAR'].value.value < dropdowns['FROM-YEAR'].value.value) {
      return ['*start year should be less than or equal to end year', false]
    }
    if (
      dropdowns['TO-YEAR'].value.value === dropdowns['FROM-YEAR'].value.value &&
      dropdowns['TO-QUARTER'].value.value < dropdowns['FROM-QUARTER'].value.value
    ) {
      return ['*start quarter should be less than or equal to end quarter in same year', false]
    }
  }
  if (trackingValue === 'monthly') {
    if (dropdowns['TO-YEAR'].value.value < dropdowns['FROM-YEAR'].value.value) {
      return ['*start year should be less than or equal to end year', false]
    }
    if (
      dropdowns['TO-YEAR'].value.value === dropdowns['FROM-YEAR'].value.value &&
      dropdowns['TO-MONTH'].value.value < dropdowns['FROM-MONTH'].value.value
    ) {
      return ['*start month should be less than or equal to end month in same year', false]
    }
  }

  // if (dropdowns['PRODUCT-DIMENSIONS'].value === null) {
  //   return ['*Please select product dimensions in FILTER section!', false]
  // }
  // if (dropdowns['GEOGRAPHY-DIMENSIONS'].value === null) {
  //   return ['*Please select geography dimensions in FILTER section!', false]
  // }

  return ['', true]
}
function isValidFormula(formulaArray) {
  const operators = new Set(['+', '-', '*', '/'])
  const stack = []
  let previousWasOperator = true // Start with true to expect an operand first

  for (let i = 0; i < formulaArray.length; i++) {
    const token = formulaArray[i]

    if (token === '(') {
      stack.push(token)
      previousWasOperator = true // Expect operand or '(' after '('
    } else if (token === ')') {
      if (stack.length === 0 || previousWasOperator) {
        return false // Unbalanced parenthesis or operator before ')'
      }
      stack.pop()
      previousWasOperator = false // A ')' acts like an operand for the next token
    } else if (operators.has(token)) {
      if (previousWasOperator) {
        return false // Two operators in a row or operator after '('
      }
      previousWasOperator = true
    } else {
      if (!previousWasOperator) {
        return false // Two operands in a row
      }
      previousWasOperator = false
    }
  }

  return stack.length === 0 && !previousWasOperator // Check for unbalanced parentheses and trailing operator
}
