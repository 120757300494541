export const requestDataForm = {
  years: [
    {
      fy: '',
      opened: false,
      quarters: [
        {
          fy: '',
          fq: 1,
          opened: false,
          months: [
            { fy: '', fq: 1, fm: 4 },
            { fy: '', fq: 1, fm: 5 },
            { fy: '', fq: 1, fm: 6 },
          ],
        },
        {
          fy: '',
          fq: 2,
          opened: false,
          months: [
            { fy: '', fq: 2, fm: 7 },
            { fy: '', fq: 2, fm: 8 },
            { fy: '', fq: 2, fm: 9 },
          ],
        },
        {
          fy: '',
          fq: 3,
          opened: false,
          months: [
            { fy: '', fq: 3, fm: 10 },
            { fy: '', fq: 3, fm: 11 },
            { fy: '', fq: 3, fm: 12 },
          ],
        },
        {
          fy: '',
          fq: 4,
          opened: false,
          months: [
            { fy: '', fq: 4, fm: 1 },
            { fy: '', fq: 4, fm: 2 },
            { fy: '', fq: 4, fm: 3 },
          ],
        },
      ],
    },
  ],
}

export const mockDataRevenue = {
  message: 'success',
  status: 'ok',
  error: '',
  data: {
    data: [
      {
        label: 'Revenue',
        data: {
          actual: [
            {
              fy: 2022,
              fq: null,
              fm: null,
              value: '3757998.80',
              quarters: [
                {
                  fy: 2022,
                  fq: 4,
                  fm: null,
                  value: '3757998.80',
                  months: [
                    {
                      fy: 2022,
                      fq: 4,
                      fm: 10,
                      value: '1149082.63',
                    },
                    {
                      fy: 2022,
                      fq: 4,
                      fm: 11,
                      value: '1026766.49',
                    },
                    {
                      fy: 2022,
                      fq: 4,
                      fm: 12,
                      value: '1582149.68',
                    },
                  ],
                },
              ],
            },
            {
              fy: 2023,
              fq: null,
              fm: null,
              value: '8171408.80',
              quarters: [
                {
                  fy: 2023,
                  fq: 1,
                  fm: null,
                  value: '3594152.04',
                  months: [
                    {
                      fy: 2023,
                      fq: 1,
                      fm: 1,
                      value: '940282.95',
                    },
                    {
                      fy: 2023,
                      fq: 1,
                      fm: 2,
                      value: '1375390.38',
                    },
                    {
                      fy: 2023,
                      fq: 1,
                      fm: 3,
                      value: '1278478.71',
                    },
                  ],
                },
                {
                  fy: 2023,
                  fq: 2,
                  fm: null,
                  value: '3737337.26',
                  months: [
                    {
                      fy: 2023,
                      fq: 2,
                      fm: 4,
                      value: '1303152.51',
                    },
                    {
                      fy: 2023,
                      fq: 2,
                      fm: 5,
                      value: '1221998.02',
                    },
                    {
                      fy: 2023,
                      fq: 2,
                      fm: 6,
                      value: '1212186.73',
                    },
                  ],
                },
                {
                  fy: 2023,
                  fq: 3,
                  fm: null,
                  value: '839919.50',
                  months: [
                    {
                      fy: 2023,
                      fq: 3,
                      fm: 7,
                      value: '839919.50',
                    },
                  ],
                },
              ],
            },
          ],
          planned: [
            {
              fy: 2022,
              fq: null,
              fm: null,
              value: '3757998.80',
              quarters: [
                {
                  fy: 2022,
                  fq: 4,
                  fm: null,
                  value: '3757998.80',
                  months: [
                    {
                      fy: 2022,
                      fq: 4,
                      fm: 10,
                      value: '1149082.63',
                    },
                    {
                      fy: 2022,
                      fq: 4,
                      fm: 11,
                      value: '1026766.49',
                    },
                    {
                      fy: 2022,
                      fq: 4,
                      fm: 12,
                      value: '1582149.68',
                    },
                  ],
                },
              ],
            },
            {
              fy: 2023,
              fq: null,
              fm: null,
              value: '8171408.80',
              quarters: [
                {
                  fy: 2023,
                  fq: 1,
                  fm: null,
                  value: '3594152.04',
                  months: [
                    {
                      fy: 2023,
                      fq: 1,
                      fm: 1,
                      value: '940282.95',
                    },
                    {
                      fy: 2023,
                      fq: 1,
                      fm: 2,
                      value: '1375390.38',
                    },
                    {
                      fy: 2023,
                      fq: 1,
                      fm: 3,
                      value: '1278478.71',
                    },
                  ],
                },
                {
                  fy: 2023,
                  fq: 2,
                  fm: null,
                  value: '3737337.26',
                  months: [
                    {
                      fy: 2023,
                      fq: 2,
                      fm: 4,
                      value: '1303152.51',
                    },
                    {
                      fy: 2023,
                      fq: 2,
                      fm: 5,
                      value: '1221998.02',
                    },
                    {
                      fy: 2023,
                      fq: 2,
                      fm: 6,
                      value: '1212186.73',
                    },
                  ],
                },
                {
                  fy: 2023,
                  fq: 3,
                  fm: null,
                  value: '839919.50',
                  months: [
                    {
                      fy: 2023,
                      fq: 3,
                      fm: 7,
                      value: '839919.50',
                    },
                  ],
                },
              ],
            },
          ],
        },
        children: [
          {
            label: 'Volume',
            data: {
              actual: [
                {
                  fy: 2022,
                  fq: null,
                  fm: null,
                  value: '350.00',
                  quarters: [
                    {
                      fy: 2022,
                      fq: 4,
                      fm: null,
                      value: '350.00',
                      months: [
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 10,
                          value: '103.00',
                        },
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 11,
                          value: '101.00',
                        },
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 12,
                          value: '146.00',
                        },
                      ],
                    },
                  ],
                },
                {
                  fy: 2023,
                  fq: null,
                  fm: null,
                  value: '770.00',
                  quarters: [
                    {
                      fy: 2023,
                      fq: 1,
                      fm: null,
                      value: '346.00',
                      months: [
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 1,
                          value: '92.00',
                        },
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 2,
                          value: '130.00',
                        },
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 3,
                          value: '124.00',
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: 2,
                      fm: null,
                      value: '346.00',
                      months: [
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 4,
                          value: '117.00',
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 5,
                          value: '110.00',
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 6,
                          value: '119.00',
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: 3,
                      fm: null,
                      value: '78.00',
                      months: [
                        {
                          fy: 2023,
                          fq: 3,
                          fm: 7,
                          value: '78.00',
                        },
                      ],
                    },
                  ],
                },
              ],
              planned: [
                {
                  fy: 2022,
                  fq: null,
                  fm: null,
                  value: '350.00',
                  quarters: [
                    {
                      fy: 2022,
                      fq: 4,
                      fm: null,
                      value: '350.00',
                      months: [
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 10,
                          value: '103.00',
                        },
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 11,
                          value: '101.00',
                        },
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 12,
                          value: '146.00',
                        },
                      ],
                    },
                  ],
                },
                {
                  fy: 2023,
                  fq: null,
                  fm: null,
                  value: '770.00',
                  quarters: [
                    {
                      fy: 2023,
                      fq: 1,
                      fm: null,
                      value: '346.00',
                      months: [
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 1,
                          value: '92.00',
                        },
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 2,
                          value: '130.00',
                        },
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 3,
                          value: '124.00',
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: 2,
                      fm: null,
                      value: '346.00',
                      months: [
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 4,
                          value: '117.00',
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 5,
                          value: '110.00',
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 6,
                          value: '119.00',
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: 3,
                      fm: null,
                      value: '78.00',
                      months: [
                        {
                          fy: 2023,
                          fq: 3,
                          fm: 7,
                          value: '78.00',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            children: [],
          },
          {
            label: 'Average Price',
            data: {
              actual: [
                {
                  fy: 2022,
                  fq: null,
                  fm: null,
                  value: '10737.14',
                  quarters: [
                    {
                      fy: 2022,
                      fq: 4,
                      fm: null,
                      value: '10737.14',
                      months: [
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 10,
                          value: '11156.14',
                        },
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 11,
                          value: '10166.00',
                        },
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 12,
                          value: '10836.64',
                        },
                      ],
                    },
                  ],
                },
                {
                  fy: 2023,
                  fq: null,
                  fm: null,
                  value: '10612.22',
                  quarters: [
                    {
                      fy: 2023,
                      fq: 1,
                      fm: null,
                      value: '10387.72',
                      months: [
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 1,
                          value: '10220.47',
                        },
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 2,
                          value: '10579.93',
                        },
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 3,
                          value: '10310.31',
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: 2,
                      fm: null,
                      value: '10801.55',
                      months: [
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 4,
                          value: '11138.06',
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 5,
                          value: '11109.07',
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 6,
                          value: '10186.44',
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: 3,
                      fm: null,
                      value: '10768.20',
                      months: [
                        {
                          fy: 2023,
                          fq: 3,
                          fm: 7,
                          value: '10768.20',
                        },
                      ],
                    },
                  ],
                },
              ],
              planned: [
                {
                  fy: 2022,
                  fq: null,
                  fm: null,
                  value: '10737.14',
                  quarters: [
                    {
                      fy: 2022,
                      fq: 4,
                      fm: null,
                      value: '10737.14',
                      months: [
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 10,
                          value: '11156.14',
                        },
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 11,
                          value: '10166.00',
                        },
                        {
                          fy: 2022,
                          fq: 4,
                          fm: 12,
                          value: '10836.64',
                        },
                      ],
                    },
                  ],
                },
                {
                  fy: 2023,
                  fq: null,
                  fm: null,
                  value: '10612.22',
                  quarters: [
                    {
                      fy: 2023,
                      fq: 1,
                      fm: null,
                      value: '10387.72',
                      months: [
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 1,
                          value: '10220.47',
                        },
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 2,
                          value: '10579.93',
                        },
                        {
                          fy: 2023,
                          fq: 1,
                          fm: 3,
                          value: '10310.31',
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: 2,
                      fm: null,
                      value: '10801.55',
                      months: [
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 4,
                          value: '11138.06',
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 5,
                          value: '11109.07',
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: 6,
                          value: '10186.44',
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: 3,
                      fm: null,
                      value: '10768.20',
                      months: [
                        {
                          fy: 2023,
                          fq: 3,
                          fm: 7,
                          value: '10768.20',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            children: [
              {
                label: 'Sales Lost',
                data: {
                  actual: [
                    {
                      fy: 2022,
                      fq: null,
                      fm: null,
                      value: '973246.14',
                      quarters: [
                        {
                          fy: 2022,
                          fq: 4,
                          fm: null,
                          value: '973246.14',
                          months: [
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 10,
                              value: '307108.09',
                            },
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 11,
                              value: '336392.00',
                            },
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 12,
                              value: '329746.05',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: null,
                      fm: null,
                      value: '2261820.77',
                      quarters: [
                        {
                          fy: 2023,
                          fq: 1,
                          fm: null,
                          value: '936441.16',
                          months: [
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 1,
                              value: '304198.46',
                            },
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 2,
                              value: '334442.15',
                            },
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 3,
                              value: '297800.55',
                            },
                          ],
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: null,
                          value: '1080549.68',
                          months: [
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 4,
                              value: '340267.66',
                            },
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 5,
                              value: '344933.76',
                            },
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 6,
                              value: '395348.26',
                            },
                          ],
                        },
                        {
                          fy: 2023,
                          fq: 3,
                          fm: null,
                          value: '244829.93',
                          months: [
                            {
                              fy: 2023,
                              fq: 3,
                              fm: 7,
                              value: '244829.93',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                  planned: [
                    {
                      fy: 2022,
                      fq: null,
                      fm: null,
                      value: '973246.14',
                      quarters: [
                        {
                          fy: 2022,
                          fq: 4,
                          fm: null,
                          value: '973246.14',
                          months: [
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 10,
                              value: '307108.09',
                            },
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 11,
                              value: '336392.00',
                            },
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 12,
                              value: '329746.05',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: null,
                      fm: null,
                      value: '2261820.77',
                      quarters: [
                        {
                          fy: 2023,
                          fq: 1,
                          fm: null,
                          value: '936441.16',
                          months: [
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 1,
                              value: '304198.46',
                            },
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 2,
                              value: '334442.15',
                            },
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 3,
                              value: '297800.55',
                            },
                          ],
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: null,
                          value: '1080549.68',
                          months: [
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 4,
                              value: '340267.66',
                            },
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 5,
                              value: '344933.76',
                            },
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 6,
                              value: '395348.26',
                            },
                          ],
                        },
                        {
                          fy: 2023,
                          fq: 3,
                          fm: null,
                          value: '244829.93',
                          months: [
                            {
                              fy: 2023,
                              fq: 3,
                              fm: 7,
                              value: '244829.93',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                children: [],
              },
              {
                label: 'Average Discount',
                data: {
                  actual: [
                    {
                      fy: 2022,
                      fq: null,
                      fm: null,
                      value: '182297.99',
                      quarters: [
                        {
                          fy: 2022,
                          fq: 4,
                          fm: null,
                          value: '182297.99',
                          months: [
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 10,
                              value: '55463.45',
                            },
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 11,
                              value: '52224.93',
                            },
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 12,
                              value: '77620.07',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: null,
                      fm: null,
                      value: '371544.81',
                      quarters: [
                        {
                          fy: 2023,
                          fq: 1,
                          fm: null,
                          value: '175856.37',
                          months: [
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 1,
                              value: '45421.47',
                            },
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 2,
                              value: '70214.40',
                            },
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 3,
                              value: '60892.57',
                            },
                          ],
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: null,
                          value: '167968.53',
                          months: [
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 4,
                              value: '60534.37',
                            },
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 5,
                              value: '54907.39',
                            },
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 6,
                              value: '54035.95',
                            },
                          ],
                        },
                        {
                          fy: 2023,
                          fq: 3,
                          fm: null,
                          value: '37762.28',
                          months: [
                            {
                              fy: 2023,
                              fq: 3,
                              fm: 7,
                              value: '37762.28',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                  planned: [
                    {
                      fy: 2022,
                      fq: null,
                      fm: null,
                      value: '182297.99',
                      quarters: [
                        {
                          fy: 2022,
                          fq: 4,
                          fm: null,
                          value: '182297.99',
                          months: [
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 10,
                              value: '55463.45',
                            },
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 11,
                              value: '52224.93',
                            },
                            {
                              fy: 2022,
                              fq: 4,
                              fm: 12,
                              value: '77620.07',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      fy: 2023,
                      fq: null,
                      fm: null,
                      value: '371544.81',
                      quarters: [
                        {
                          fy: 2023,
                          fq: 1,
                          fm: null,
                          value: '175856.37',
                          months: [
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 1,
                              value: '45421.47',
                            },
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 2,
                              value: '70214.40',
                            },
                            {
                              fy: 2023,
                              fq: 1,
                              fm: 3,
                              value: '60892.57',
                            },
                          ],
                        },
                        {
                          fy: 2023,
                          fq: 2,
                          fm: null,
                          value: '167968.53',
                          months: [
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 4,
                              value: '60534.37',
                            },
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 5,
                              value: '54907.39',
                            },
                            {
                              fy: 2023,
                              fq: 2,
                              fm: 6,
                              value: '54035.95',
                            },
                          ],
                        },
                        {
                          fy: 2023,
                          fq: 3,
                          fm: null,
                          value: '37762.28',
                          months: [
                            {
                              fy: 2023,
                              fq: 3,
                              fm: 7,
                              value: '37762.28',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
}

export const FinalmockData = {
  message: 'success',
  status: 'ok',
  error: '',
  data: {
    label: 'revenue',
    data: {
      actual: [
        {
          year: '2023',
          value: '28009.75',
          totalAmount: '28009.75',
          quarters: [
            {
              quarter: '1',
              value: '10603.75',
              totalAmount: '10603.75',
              months: [
                {
                  month: '1',
                  value: '8403.00',
                  totalAmount: '8403.00',
                },
                {
                  month: '3',
                  value: '2200.75',
                  totalAmount: '2200.75',
                },
              ],
            },
            {
              quarter: '2',
              value: '8603.00',
              totalAmount: '8603.00',
              months: [
                {
                  month: '5',
                  value: '8603.00',
                  totalAmount: '8603.00',
                },
              ],
            },
            {
              quarter: '3',
              value: '8803.00',
              totalAmount: '8803.00',
              months: [
                {
                  month: '9',
                  value: '8803.00',
                  totalAmount: '8803.00',
                },
              ],
            },
          ],
        },
      ],
      planned: [
        {
          year: '2023',
          value: '28009.75',
          totalAmount: '28009.75',
          quarters: [
            {
              quarter: '1',
              value: '10603.75',
              totalAmount: '10603.75',
              months: [
                {
                  month: '1',
                  value: '8403.00',
                  totalAmount: '8403.00',
                },
                {
                  month: '3',
                  value: '2200.75',
                  totalAmount: '2200.75',
                },
              ],
            },
            {
              quarter: '2',
              value: '8603.00',
              totalAmount: '8603.00',
              months: [
                {
                  month: '5',
                  value: '8603.00',
                  totalAmount: '8603.00',
                },
              ],
            },
            {
              quarter: '3',
              value: '8803.00',
              totalAmount: '8803.00',
              months: [
                {
                  month: '9',
                  value: '8803.00',
                  totalAmount: '8803.00',
                },
              ],
            },
          ],
        },
      ],
    },
    children: [
      {
        label: 'Volume',
        data: {
          actual: [
            {
              year: '2023',
              value: '13',
              totalCount: '13',
              quarters: [
                {
                  quarter: '1',
                  value: '5',
                  totalCount: '5',
                  months: [
                    {
                      month: '1',
                      value: '4',
                      totalCount: '4',
                    },
                    {
                      month: '3',
                      value: '1',
                      totalCount: '1',
                    },
                  ],
                },
                {
                  quarter: '2',
                  value: '4',
                  totalCount: '4',
                  months: [
                    {
                      month: '5',
                      value: '4',
                      totalCount: '4',
                    },
                  ],
                },
                {
                  quarter: '3',
                  value: '4',
                  totalCount: '4',
                  months: [
                    {
                      month: '9',
                      value: '4',
                      totalCount: '4',
                    },
                  ],
                },
              ],
            },
          ],
          planned: [
            {
              year: '2023',
              value: '13',
              totalCount: '13',
              quarters: [
                {
                  quarter: '1',
                  value: '5',
                  totalCount: '5',
                  months: [
                    {
                      month: '1',
                      value: '4',
                      totalCount: '4',
                    },
                    {
                      month: '3',
                      value: '1',
                      totalCount: '1',
                    },
                  ],
                },
                {
                  quarter: '2',
                  value: '4',
                  totalCount: '4',
                  months: [
                    {
                      month: '5',
                      value: '4',
                      totalCount: '4',
                    },
                  ],
                },
                {
                  quarter: '3',
                  value: '4',
                  totalCount: '4',
                  months: [
                    {
                      month: '9',
                      value: '4',
                      totalCount: '4',
                    },
                  ],
                },
              ],
            },
          ],
        },
        children: [],
      },
      {
        label: 'Average Price',
        data: {
          actual: [
            {
              year: '2023',
              value: '2154.5961538461538462',
              averageAmount: '2154.5961538461538462',
              quarters: [
                {
                  quarter: '1',
                  value: '2120.7500000000000000',
                  averageAmount: '2120.7500000000000000',
                  months: [
                    {
                      month: '1',
                      value: '2100.7500000000000000',
                      averageAmount: '2100.7500000000000000',
                    },
                    {
                      month: '3',
                      value: '2200.7500000000000000',
                      averageAmount: '2200.7500000000000000',
                    },
                  ],
                },
                {
                  quarter: '2',
                  value: '2150.7500000000000000',
                  averageAmount: '2150.7500000000000000',
                  months: [
                    {
                      month: '5',
                      value: '2150.7500000000000000',
                      averageAmount: '2150.7500000000000000',
                    },
                  ],
                },
                {
                  quarter: '3',
                  value: '2200.7500000000000000',
                  averageAmount: '2200.7500000000000000',
                  months: [
                    {
                      month: '9',
                      value: '2200.7500000000000000',
                      averageAmount: '2200.7500000000000000',
                    },
                  ],
                },
              ],
            },
          ],
          planned: [
            {
              year: '2023',
              value: '2154.5961538461538462',
              averageAmount: '2154.5961538461538462',
              quarters: [
                {
                  quarter: '1',
                  value: '2120.7500000000000000',
                  averageAmount: '2120.7500000000000000',
                  months: [
                    {
                      month: '1',
                      value: '2100.7500000000000000',
                      averageAmount: '2100.7500000000000000',
                    },
                    {
                      month: '3',
                      value: '2200.7500000000000000',
                      averageAmount: '2200.7500000000000000',
                    },
                  ],
                },
                {
                  quarter: '2',
                  value: '2150.7500000000000000',
                  averageAmount: '2150.7500000000000000',
                  months: [
                    {
                      month: '5',
                      value: '2150.7500000000000000',
                      averageAmount: '2150.7500000000000000',
                    },
                  ],
                },
                {
                  quarter: '3',
                  value: '2200.7500000000000000',
                  averageAmount: '2200.7500000000000000',
                  months: [
                    {
                      month: '9',
                      value: '2200.7500000000000000',
                      averageAmount: '2200.7500000000000000',
                    },
                  ],
                },
              ],
            },
          ],
        },
        children: [],
      },
    ],
  },
}
