// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-width-header > div {
    width: 150px; /* Set the fixed width for all child div elements */
    font-weight: bold;
  }


  .custom-width-row > div {
    width: 150px; /* Set the fixed width for all child div elements */
  }

  .small-label {
    font-weight: bold;
    font-size: 0.7rem;
    color: rgb(12, 12, 128);
  }`, "",{"version":3,"sources":["webpack://./src/components/Home/Okr/OkrsExpandableRow/OkrsExpandableRow.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,mDAAmD;IACjE,iBAAiB;EACnB;;;EAGA;IACE,YAAY,EAAE,mDAAmD;EACnE;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,uBAAuB;EACzB","sourcesContent":[".custom-width-header > div {\n    width: 150px; /* Set the fixed width for all child div elements */\n    font-weight: bold;\n  }\n\n\n  .custom-width-row > div {\n    width: 150px; /* Set the fixed width for all child div elements */\n  }\n\n  .small-label {\n    font-weight: bold;\n    font-size: 0.7rem;\n    color: rgb(12, 12, 128);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
