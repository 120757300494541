/* eslint-disable no-prototype-builtins */

// eslint-disable-next-line import/order
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import axios from "axios"
import React, { useRef, useEffect, useState, useMemo } from "react"
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa"
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
  IoMdRefresh,
} from "react-icons/io"
import "./Dive.css"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useOutletContext } from "react-router-dom"

import Dimensions from "./Dimensions/Dimensions"
import ShowData from "./ShowData/ShowData"
import YearData from "./ShowYearHeader/YearData"
import YearDataComponent from "./ShowYearHeader/YearDataComponent"
import useScrollSync from "../../../config/customHooks/useScrollHooks"
import {
  setMisDataFromSlice,
  setTimeLine,
} from "../../../config/Redux/DataSlice"
import {
  axiosErrorHandler,
  axiosUtilsGet,
} from "../../../config/Utils/AxiosUtils"
import { createYearsStructure, findMinMaxFY } from "../../../config/Utils/Utils"
import Spinner from "../../shared/Spinner/Spinner"

const Dive = () => {
  const headerRef = useRef(null)
  const contentRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showLoading, setShowLoading] = useState(false)
  const [hideHomeHeader] = useOutletContext()
  const timeLine = useSelector((stores) => stores.dataStoreReducer.timeLine)
  const misData = useSelector((stores) => {
    return stores.dataStoreReducer.misDataFromSlice
  })
  const [isHiddenControls, setIsHiddenControls] = useState(false)

  const { scrollLeft, scrollRight } = useScrollSync(headerRef, contentRef)

  useEffect(() => {
    if (!misData.length) {
      setShowLoading(true)
      const fetch = () => {
        const revenueApi = axiosUtilsGet("/api/metrics/revenue")
        const cogsApi = axiosUtilsGet("/api/metrics/cogs")
        const employeeExpensesApi = axiosUtilsGet(
          "/api/metrics/employeeExpenses"
        )
        const employeeHiresApi = axiosUtilsGet("/api/metrics/employeeHires")
        const employeeExitsApi = axiosUtilsGet("/api/metrics/employeeExits")

        Promise.all([
          revenueApi,
          cogsApi,
          employeeExpensesApi,
          employeeHiresApi,
          employeeExitsApi,
        ])
          .then((data) => {
            const { minFY, maxFY } = findMinMaxFY(data[0]?.data[0])
            const structure = createYearsStructure(minFY, maxFY)
            dispatch(setTimeLine(structure))
            dispatch(
              setMisDataFromSlice([
                ...data[0].data,
                ...data[1].data,
                ...data[2].data,
                ...data[3].data,
                ...data[4].data,
              ])
            )
            setShowLoading(false)
          })
          .catch((error) => {
            setShowLoading(false)
            axiosErrorHandler(error, navigate)
          })
      }
      fetch()
    }
  }, [])

  const memoizedShowData = useMemo(() => {
    return misData.map((item, index) => {
      return (
        <ShowData
          key={index}
          hideHomeHeader={hideHomeHeader}
          contentRef={contentRef}
          isHiddenControls={isHiddenControls}
          level={"9"}
          title=""
          data={item}
          gridLeft={0}
          siblingLevels={"9"}
        />
      )
    })
  }, [misData, isHiddenControls])

  const refreshMIS = () => {
    window.location.reload()
  }

  return (
    <>
      {showLoading && <Spinner />}

      <div
        className={`grid grid-cols-100 px-2 ${showLoading && "blur-sm"}`}
        style={hideHomeHeader ? { maxHeight: "95vh" } : { maxHeight: "80vh" }}
      >
        {/**PnL and Year full column */}
        <div className={isHiddenControls ? "col-span-98" : "col-span-80"}>
          {/**PnL and left arrow button */}
          <div
            className={`grid ${
              isHiddenControls ? "grid-cols-98" : "grid-cols-80"
            }  mr-1 `}
          >
            {/**PnL */}
            <div className="col-span-14 text-center flex flex-col justify-center">
              <div className="grid grid-cols-5 ">
                <div className="col-span-4 text-center text-3xl font-bold text-gray-600"></div>
              </div>
            </div>
            {/**year,quarter,month */}
            <div className="flex m-4 absolute left-24 mt-8">
              <Tooltip
                title="Reset View"
                onClick={() => {
                  refreshMIS()
                }}
              >
                <button
                  className="flex bg-white rounded-xl p-2 text-gray-900 border-2 border-stone-80"
                  // onClick={() => {
                  //   refreshMIS()
                  // }}
                >
                  {/* &nbsp; Reset View &nbsp; */}
                  <IoMdRefresh size={25} />
                </button>
              </Tooltip>
            </div>

            <div className="col-span-10 text-center flex flex-col ">
              <YearDataComponent
                name={"Years"}
                classProperty={
                  " bg-blue-700 text-white pointer-events-none mx-[0px]"
                }
              />
              <YearDataComponent
                name={"Quarters"}
                classProperty={
                  " bg-blue-600 text-white pointer-events-none  mx-[0px]"
                }
              />
              <YearDataComponent
                name={"Months"}
                classProperty={
                  " bg-blue-500 text-white pointer-events-none  mx-[0px]"
                }
              />
            </div>
            {/**left arrow */}
            <div className="col-span-4 text-center flex flex-col justify-center">
              <button
                onClick={scrollLeft}
                className="col-span-1 rounded flex justify-center items-center"
              >
                <FaArrowAltCircleLeft size={30} className="text-[#5FA5F9]" />
              </button>
            </div>
            {/**Timeline Part year expandable */}
            <div
              className={`${
                isHiddenControls ? "col-span-66" : "col-span-48"
              } text-center hide-scrollbar flex overflow-x-auto h-[140px] `}
              ref={headerRef}
            >
              {timeLine?.years?.map((item, key) => {
                return (
                  <YearData
                    name={`FY${item?.fy}`}
                    key={key}
                    yearIndex={key}
                    type="year"
                  />
                )
              })}
            </div>
            {/** right arrow */}
            <div className="col-span-4 text-center flex flex-col justify-center">
              <button
                onClick={scrollRight}
                className="col-span-1 rounded flex justify-center items-center"
              >
                <FaArrowAltCircleRight size={30} className="text-[#5FA5F9]" />
              </button>
            </div>
            {/**Data Part----------------------------------------------------------------*/}
            <div
              className={` ${
                isHiddenControls ? "col-span-98" : "col-span-80"
              } `}
            >
              <div
                className={`grid ${
                  isHiddenControls ? "grid-cols-98 " : "grid-cols-80"
                } `}
              >
                <div
                  className={` ${
                    isHiddenControls ? "col-span-94" : "col-span-76"
                  }   `}
                >
                  <div
                    className={`grid  ${
                      isHiddenControls ? "grid-cols-94" : "grid-cols-76"
                    } overflow-y-auto  hide-scrollbar `}
                    style={
                      hideHomeHeader
                        ? { maxHeight: "75vh" }
                        : { maxHeight: "60vh" }
                    }
                    ref={contentRef}
                  >
                    {memoizedShowData}

                    {/* for cogs or other metrics other than revenue we will map below */}
                    {/* {misData.map((item, index) => {
                      // console.log(item)
                      return (
                        <ShowData
                          key={index}
                          isHiddenControls={isHiddenControls}
                          data={item?.data?.data?.data[0]}
                          level={'9'}
                          title={' - ' + item?.data?.data?.data[0]?.title}
                        />
                      )
                    })} */}
                  </div>
                </div>
                <div className="col-span-4"></div>
              </div>
              {/**Revenue and cogs with Data list left side */}
            </div>
          </div>
        </div>
        {/**slider button to show and hide controls */}
        <div className="col-span-2 flex justify-center">
          <span
            role="presentation"
            className={` w-[0.1rem] absolute bg-gray-200 justify-center
            ${hideHomeHeader ? " h-[95vh]" : " h-[80vh]"}
            `}
          ></span>
          <div className=" absolute top-[44%]">
            {!isHiddenControls ? (
              <Tooltip
                title="Hide Dimensions"
                onClick={(e) => {
                  setIsHiddenControls(!isHiddenControls)
                  e.stopPropagation()
                }}
              >
                <IconButton>
                  <IoIosArrowDroprightCircle
                    size={30}
                    className="cursor-pointer  py-1   text-gray-400 hover:text-blue-500"
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title="Show Dimensions"
                onClick={(e) => {
                  setIsHiddenControls(!isHiddenControls)
                  e.stopPropagation()
                }}
              >
                <IconButton>
                  <IoIosArrowDropleftCircle
                    size={40}
                    className="cursor-pointer py-1   text-gray-400 hover:text-blue-500"
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {/**Control column */}
        <div
          className={`col-span-18  justify-center  ${
            isHiddenControls ? "hidden" : ""
          } `}
        >
          <div className="grid grid-cols-12 ">
            <div className="col-span-12 ">
              {/**for right arrow and control keyword */}
              <div className="col-span-11 justify-center flex flex-col h-[80px] border-b-2">
                <div className="grid grid-cols-11">
                  <div className="col-span-11 justify-center text-center  text-gray-500 font-serif tracking-widest">
                    DIMENSIONS
                  </div>
                </div>
              </div>
              {/**List of controls */}
              <Dimensions setShowLoading={setShowLoading} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dive
