/* eslint-disable no-prototype-builtins */

import { monthsDropdownOptions } from '../../components/Home/Okr/Okr utils/dropdownOptionsInitialStateMap'

export const getBgColorSiblingClass = level => {
  const bgColorSiblingClasses = [
    'bg-blue-50',
    'bg-blue-50',
    'bg-blue-50',
    'bg-blue-50',
    'bg-blue-50',
    'bg-blue-100',
    'bg-blue-200',
    'bg-blue-300',
    'bg-blue-400',
  ]

  return bgColorSiblingClasses[level - 1] || 'default-bg-class'
}

export const getBgColorClass = level => {
  const bgColorClasses = [
    'bg-gray-50',
    'bg-gray-50',
    'bg-gray-50',
    'bg-gray-50',
    'bg-gray-100',
    'bg-gray-300',
    'bg-gray-500',
    'bg-gray-700',
    'bg-gray-900',
  ]

  return bgColorClasses[level - 1] || 'default-bg-class'
}

export const getMarginClass = level => {
  const marginClasses = [
    'ml-[72px]',
    'ml-[60px]',
    'ml-[48px]',
    'ml-[72px]',
    'ml-[60px]',
    'ml-[48px]',
    'ml-[36px]',
    'ml-[24px]',
    'ml-[12px]',
    'ml-[0px]',
  ]
  return marginClasses[level] || ''
}
export const updateOpenedStateByIndex = (data, indexToUpdate, openedState) => {
  // Update the 'opened' property at a specific index
  const target = data[indexToUpdate]
  if (target) {
    target.opened = openedState
  }
}

export function findMinMaxFY(data) {
  let minFY = Infinity
  let maxFY = -Infinity
  function traverse(node) {
    if (node?.fy) {
      minFY = Math.min(minFY, node?.fy)
      maxFY = Math.max(maxFY, node?.fy)
    }
    if (node?.children) {
      node?.children.forEach(child => traverse(child))
    }
    if (node?.data && Array.isArray(node?.data)) {
      traverse(node?.data[0])
    }
    if (node?.data && !Array.isArray(node?.data)) {
      if (node?.data?.actual) {
        node?.data?.actual?.years.forEach(year => traverse(year))
      }
      if (node?.data?.planned) {
        node?.data?.planned?.years.forEach(year => traverse(year))
      }
    }
    if (node?.quarters) {
      node?.quarters?.forEach(quarter => traverse(quarter))
    }
    if (node?.months) {
      node?.months?.forEach(month => traverse(month))
    }
  }

  // Start the traversal
  traverse(data)

  return { minFY, maxFY }
}

export function createYearsStructure(minYear, maxYear) {
  const years = []

  for (let fy = minYear; fy <= maxYear; fy++) {
    const quarters = []

    // Define the month start for each quarter

    for (let fq = 1; fq <= 4; fq++) {
      const months = []
      // Calculate the starting month for the current quarter (1-based index)
      const startMonth = (fq - 1) * 3 + 1

      // Create month objects for the current quarter
      for (let i = 0; i < 3; i++) {
        months.push({ fy: fy, fq: fq, fm: startMonth + i })
      }

      quarters.push({
        fy: fy,
        fq: fq,
        opened: false,
        months: months,
      })
    }

    years.push({
      fy: fy,
      opened: false,
      quarters: quarters,
    })
  }

  return { years }
}
export const modifiedChartDataAccordingly = mockData => {
  const newData = mockData.map(item => {
    const { financial_year, financial_quarter, financial_month, actual, planned, status } = item

    // Create the time property by combining financial_month, financial_quarter, and financiallet time = '';
    let time = ''
    if (financial_month !== null) {
      time += monthsDropdownOptions[financial_month - 1]?.name
      if (financial_quarter !== null) {
        time += ' Q' + financial_quarter
      }
    } else if (financial_quarter !== null) {
      time += 'Q' + financial_quarter
    }
    if(time.length){
      time += ', ' + financial_year
    } else {
      time += financial_year
    }
    // // console.log(monthsDropdownOptions[financial_month - 1]?.name)
    // Return the modified object
    return {
      time,
      actual,
      planned,
      status,
    }
  })
  // console.log(newData)
  return newData
}

export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}
