import { Tooltip } from "@mui/material"
import React from "react"
import { Outlet, useNavigate } from "react-router-dom"

const HomeOptions = (props) => {
  window.location.pathname.replace("/home/", "")
  const navigate = useNavigate()
  return (
    <Tooltip
      title={
        props.maxPermission > 2
          ? "You do not have necessary permissions to perform this action"
          : ""
      }
    >
      <span>
        <button
          onClick={() => {
            props.setClick("/" + props.name.toLowerCase(), navigate)
          }}
          className={
            "bg-blue-400 shadow-xl hover:bg-blue-600 text-white text-sm font-semibold py-2 px-1 rounded-2xl w-[150px] mx-3 my-2 " +
            (window.location.pathname
              .replace("/home/", "")
              .includes(props.name.toLowerCase()) &&
              "bg-blue-600 pointer-events-none") +
            (props.isDisabled
              ? " disabled:pointer-events-none disabled:opacity-50"
              : "")
          }
          disabled={props.isDisabled ? true : ""}
        >
          {props.label}
        </button>
      </span>
    </Tooltip>
  )
}

export default HomeOptions
