import "../../index.css"
import "./LoginPage.css"
import axios from "axios"
import Cookies from "js-cookie"
import React, { useEffect, useReducer } from "react"
import { FcGoogle } from "react-icons/fc"
import { Navigate, useNavigate } from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"

import InputField from "./InputField"
import { ACTION_TYPE, initialState, reducer } from "./LoginPageReducerVariables"
import { API_ENDPOINT } from "../../config/config"
import {
  axiosErrorHandler,
  axiosUtilsPost,
} from "../../config/Utils/AxiosUtils"

const LoginPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  const loginBtnClick = (e) => {
    dispatch({ type: ACTION_TYPE.isClickedLogInBtn })
    e.preventDefault()
    // console.log(state.email, state.password)
    axiosUtilsPost("/api/users/login", {
      email: state.email,
      password: state.password,
    })
      .then(function (response) {
        // console.log(response)
        localStorage.setItem("userId", response?.id)
        localStorage.setItem("isPasswordChanged", response?.is_password_changed)
        dispatch({ type: ACTION_TYPE.isClickedLogInBtn })
        // // console.log(Cookies.get('actuals_jwt'))
        navigate("/login/resetpassword")

        // localStorage.setItem('isLoggedIn',true)
      })
      .catch(function (error) {
        axiosErrorHandler(error, navigate)
        dispatch({
          type: ACTION_TYPE.displayMessage,
          value: error?.response?.data?.message,
        })
        dispatch({ type: ACTION_TYPE.isClickedLogInBtn })
      })
  }
  const handleGoogleSignin = () => {
    dispatch({ type: ACTION_TYPE.isGoogleLogInBtnClicked })
    const baseURL = new URL(`${API_ENDPOINT}/api/auth/google`)
    const params = new URLSearchParams({
      successRedirect: encodeURIComponent(
        process.env.REACT_APP_REDIRECT_GOOGLE_SIGN_IN_TO_HOME
      ),
      token: searchParams.get("token"),
      id: searchParams.get("id"),
      failureRedirect: encodeURIComponent(
        process.env.REACT_APP_REDIRECT_LINK_EXPIRED
      ),
    })
    baseURL.search = params
    window.location.href = baseURL.toString()
  }

  return (
    <div className="login_container_css mt-[-60px] lg:mt-[-50px] mx-[20px] ">
      <div className="logo">
        <img
          src={require("../../assets/images/logo actuals transparent.png")}
          alt="Logo_img"
          className="w-[200px] m-[20px] "
        />
      </div>
      <div className="lg:flex shadow-2xl rounded-3xl items-center">
        <div>
          <img
            src={require("../../assets/images/login_girl_icon.png")}
            alt="Logo_img"
            className="w-[400px] m-[20px]"
          />
        </div>
        <div>
          <form onSubmit={loginBtnClick}>
            <div className=" p-[20px] ">
              <div className="mb-[-20px] text-red-500">
                {state.displayMessage}
              </div>
              <div>
                {/* <InputField
                  type={ACTION_TYPE.email}
                  placeholder={'Enter your email'}
                  value={state.email}
                  dispatch={dispatch}
                  name={'Email ID'}
                />
                <InputField
                  type={ACTION_TYPE.password}
                  placeholder={'Enter your password'}
                  value={state.password}
                  dispatch={dispatch}
                  name={'Password'}
                />
                <div className="mt-[30px]">
                  <button
                    disabled={state.isClickedLogInBtn}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-[250px]  md:w-[300px] lg:w-[400px] cursor-pointer"
                  >
                    {!state.isClickedLogInBtn ? 'LOG IN' : <ClipLoader color="#FFF" size={20} />}
                  </button>
                </div> */}
                {/* <div className='mt-[20px] font-light'>
                    <input type='checkbox' className='mr-[5px] '/>
                    Remember me
                </div> */}
                {/* <div className="text-blue-400 mt-[10px] mb-[20px] text-sm cursor-pointer">
                  Forget your password?
                </div>
                <div className="text-center flex justify-center font-semibold mb-[10px]">OR</div> */}
              </div>
              <div
                className="text-center flex justify-center m-8 p-16 rounded-md border-gray-300 border py-2 cursor-pointer hover:bg-blue-50"
                onClick={handleGoogleSignin}
              >
                <FcGoogle size={55} className="ml-2 rounded-md " />
                <p className="text-lg px-2 font-extrabold mt-3">
                  {!state.isGoogleLogInBtnClicked ? (
                    "Sign in with Google"
                  ) : (
                    <ClipLoader color="#000" size={35} />
                  )}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="bottom-[10px] fixed bg-blue-50 p-2 rounded-lg font-mono">
        © 2024 INTCHAR TECHSOL | All Right Reserved |
        <a
          href="https://actuals.co.in/privacy.html"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          &nbsp;Privacy
        </a>
      </div>
    </div>
  )
}

export default LoginPage
