import React, { useState, useRef, useEffect } from "react"
import { FaCloudUploadAlt } from "react-icons/fa"

import "./Upload.css"
import { API_ENDPOINT } from "../../../config/config"

const getPresignedURLEndpoint =
  "https://drg84ulgqe.execute-api.us-east-2.amazonaws.com/generate-presigned-url"

const Upload = () => {
  const [dragActive, setDragActive] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadStarted, setUploadStarted] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(false)
  const [isLoading, setIsLoading] = useState(false) // state for loading status
  const [fileExists, setFileExists] = useState(false) // state for file existence
  const [downloadUrl, setDownloadUrl] = useState("") // state for download URL

  const inputRef = useRef(null)

  const resetToInitialState = (e) => {
    e.preventDefault()
    setSelectedFile("")
    setDragActive(false)
    resetUploadState()
    setUploadStarted(false)
    inputRef.current.value = ""
  }

  const resetUploadState = () => {
    setUploadStarted(false)
    setUploadProgress(0)
    setUploadComplete(false)
  }

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(e.type === "dragenter" || e.type === "dragover")
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    handleFileSelection(e.dataTransfer.files[0])
  }

  const handleChange = (e) => {
    e.preventDefault()
    handleFileSelection(e.target.files[0])
  }

  const handleFileSelection = (file) => {
    resetUploadState()
    if (file) {
      setSelectedFile(file)
    }
  }

  const onButtonClick = () => {
    inputRef.current.click()
  }

  const checkS3FileAlreadyExists = async () => {
    setIsLoading(true) // Start loading
    try {
      const env =
        process.env.REACT_APP_API_ENDPOINT === "https://punt-prod.actuals.co.in"
          ? "production"
          : "development"
      const response = await fetch(getPresignedURLEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileName: `punt-partners-planned-data-${env}.xlsx`,
          intention: "existenceCheck",
        }),
      })

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const data = await response.json()
      const responseBody = JSON.parse(data.body)
      setIsLoading(false) // Stop loading
      setFileExists(responseBody.exists)
      setDownloadUrl(responseBody.downloadUrl || "")
    } catch (e) {
      console.error("Error checking file existence:", e)
      setIsLoading(false) // Ensure loading is stopped in case of an error
    }
  }

  const handleUploadBtn = async () => {
    if (!selectedFile) {
      return
    }

    // Mock upload for development environment
    // if (process.env.REACT_APP_ENV === 'local') {
    //   //use process.env.REACT_APP_ENV === "local" instead of true for mock upload on local only
    //   setUploadStarted(true)
    //   for (let i = 0; i <= 100; i += 20) {
    //     // Simulate 5 steps of progress
    //     setTimeout(() => setUploadProgress(i), i * 50) // 50ms * 20 = 1s per step, for 5 seconds total
    //   }
    //   setTimeout(() => {
    //     setUploadComplete(true)
    //   }, 5000) // Mark as complete after 5 seconds
    //   return
    // }

    // Actual upload for non-development environments
    try {
      setUploadStarted(true)
      const env =
        process.env.REACT_APP_API_ENDPOINT === "https://punt-prod.actuals.co.in"
          ? "production"
          : "development"
      const response = await fetch(getPresignedURLEndpoint, {
        method: "POST",
        body: JSON.stringify({
          fileName: `punt-partners-planned-data-${env}.xlsx`,
        }),
      })

      const responseJson = await response.json()
      // console.log(responseJson)
      const url = JSON.parse(responseJson.body).uploadUrl
      // console.log(JSON.parse(responseJson.body), url)

      const xhr = new XMLHttpRequest()

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total)
          setUploadProgress(progress)
        }
      })

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            setUploadComplete(true)
          } else {
            console.error("File upload failed:", xhr.responseText)
          }
        }
      }

      xhr.open("PUT", url, true)
      xhr.setRequestHeader("Content-Type", selectedFile.type)
      xhr.send(selectedFile)
    } catch (e) {
      console.error("Error during file upload:", e)
    }
  }

  const handleDownload = () => {
    fetch(`${API_ENDPOINT}/download/planned-data-template`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to download file")
        }
        return response.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "Planned-Data-Template.xlsx")
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
      .catch((error) => console.error("Error downloading file:", error))
  }

  useEffect(() => {
    checkS3FileAlreadyExists()
  }, [])

  return (
    <div className="flex flex-col justify-center items-center h-full relative">
      <div
        className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md"
        role="alert"
      >
        <div className="flex">
          <div className="py-1">
            <svg
              className="fill-current h-6 w-6 text-blue-500 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">
              Please download the mandatory template{" "}
              <u onClick={handleDownload} className="cursor-pointer">
                Here
              </u>
            </p>
            <p className="text-sm">
              Please update your planned metrics in the provided template file
              only. <br></br>
              {/* Click on &quot;Download CSV&quot; button to download all rows in .xlsx format */}
            </p>
          </div>
        </div>
      </div>
      <br></br>
      {/* Section for File Existence Check */}
      {isLoading ? (
        <div className="spinner"></div>
      ) : fileExists ? (
        <div>
          {/* <p>Planned Data already exists. Uploading a new file will overwrite the existing and refresh the Planned Data. </p> */}
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Planned Data already exists!</strong>
            <span className="block sm:inline">
              {" "}
              Uploading a new file will overwrite the existing one and refresh
              the Planned Data.&nbsp;
            </span>
            <strong>
              <u>
                <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                  Download Existing File
                </a>
              </u>
            </strong>
          </div>
        </div>
      ) : (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">
            No existing Planned Data file exists.<br></br> Please upload a file
            by clicking on &apos;Browse file&apos; or<br></br> Drag and Drop a
            file.<br></br> <b>Supported Formats : .xlsx</b>
          </strong>
          {/* <span className="block sm:inline"> Uploading a new file will overwrite the existing and refresh the Planned Data.&nbsp;</span> */}
        </div>
      )}

      <form onSubmit={(e) => e.preventDefault()} onDragEnter={handleDrag}>
        <input
          ref={inputRef}
          type="file"
          onChange={handleChange}
          className="hidden"
          accept=".xlsx"
        />
        <label
          className={`relative cursor-pointer w-[500px] h-[350px] mt-4 border-2 border-solid rounded-lg border-blue-400 flex flex-col justify-start items-center space-y-2 ${
            dragActive ? "bg-gray-200" : ""
          }`}
        >
          <FaCloudUploadAlt size={200} className="text-[#5FA5F9]" />
          <p>{selectedFile ? `${selectedFile.name} is Selected!` : ""}</p>
          {!uploadStarted && (
            <div className="mt-2">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md cursor-pointer w-[250px] md:w-[300px] lg:w-[400px]"
                onClick={onButtonClick}
              >
                {selectedFile ? "Change selection..." : "Browse XLSX file"}
              </button>
            </div>
          )}
          {selectedFile && !uploadStarted && (
            <button
              className="absolute bottom-4 text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md cursor-pointer w-[250px] md:w-[300px] lg:w-[400px]"
              onClick={handleUploadBtn}
            >
              Upload
            </button>
          )}
          {uploadStarted && (
            <div className="relative w-4/5 h-4 mt-2 bg-gray-300 rounded">
              <div
                style={{ width: `${uploadProgress}%` }}
                className="absolute top-0 left-0 h-4 bg-blue-500 rounded"
              ></div>
            </div>
          )}
          {/* {console.log(uploadComplete)} */}
          {uploadComplete && (
            <>
              <p className="mt-2 text-green-600">Upload Successful!</p>
              <button
                className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md cursor-pointer w-[250px] md:w-[300px] lg:w-[400px]"
                onClick={resetToInitialState}
              >
                Upload another file
              </button>
            </>
          )}
        </label>
        {dragActive && (
          <div
            className="absolute w-full h-full rounded-lg top-0 right-0 bottom-0 left-0"
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragLeave={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
    </div>
  )
}

export default Upload
