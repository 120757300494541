import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  geographyData: [],
  productData: [],
  industryData: [],
}

const DimensionsSlice = createSlice({
  name: 'dimensionsData',
  initialState,
  reducers: {
    setGeographyDimensionsData: (state, action) => {
      state.geographyData = action.payload
    },
    setProductDimensionData: (state, action) => {
      state.productData = action.payload
    },
    setIndustryDimensionData: (state, action) => {
      state.industryData = action.payload
    },
  },
})

export const { setGeographyDimensionsData, setProductDimensionData, setIndustryDimensionData } =
  DimensionsSlice.actions

export default DimensionsSlice.reducer
