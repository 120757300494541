// src/features/DataSlice.js
// DataSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  misDataFromSlice: [],
  timeLine: {},
}

const DataSlice = createSlice({
  name: 'PnLData',
  initialState,
  reducers: {
    setTimeLine: (state, action) => {
      state.timeLine = action.payload
    },

    setMisDataFromSlice: (state, action) => {
      state.misDataFromSlice = action.payload
    },

    toggleYearOpened: (state, action) => {
      const yearIndex = action.payload
      if (state.timeLine.years && state.timeLine.years.length > yearIndex) {
        const year = state.timeLine.years[yearIndex]
        year.opened = !year.opened

        // If the year is now closed, also close all its quarters
        if (!year.opened) {
          year.quarters.forEach(quarter => {
            quarter.opened = false
          })
        }
      }
    },
    toggleQuarterOpened: (state, action) => {
      const { yearIndex, quarterIndex } = action.payload
      const year = state.timeLine.years[yearIndex]
      if (year && year.quarters.length > quarterIndex) {
        year.quarters[quarterIndex].opened = !year.quarters[quarterIndex].opened
      }
    },
  },
})

export const { setMisDataFromSlice, setTimeLine, toggleYearOpened, toggleQuarterOpened } =
  DataSlice.actions

export default DataSlice.reducer
