import React from 'react'
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai'

function FullScreen({ isFullScreen, setIsFullScreen }) {
  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  return (
    <div className="flex">
      <div className="my-1">
        <AiFillMinusCircle
          onClick={handleFullScreen}
          className={`${!isFullScreen ? 'opacity-50 pointer-events-none' : 'cursor-pointer'}`}
        />
      </div>
      <div className="mx-1 my-1">
        <AiFillPlusCircle
          onClick={handleFullScreen}
          className={`${isFullScreen ? 'opacity-50 pointer-events-none' : 'cursor-pointer'}`}
        />
      </div>
    </div>
  )
}

export default FullScreen
