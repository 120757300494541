import { Tooltip } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import {
  setGeographyDimensionsData,
  setProductDimensionData,
} from "../../../../config/Redux/DimensionsSlice"
import {
  axiosErrorHandler,
  axiosUtilsGet,
  axiosUtilsPost,
} from "../../../../config/Utils/AxiosUtils"
import LabelInput from "../../../shared/LabelInput/LabelInput"
import Spinner from "../../../shared/Spinner/Spinner"
import "../CreateObjectives/createObjectives.css"
import dropdownOptionsInitialStateMap, {
  formulaItemsMetrics,
  monthsDropdownOptions,
} from "../Okr utils/dropdownOptionsInitialStateMap"
import {
  checkingNotNull,
  handleDropdownChange,
  shouldDropdownBeEnabled,
} from "../Okr utils/handleChangeOperationsInOKR"
import { verticalMenuOptionConstants } from "../Okr utils/okrContants"
import Filter from "../SharedOkrComponents/Filter/Filter"
import FormulaBuilder from "../SharedOkrComponents/FormulaBuilder/FormulaBuilder"
import CustomLineChart from "../SharedOkrComponents/LineChart/LineChart"
import ObjectiveExpandableRow from "../SharedOkrComponents/ObjectiveExpandableRow/ObjectiveExpandableRow"
import Specifics from "../SharedOkrComponents/Specifics/Specifics"

function EditObjective({
  hideHomeHeader,
  verticalMenuSelection,
  setVerticalMenuSelection,
  isEditable,
  id,
  maxPermission,
}) {
  // // console.log(isEditable)
  //state for specifics and filter
  const [dropdowns, setDropdowns] = useState(dropdownOptionsInitialStateMap)
  const [dimensionsActive, setDimensionsActive] = useState({})
  //formulaBuilder
  const [formulaMetrics, setFormulaMetrics] = useState(formulaItemsMetrics)
  const [formula, setFormula] = useState([])
  const [comparator, setComparator] = useState(null)

  const [openRow, setOpenRow] = useState(null)
  const [particularOkrData, setParticularOkrData] = useState({})

  const [showLoading, setShowLoading] = useState(false)
  const [showMandatoryMessage, setShowMandatoryMessage] = useState("")

  const navigate = useNavigate()

  const dispatch = useDispatch()
  useEffect(() => {
    setShowLoading(true)
    //to get 1 particular OKR
    const OKRaxios = axiosUtilsGet("/api/okrs/" + id)
    //all list of users
    const allUsersForOwner = axiosUtilsGet("/api/users")
    //geography list
    const geographyList = axiosUtilsGet("/api/geographies/")
    //product list
    const productList = axiosUtilsGet("/api/products/")
    //Metrics List for BODMAS FORMAULA
    const allMetricsList = axiosUtilsGet("/api/metrics")

    Promise.all([
      OKRaxios,
      allUsersForOwner,
      geographyList,
      productList,
      allMetricsList,
    ])
      .then((responses) => {
        setShowLoading(false)
        setParticularOkrData(responses[0])
        const modifiedOwnerList = responses[1].map((item) => ({
          name: item.first_name + " " + item.last_name,
          id: item.id,
          value: item.id,
        }))
        const updatedNewData = responses[4].data.map(({ id, name, slug }) => ({
          id,
          name,
          slug,
          value: name,
        }))
        const modifiedFormulaExpression = responses[0].expression.map(
          (item) => {
            const foundObject = responses[4].data.find(
              (obj) => obj.slug === item
            )
            return foundObject
              ? {
                  name: foundObject.name,
                  value: foundObject.name,
                  id: foundObject.id,
                  slug: foundObject.slug,
                }
              : { name: item, value: item, id: item, slug: item }
          }
        )

        setFormula(modifiedFormulaExpression)
        setComparator(
          responses[0].comparator === "gte"
            ? { name: ">= Planned", value: "gte" }
            : responses[0].comparator === "lte"
            ? { name: "<= Planned", value: "lte" }
            : responses[0].comparator === "lt"
            ? { name: "< Planned", value: "lt" }
            : responses[0].comparator === "gt"
            ? { name: "> Planned", value: "gt" }
            : { name: "none", value: "none" }
        )
        // console.log(responses[0])
        // // console.log(responses[1].data)
        // // console.log(responses[2].data.data)
        // // console.log(responses[3].data.data)
        // // console.log(responses[4].data.data.data)
        dispatch(setGeographyDimensionsData(responses[2].data))
        dispatch(setProductDimensionData(responses[3].data))
        const GeographyPaths = responses[2].data.map((item) => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(" >> "),
        }))
        const ProductPaths = responses[3].data.map((item) => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(" >> "),
        }))

        // // console.log(GeographyPaths.find(item => item.id === responses[0]?.data?.data?.geography_id))
        setFormulaMetrics([...formulaMetrics, ...updatedNewData])
        setDropdowns((prevState) => ({
          ...prevState, // This spreads the previous state to retain all existing properties

          OBJECTIVE: {
            ...prevState.OBJECTIVE,
            value: responses[0]?.title,
          },
          OWNER: {
            ...prevState.OWNER, // This retains all properties of OWNER
            value: modifiedOwnerList.find(
              (user) => user.id === responses[0]?.owner_id
            ), // This updates the value of OWNER
            options: modifiedOwnerList,
          },
          VISIBLITY: {
            ...prevState.VISIBLITY,
            placeholder: responses[0]?.visibility,
            value: {
              name: responses[0]?.visibility,
              value: responses[0]?.visibility,
            },
          },
          TRACKING: {
            ...prevState.TRACKING,
            placeholder: responses[0]?.interval,
            value: {
              name: responses[0]?.interval,
              value: responses[0]?.interval,
            },
          },
          "FROM-MONTH": {
            ...prevState["FROM-MONTH"],
            value:
              responses[0]?.start_financial_month === null
                ? null
                : {
                    name: monthsDropdownOptions[
                      responses[0]?.start_financial_month - 1
                    ]?.name,
                    value: responses[0]?.start_financial_month,
                  },
          },
          "FROM-QUARTER": {
            ...prevState["FROM-QUARTER"],
            value:
              responses[0]?.start_financial_quarter === null
                ? null
                : {
                    name: "Q" + responses[0]?.start_financial_quarter,
                    value: responses[0]?.start_financial_quarter,
                  },
          },
          "FROM-YEAR": {
            ...prevState["FROM-YEAR"],
            value: {
              name: "FY" + responses[0]?.start_financial_year,
              value: responses[0]?.start_financial_year,
            },
          },
          "TO-MONTH": {
            ...prevState["TO-MONTH"],
            value:
              responses[0]?.end_financial_month === null
                ? null
                : {
                    name: monthsDropdownOptions[
                      responses[0]?.end_financial_month - 1
                    ]?.name,
                    value: responses[0]?.end_financial_month,
                  },
          },
          "TO-QUARTER": {
            ...prevState["TO-QUARTER"],
            value:
              responses[0]?.end_financial_quarter === null
                ? null
                : {
                    name: "Q" + responses[0]?.end_financial_quarter,
                    value: responses[0]?.end_financial_quarter,
                  },
          },
          "TO-YEAR": {
            ...prevState["TO-YEAR"],
            value: {
              name: "FY" + responses[0]?.end_financial_year,
              value: responses[0]?.end_financial_year,
            },
          },
          "PRODUCT-DIMENSIONS": {
            ...prevState["PRODUCT-DIMENSION"],
            options: ProductPaths,
            value: ProductPaths.find(
              (item) => item.id === responses[0]?.product_id
            ),
          },
          "GEOGRAPHY-DIMENSIONS": {
            ...prevState["GEOGRAPHY-DIMENSIONS"],
            options: GeographyPaths,
            value: GeographyPaths.find(
              (item) => item.id === responses[0]?.geography_id
            ),
          },
        }))
      })
      .catch((error) => {
        axiosErrorHandler(error, navigate)
        setShowLoading(false)
      })
  }, [])

  const objectiveOptions = [
    {
      name: "SPECIFICS",
      data: (
        <Specifics
          isEditable={isEditable}
          dropdowns={dropdowns}
          setDropdowns={setDropdowns}
          handleDropdownChange={handleDropdownChange}
        />
      ),
    },
    {
      name: "DEFINE",
      data: (
        <>
          <div className="m-4"></div>
          <FormulaBuilder
            selectedOkrData={particularOkrData}
            isEditable={isEditable}
            formulaMetrics={formulaMetrics}
            formula={formula}
            setFormula={setFormula}
            comparator={comparator}
            setComparator={setComparator}
          />
        </>
      ),
    },
    {
      name: "FILTER",
      data: (
        <Filter
          isEditable={isEditable}
          dropdowns={dropdowns}
          setDropdowns={setDropdowns}
          shouldDropdownBeEnabled={shouldDropdownBeEnabled}
          handleDropdownChange={handleDropdownChange}
        />
      ),
    },
  ]

  const toggleRow = (name) => {
    setOpenRow(name === openRow ? null : name)
  }
  const saveExistingOKR = () => {
    const checkinMessageAndNotNull = checkingNotNull(
      dropdowns,
      formula,
      comparator
    )
    setShowMandatoryMessage(checkinMessageAndNotNull[0])
    if (checkinMessageAndNotNull[1]) {
      setShowLoading(true)

      axiosUtilsPost("/api/okrs/" + id, {
        okr: {
          title: dropdowns?.OBJECTIVE?.value,
          expression: formula.map((item) => item.slug),
          comparator: comparator.value,
          parent_id: particularOkrData.parent_id,
          visibility: dropdowns?.VISIBLITY.value.value,
          start_financial_year: dropdowns["FROM-YEAR"].value.value,
          start_financial_quarter: dropdowns["FROM-QUARTER"]?.value?.value,
          start_financial_month: dropdowns["FROM-MONTH"]?.value?.value,
          end_financial_year: dropdowns["TO-YEAR"].value.value,
          end_financial_quarter: dropdowns["TO-QUARTER"]?.value?.value,
          end_financial_month: dropdowns["TO-MONTH"]?.value?.value,
          interval: dropdowns.TRACKING.value.value,
          owner_id: dropdowns.OWNER.value.id,
          geography_id: dropdowns["GEOGRAPHY-DIMENSIONS"]?.value?.id,
          product_id: dropdowns["PRODUCT-DIMENSIONS"]?.value?.id,
        },
      })
        .then((res) => {
          // console.log(res)
          setShowLoading(false)
          setVerticalMenuSelection(verticalMenuOptionConstants.VIEW)
        })
        .catch((err) => {
          axiosErrorHandler(err, navigate)
          setShowLoading(false)
          setVerticalMenuSelection(verticalMenuOptionConstants.VIEW)
        })
    }
  }

  return (
    <div
      className={`bg-gray-100  ${hideHomeHeader ? "h-[95vh]" : "h-[80vh]"} `}
    >
      <div className=" py-5  px-12 text-gray-700">
        {showLoading && <Spinner />}
        <div
          className={`${
            hideHomeHeader ? "h-[80vh]" : "h-[65vh]"
          } border-[1px]  bg-white rounded-xl overflow-x-hidden ${
            showLoading && "blur-sm"
          } `}
        >
          <div className="flex justify-start items-center mb-4 mx-5 mt-5">
            <div className="mr-[5.6rem] w-[90%] px-4">
              <div className="label font-bold p-2">WRITE OBJECTIVE</div>
              <div>
                <input
                  required
                  placeholder={dropdowns?.OBJECTIVE?.placeholder}
                  value={dropdowns?.OBJECTIVE?.value}
                  readOnly={!isEditable}
                  className={`border rounded p-2 m-2 w-full ${
                    !isEditable && "bg-gray-200 text-gray-500"
                  }`}
                  onChange={(e) =>
                    handleDropdownChange(
                      e.target.value,
                      "OBJECTIVE",
                      setDropdowns
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt-1 mx-5">
            {objectiveOptions.map((option) => (
              <ObjectiveExpandableRow
                key={option.name}
                name={option.name}
                isOpen={option.name === openRow}
                toggleRow={() => toggleRow(option.name)}
                data={<div>{option.data}</div>}
              />
            ))}
          </div>
          <div className="flex justify-start items-center mb-4 p-8">
            <div className="font-bold mx-6 w-1/5 text-xs">AUTO-FILL</div>
            <div className="w-4/5 flex overflow-x-auto whitespace-nowrap">
              <LabelInput
                label={"Target"}
                text={
                  particularOkrData?.combined_okr_values?.[0]?.planned || "-"
                }
                textStyling={"text-green-500"}
                isReadOnly={true}
                cursor={"pointer"}
              ></LabelInput>
              <LabelInput
                label={"Current"}
                text={
                  particularOkrData?.combined_okr_values?.[0]?.actual || "-"
                }
                textStyling={
                  particularOkrData?.combined_okr_values?.[0]?.actual <
                  particularOkrData?.combined_okr_values?.[0]?.planned
                    ? "text-red-500"
                    : "text-green-500"
                }
                isReadOnly={true}
                cursor={"pointer"}
              ></LabelInput>
              {/* <LabelInput
              label={'PROGRESS'}
              text={'20%'}
              textStyling={'text-red-500'}
              isReadOnly={true}
            ></LabelInput> */}
              <LabelInput
                label={"STATUS"}
                text={
                  particularOkrData?.combined_okr_values?.[0]?.status || "-"
                }
                textStyling={
                  particularOkrData?.combined_okr_values?.[0]?.status ===
                  "Leading"
                    ? "text-green-500"
                    : "text-red-500"
                }
                isReadOnly={true}
              ></LabelInput>
            </div>
          </div>

          <div className=" rounded h-auto m-8 flex justify-center ">
            <div className="flex ">
              <CustomLineChart
                chartDataArray={particularOkrData?.separate_okr_values}
              />
            </div>
          </div>
        </div>
        <p className="text-red-500">{showMandatoryMessage}</p>
        {console.log(maxPermission)}
        <div className="flex justify-center m-2">
          <Tooltip
            title={
              maxPermission > 2
                ? "You do not have necessary permissions to perform this action"
                : ""
            }
          >
            <button
              className={`bg-blue-600 px-4 py-2 rounded text-white mr-4 ${
                maxPermission > 2 ? "bg-gray-400 !cursor-not-allowed" : ""
              }`}
              onClick={() => {
                verticalMenuSelection === "EDIT"
                  ? saveExistingOKR()
                  : setVerticalMenuSelection(verticalMenuOptionConstants.EDIT)
              }}
            >
              {verticalMenuSelection === "EDIT" ? "SAVE" : "EDIT"}
            </button>
          </Tooltip>

          <button
            className="bg-red-600 px-4 py-2 rounded text-white"
            onClick={() =>
              verticalMenuSelection === "EDIT"
                ? setVerticalMenuSelection(verticalMenuOptionConstants.VIEW)
                : setVerticalMenuSelection(null)
            }
          >
            {verticalMenuSelection === "EDIT" ? "CANCEL" : "CLOSE"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditObjective
