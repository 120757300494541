/* eslint-disable max-params */
import React from 'react'

import DataComponent from './DataComponent'
import MetricsComponent from './MetricsComponent'
import '../Dive.css'
import {
  getBgColorClass,
  getBgColorSiblingClass,
  getMarginClass,
} from '../../../../config/Utils/Utils'

const MetricsAndDataComponent = ({
  siblings,
  level,
  showFiltersChild,
  setShowFiltersChild,
  data,
  setShowChild,
  showChild,
  isHiddenControls,
  gridLeft,
  siblingLevels,
}) => {
  // // console.log(siblingLevels)
  const bgColorClass = getBgColorClass(level)
  const marginclass = getMarginClass(level)
  const siblingMarginclass = getMarginClass(level - (9 - siblingLevels))
  const bgColorSiblingClass = getBgColorSiblingClass(siblingLevels)
  // const columnSpan=` col-span-`+(28 - gridLeft)
  // const gridSpan=' grid-cols-'+(28-gridLeft)

  return (
    <>
      <div className={` col-span-28 sticky_data bg-white `}>
        <div className={` grid grid-cols-28 `}>
          <MetricsComponent
            gridLeft={gridLeft}
            siblings={siblings}
            siblingStyleClose={` flex   border rounded-xl  font-medium text-sm py-2 px-4  w-full  ${bgColorSiblingClass} ${siblingMarginclass}   text-slate-900   `}
            siblingStyleOpen={`flex border rounded-xl   w-[50%] my-[1px]   text-slate-900 ${bgColorSiblingClass} ${siblingMarginclass} px-2 py-1`}
            metricsStyleOpen={`flex border rounded-xl   w-[50%]  my-[1px] ${bgColorClass} ${marginclass}  px-2 py-1   ${
              level >= 7
                ? ' text-slate-100 border-slate-100 '
                : ' text-slate-900 border-slate-800   '
            } `}
            metricsStyleClose={`flex w-full justify-between border-gray-500 border  ${bgColorClass} ${marginclass}  rounded-2xl  font-medium text-sm py-2 px-4  ${
              level >= 7
                ? ' text-slate-100 border-slate-100 '
                : ' text-slate-900 border-slate-800   '
            } `}
            level={level}
            showFiltersChild={showFiltersChild}
            setShowFiltersChild={setShowFiltersChild}
            data={data}
            setShowChild={setShowChild}
            showChild={showChild}
          />
          <div className=" col-span-6 ">
            {!showFiltersChild && (
              <div className=" grid grid-cols-6">
                {/* if we have dimensions selected then we have to show total or not */}
                <>
                  {/* <div className="col-span-8 flex flex-col"></div> */}
                  <div className="col-span-6  flex flex-col">
                    <h1 className="mx-[5px] flex  justify-center text-gray-500  font-extrabold text-xs py-1 mt-1">
                      Planned
                    </h1>
                    <h1 className="mx-[5px] flex justify-center text-green-900  font-extrabold text-xs py-1 mb-1 pb-2  ">
                      Actuals
                    </h1>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
      </div>
      {!showFiltersChild ? (
        <DataComponent isHiddenControls={isHiddenControls} siblings={siblings} data={data} />
      ) : (
        <div
          className={`${
            isHiddenControls ? 'col-span-66' : 'col-span-48'
          } text-center flex flex-col bg-white`}
        ></div>
      )}
    </>
  )
}

export default MetricsAndDataComponent
