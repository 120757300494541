import React, { useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

const InputPassword = props => {
  const [showPasswordType, setShowPassword] = useState('password')
  return (
    <div className="mt-[30px] relative mb-[10px]">
      <h1 className="my-[10px]">{props.name}:</h1>
      <input
        type={showPasswordType}
        placeholder={props.placeholder}
        value={props.value}
        onChange={e => props.dispatch({ type: props.type, value: e.target.value })}
        required
        className="bg-white border border-gray-300 rounded-lg w-full p-2.5"
      />
      {showPasswordType === 'password' ? (
        <FaRegEyeSlash
          className="absolute top-12 right-4 cursor-pointer justify-end"
          onClick={() => setShowPassword(showPasswordType == 'text' ? 'password' : 'text')}
        />
      ) : (
        <FaRegEye
          className="absolute top-12 right-4 cursor-pointer justify-end"
          onClick={() => setShowPassword(showPasswordType == 'text' ? 'password' : 'text')}
        />
      )}
    </div>
  )
}

export default InputPassword
