import React from 'react'
import { useSelector } from 'react-redux'

import Actual_Planned_DataComponent from './Actual_Planned_DataComponent'

const DataComponent = ({ isHiddenControls, siblings, data }) => {
  const timeLine = useSelector(stores => stores.dataStoreReducer.timeLine)
  return (
    <div
      className={`${
        isHiddenControls ? 'col-span-66' : 'col-span-48'
      } text-center flex flex-col bg-white`}
    >
      <div className="flex">
        {timeLine.years?.map((item, key) => {
          const yearExist = siblings
            ? data?.data?.planned?.years?.find(y => y.fy === item.fy)
            : data?.data?.[0]?.data?.planned?.years?.find(y => y.fy === item.fy)
          // // console.log(yearExit === undefined)
          // // console.log(item)
          return (
            <div className="flex" key={key}>
              <Actual_Planned_DataComponent
                data={yearExist != undefined && yearExist}
                isEmpty={yearExist != undefined ? false : true}
                timeLine={item}
                classProperty={' text-gray-500 py-1 mt-1 '}
              />
            </div>
          )
        })}
      </div>
      <div className="flex ">
        {timeLine.years?.map((item, key) => {
          const yearExist = siblings
            ? data?.data?.actual?.years?.find(y => y.fy === item.fy)
            : data?.data?.[0]?.data?.actual?.years?.find(y => y.fy === item.fy)
          return (
            <div className="flex" key={key}>
              <Actual_Planned_DataComponent
                data={yearExist != undefined && yearExist}
                isEmpty={yearExist != undefined ? false : true}
                timeLine={item}
                classProperty={' text-green-900 py-1 mb-1 pb-2 '}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DataComponent
