// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-section,
.right-section {
  overflow-y: auto;
}

/* This is optional to handle padding, borders, etc. */
.left-section::-webkit-scrollbar,
.right-section::-webkit-scrollbar {
  width: 8px;
}

.left-section::-webkit-scrollbar-thumb,
.right-section::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.left-section::-webkit-scrollbar-thumb:hover,
.right-section::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

`, "",{"version":3,"sources":["webpack://./src/components/Home/Settings/Settings.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;AAClB;;AAEA,sDAAsD;AACtD;;EAEE,UAAU;AACZ;;AAEA;;EAEE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;;EAEE,sBAAsB;AACxB","sourcesContent":[".left-section,\n.right-section {\n  overflow-y: auto;\n}\n\n/* This is optional to handle padding, borders, etc. */\n.left-section::-webkit-scrollbar,\n.right-section::-webkit-scrollbar {\n  width: 8px;\n}\n\n.left-section::-webkit-scrollbar-thumb,\n.right-section::-webkit-scrollbar-thumb {\n  background-color: #888;\n  border-radius: 4px;\n}\n\n.left-section::-webkit-scrollbar-thumb:hover,\n.right-section::-webkit-scrollbar-thumb:hover {\n  background-color: #555;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
