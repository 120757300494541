import Tooltip from "@mui/material/Tooltip"
import axios from "axios"
import React, { useState } from "react"
import { AiOutlineSend, AiFillCloseCircle } from "react-icons/ai"
import { FaInfoCircle } from "react-icons/fa"
import Select from "react-select"

import { AccessControlsHandlers } from "./AccessControls/AccessControlsHandlers"
import DimensionsAccessControl from "./AccessControls/DimensionsAccessControl"
import { InputOption } from "../../Dive/Dimensions/DimensionUtils"

const InviteModal = ({
  setShowInviteModal,
  showInviteModal,
  allRoles,
  dimensions,
  setLoading,
  updateControls,
  setUpdateControls,
  setOpenViewCard,
}) => {
  const [saveNewDimensions, setSaveNewDimensions] = useState([
    { geography_id: null, product_id: null },
  ])
  const [editIndex, setEditIndex] = useState(null)
  const [showMessage, setShowMessage] = useState("")
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [saveUserRole, setSaveUserRole] = useState([])
  const [emailId, setEmailId] = useState("")
  const {
    handleEdit,
    handleDelete,
    handleSaveButton,
    handleCancelButton,
    handleAddNewDimension,
    handleInviteMemberButton,
  } = AccessControlsHandlers(
    saveNewDimensions,
    setSaveNewDimensions,
    setEditIndex,
    setShowMessage,
    "",
    [],
    saveUserRole,
    setSaveUserRole,
    setLoading,
    updateControls,
    setUpdateControls,
    "",
    showInviteModal,
    setShowInviteModal,
    setOpenViewCard,
    emailId
  )

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-10 ">
      <div className="bg-white p-6 pb-12 rounded-lg shadow-md">
        <div className="flex space-x-0 justify-between ">
          <div className="mb-4 font-bold flex justify-start text-lg text-center w-10/12 ml-3">
            ENTER EMAIL ID
          </div>
          <div
            className="mb-4 font-semibold text-lg cursor-pointer text w-2/12 flex justify-end"
            onClick={() => {
              setShowInviteModal(!showInviteModal)
            }}
          >
            <AiFillCloseCircle />
          </div>
        </div>

        <div className="flex items-center mb-2">
          <input
            type="email"
            placeholder="Enter email ID..."
            className="flex-grow p-2 rounded-lg border  mx-2"
            onChange={(e) => {
              const email = e.target.value
              setEmailId(email)
              setShowMessage("")
              setIsEmailValid(true)
            }}
          />
        </div>
        {/* <div className="flex flex-col">
          <div className="bg-white flex  px-2 py-5">
            <div className="flex items-center">Role : </div>
            <div className="ml-10">
              <Select
                className="w-[250px] "
                getOptionLabel={(option) => option.name}
                defaultValue={[]}
                value={saveUserRole}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(options) => {
                  setSaveUserRole(options)
                }}
                placeholder={"Select Role..."}
                options={allRoles}
                components={{
                  Option: InputOption,
                }}
              />
            </div>

            <div className="flex justify-center items-center mx-2">
              <Tooltip
                // title="Super Admin : Can Create, Modify, Delete Resources"
                title={
                  <div>
                    <p>Super Admin : Can Create, Modify, Delete Resources</p>
                    <p>Admin : Can Create, Modify Resources</p>
                    <p>Normal User : Can View Resources</p>
                  </div>
                }
              >
                <span>
                  <FaInfoCircle size={15} />
                </span>
              </Tooltip>
            </div>
          </div>

          <div className="max-h-[500px] overflow-auto">
            <div className="mx-2 font-bold text-xs mt-4">ACCESS CONTROL </div>
            <DimensionsAccessControl
              saveNewDimensions={saveNewDimensions}
              editIndex={editIndex}
              dimensions={dimensions}
              setEditIndex={setEditIndex}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleAddNewDimension={handleAddNewDimension}
            />
          </div>
        </div> */}
        <div className="mx-2 text-red-500">{showMessage}</div>
        <button
          className={`bg-green-500 px-5 py-2 rounded-md text-white mx-2 font-semibold mt-2 cursor-pointer ${!isEmailValid ? "bg-gray-400 !cursor-not-allowed" : ""
            }`}
          onClick={handleInviteMemberButton}
          disabled={!isEmailValid}
        >
          INVITE
        </button>
      </div>
    </div>
  )
}

export default InviteModal
