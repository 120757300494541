import axios from "axios"
import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import { FaRegQuestionCircle, FaRegBell, FaRegSun } from "react-icons/fa"
import { MdDarkMode } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import puntLogo from "../../../assets/images/punt-logo.svg"
import profileImage from "../../../assets/images/userProfileImage.png"
import { setUserDetailsFromSlice } from "../../../config/Redux/UserInfoSlice"
import {
  axiosErrorHandler,
  axiosUtilsGet,
} from "../../../config/Utils/AxiosUtils"
import { homeOptions, handleHomeOptionsClick } from "../Home"
import Logout from "../Logout"

const HomeHeader = () => {
  const [dropdownValue, setDropdownValue] = useState("") // State to manage the selected dropdown value
  const [showDropdown, setShowDropdown] = useState(false) // State to toggle dropdown visibility
  const [isModalOpen, setModalOpen] = useState(false)
  const userInfo = useSelector(
    (stores) => stores.userInfoStoreReducer.userDetails
  )
  const [theme, setTheme] = useState(localStorage.getItem("client_theme"))

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = () => {
      axiosUtilsGet("/api/users/logged-in-user")
        .then((data) => dispatch(setUserDetailsFromSlice(data)))
        .catch((err) => axiosErrorHandler(err, navigate))
    }
    fetchData()
  }, [])

  // Handler for the clear button
  const handleClearSearch = () => {
    setDropdownValue("")
    setShowDropdown(false)
  }

  const handleDropdownChange = (value) => {
    setDropdownValue(value)
    const path = "/" + value.toLowerCase()
    handleHomeOptionsClick(path, navigate)
    setDropdownValue("")
  }

  const handleSettingsIconClick = () => {
    navigate("/home" + "/settings" + "/user/profile")
  }

  const handleDarkTheme = () => {
    localStorage.setItem(
      "client_theme",
      localStorage.getItem("client_theme") === "dark" ? "light" : "dark"
    )
    setTheme(theme === "dark" ? "light" : "dark")
  }

  const [profileImageUrl, setProfileImageUrl] = useState(
    userInfo?.profile_picture_url || profileImage
  )

  return (
    <div>
      <div className="home-header flex justify-between items-center py-3 mx-3">
        <div>
          <img
            src={require("../../../assets/images/logo actuals transparent.png")}
            alt="actuals_logo"
            className="w-[150px]"
          />
        </div>
        <div className="text-gray-500 font-semibold">
          Welcome, {userInfo?.first_name + " " + userInfo?.last_name}
        </div>

        {/* <div className="flex items-center relative bg-white border border-gray-300 rounded-lg p-2 w-[200px] md:w-[300px] lg:w-[600px] h-[2rem]">
          <input
            type="text"
            value={dropdownValue}
            placeholder="Search Actuals..."
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
            className="outline-none w-full bg-transparent"
            onChange={(e) => setDropdownValue(e.target.value)}
          /> */}
        {/* Clear button */}
        {/* {dropdownValue && (
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              onClick={handleClearSearch}
            >
              X
            </button>
          )}
          {showDropdown && (
            <div className="relative z-10 w-full border-gray-300 rounded shadow-md bg-white ">
              <ul className="absolute w-[200px] md:w-[300px] lg:w-[600px] z-10 border-b-2 border-l-2 border-r-2 border-gray-300 rounded shadow-md bg-white mt-[20px] ml-[-300px]">
                {Object.keys(homeOptions)
                  .filter((opt) =>
                    homeOptions[opt]
                      .toLowerCase()
                      .includes(dropdownValue.toLowerCase())
                  )
                  .map((option) => (
                    <li
                      key={option}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                      onClick={() => handleDropdownChange(option)}
                    >
                      {homeOptions[option]}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div> */}

        <div className="justify-between flex items-center relative  rounded-lg p-1 ">
          {/* <FaRegQuestionCircle size={20} className="mx-[5px]" /> */}
          {/* <FaRegBell size={20} className="mx-[5px]" /> */}
          {/* <div>
            <MdDarkMode
              size={25}
              className={`mx-10 cursor-pointer ${theme === 'dark' && ' text-white '}`}
              onClick={handleDarkTheme}
            />
          </div> */}

          {/* <div>
            <FaRegSun
              size={20}
              className="mx-[5px] cursor-pointer"
              onClick={() => handleSettingsIconClick()}
            />
          </div> */}
          {/* <img src={puntLogo} alt="punt-logo" className="w-[80px]"></img> */}

          <img
            src={profileImageUrl}
            onError={() => setProfileImageUrl(profileImage)}
            alt="profile_img"
            className="w-[30px] h-[30px] mx-[10px] cursor-pointer"
            onClick={() => setModalOpen(!isModalOpen)}
          />

          {isModalOpen && (
            <Logout
              setModalOpen={setModalOpen}
              isModalOpen={isModalOpen}
              userDetails={userInfo}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default HomeHeader
