import React, { useEffect, useState } from "react"
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai"
import { useSelector } from "react-redux"
import Select from "react-select"

import { InputOption } from "./DimensionUtils"
import DialogComponent from "../../../shared/DialogComponent/DialogComponent"

const GeographyComponent = ({
  setShowGeographycontrols,
  showGeographycontrols,
  selectedGeographyList,
  setSelectedGeographyList,
}) => {
  const { geographyData } = useSelector(
    (stores) => stores.dimensionDataStoreReducer
  )
  const [showDialog, setShowDialog] = useState(false)
  // useEffect(()=>{},[filteredItemsGeographyList])
  return (
    <div className="col-span-8">
      <div
        className="grid grid-cols-20 w-full bg-blue-100 px-2 my-2 cursor-pointer py-2 rounded-md"
        onClick={() => setShowGeographycontrols(!showGeographycontrols)}
      >
        <div className="col-span-20 flex justify-between ">
          <div>Geography</div>

          <div>
            {showGeographycontrols ? (
              <AiOutlineCaretUp />
            ) : (
              <AiOutlineCaretDown />
            )}
          </div>
        </div>
      </div>
      {showDialog && (
        <div>
          <DialogComponent
            setShowDialog={setShowDialog}
            showDialog={showDialog}
            warningTitle={"Please select upto 4 Geographies"}
            warningText={"Not more than 4 Geographies can be selected..."}
            // yesButton={}
            noButton={"Ok"}
          />
        </div>
      )}
      {showGeographycontrols && (
        <div className="col-span-8">
          <div className="grid grid-cols-20">
            <div className="col-span-20">
              <Select
                className="w-[100%]"
                getOptionLabel={(option) => option.name}
                defaultValue={[]}
                value={selectedGeographyList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options) && options.length <= 4) {
                    setSelectedGeographyList(options)
                  } else {
                    // Handle the case where more than 5 options are selected
                    // For instance, you can show an alert or ignore the extra selections
                    // alert('You can select up to 5 options only.')
                    setShowDialog(true)
                  }
                }}
                placeholder={"Select Geography..."}
                options={geographyData}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GeographyComponent
