import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import GeographyComponent from './GeographyComponent'
import IndustryComponent from './IndustryComponent'
import ProductComponent from './ProductComponent'
import { setMisDataFromSlice } from '../../../../config/Redux/DataSlice'
import {
  setGeographyDimensionsData,
  setProductDimensionData,
  setIndustryDimensionData,
} from '../../../../config/Redux/DimensionsSlice'
import { axiosErrorHandler, axiosUtilsGet } from '../../../../config/Utils/AxiosUtils'
const Dimensions = ({ setShowLoading }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showGeographyControls, setShowGeographyControls] = useState(true)
  const [selectedGeographyList, setSelectedGeographyList] = useState([]) // Separate array for checked items

  //Product
  const [showProductControls, setShowProductControls] = useState(true)
  const [selectedProductList, setSelectedProductList] = useState([]) // Separate array for checked items

  //Industry
  const [showIndustryControls, setShowIndustryControls] = useState(true)
  const [selectedIndustryList, setSelectedIndustryList] = useState([]) // Separate array for checked items

  useEffect(() => {
    const geographyAPI = axiosUtilsGet('/api/geographies/')
    const ProductAPI = axiosUtilsGet('/api/products/')
    const IndustryAPI = axiosUtilsGet('/api/industries/')

    Promise.all([geographyAPI, ProductAPI, IndustryAPI])
      .then(response => {
        // console.log(response[0])
        const geographyPaths = response?.[0]?.data?.map(item => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(' >> '),
        }))
        dispatch(setGeographyDimensionsData(geographyPaths))
        const productPaths = response?.[1]?.data?.map(item => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(' >> '),
        }))
        dispatch(setProductDimensionData(productPaths))
        const industryPaths = response?.[2]?.data?.map(item => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(' >> '),
        }))
        dispatch(setIndustryDimensionData(industryPaths))
      })
      .catch(function (error) {
        axiosErrorHandler(error, navigate)
      })
  }, [])

  // eslint-disable-next-line max-statements
  const handleFilterButton = () => {
    if (
      selectedGeographyList.length != 0 ||
      selectedProductList.length != 0 ||
      selectedIndustryList.length != 0
    ) {
      setShowLoading(true)
      // Assuming selectedGeographyList and selectedProductList are arrays of objects with an 'id' property
      const geographyIds = selectedGeographyList.map(item => item.id).join(',')
      const productIds = selectedProductList.map(item => item.id).join(',')
      const industryIds = selectedIndustryList.map(item => item.id).join(',')

      // Initialize URLSearchParams
      const params = new URLSearchParams()
      if (selectedGeographyList.length > 0) {
        params.append('geography_ids', geographyIds)
      }
      if (selectedProductList.length > 0) {
        params.append('product_ids', productIds)
      }
      if (selectedIndustryList.length > 0) {
        params.append('industry_ids', industryIds)
      }
      // selectedGeographyList.length > 0
      //   ? selectedProductList.length > 0
      //     ? params.append('order', 'geography_id,product_id')
      //     : params.append('order', 'geography_id')
      //   : selectedProductList.length > 0
      //   ? params.append('order', 'product_id')
      //   : params.append('order', 'geography_id')

      const orderFields = []
      if (selectedGeographyList.length > 0) {
        orderFields.push('geography_id')
      }
      if (selectedProductList.length > 0) {
        orderFields.push('product_id')
      }
      if (selectedIndustryList.length > 0) {
        orderFields.push('industry_id')
      }
      if (orderFields.length === 0) {
        orderFields.push('geography_id') // Or set to any default order you prefer
      }

      params.append('order', orderFields.join(','))

      const revenueApi = axiosUtilsGet(`/api/metrics/revenue?${params.toString()}`)
      const cogsApi = axiosUtilsGet(`/api/metrics/cogs?${params.toString()}`)
      const employeeExpensesApi = axiosUtilsGet(
        `/api/metrics/employeeExpenses?${params.toString()}`,
      )
      const employeeHiresApi = axiosUtilsGet(`/api/metrics/employeeHires?${params.toString()}`)
      const employeeExitsApi = axiosUtilsGet(`/api/metrics/employeeExits?${params.toString()}`)

      Promise.all([revenueApi, cogsApi, employeeExpensesApi, employeeHiresApi, employeeExitsApi])
        .then(responses => {
          dispatch(
            setMisDataFromSlice([
              ...responses[0].data,
              ...responses[1].data,
              ...responses[2].data,
              ...responses[3].data,
              ...responses[4].data,
            ]),
          )
          setShowLoading(false)
        })
        .catch(error => {
          // Handle the error
          // console.log(error)
          axiosErrorHandler(error, navigate)
          setShowLoading(false)
        })
    }
  }

  return (
    <div className="col-span-11  text-xs ">
      <div className="grid grid-cols-8 ">
        <GeographyComponent
          setShowGeographycontrols={setShowGeographyControls}
          showGeographycontrols={showGeographyControls}
          setSelectedGeographyList={setSelectedGeographyList}
          selectedGeographyList={selectedGeographyList}
        />
        <IndustryComponent
          setShowIndustrycontrols={setShowIndustryControls}
          showIndustrycontrols={showIndustryControls}
          setSelectedIndustryList={setSelectedIndustryList}
          selectedIndustryList={selectedIndustryList}
        />
        <ProductComponent
          setShowProductcontrols={setShowProductControls}
          showProductcontrols={showProductControls}
          setSelectedProductList={setSelectedProductList}
          selectedProductList={selectedProductList}
        />

        <div className="col-span-8 my-2">
          <button
            className="flex justify-center text-center w-full bg-blue-400 rounded rounded-lg text-white py-2"
            onClick={handleFilterButton}
          >
            Apply Dimensions
          </button>
        </div>
      </div>
    </div>
  )
}

export default Dimensions
