export const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.email:
      return { ...state, email: action.value }
    case ACTION_TYPE.password:
      return { ...state, password: action.value }
    case ACTION_TYPE.isClickedLogInBtn:
      return { ...state, isClickedLogInBtn: !state.isClickedLogInBtn }
    case ACTION_TYPE.displayMessage:
      return { ...state, displayMessage: action.value }
    case ACTION_TYPE.isGoogleLogInBtnClicked:
      return { ...state, isGoogleLogInBtnClicked: !state.isGoogleLogInBtnClicked }
    default:
      return state
  }
}
export const initialState = {
  email: '',
  password: '',
  isClickedLogInBtn: false,
  displayMessage: '',
  isGoogleLogInBtnClicked: false,
}

export const ACTION_TYPE = {
  email: 'email',
  password: 'password',
  isClickedLogInBtn: 'isClickedLogInBtn',
  displayMessage: 'displayMessage',
  isGoogleLogInBtnClicked: 'isGoogleLogInBtnClicked',
}
