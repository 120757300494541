import React, { useState } from "react";
import "./connect.css"; // Import CSS file for styling

const Connect = () => {
  const [loading, setLoading] = useState(true);

  // Function to hide loading symbol once iframe content is loaded
  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}

      <iframe
        // src={`https://localhost:3001/workspaces/38204825-85eb-4125-85c0-7e0b5c329f30/connections`}
        src={process.env.REACT_APP_AIRBYTE_APP_ENDPOINT}
        title="Static Page"
        style={{ width: "100%", height: "100vh", border: "none", display: loading ? "none" : "block" }}
        onLoad={handleLoad} // Triggered when iframe content is loaded
      />
    </>
  );
};

export default Connect;
