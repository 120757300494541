import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { toggleQuarterOpened, toggleYearOpened } from '../../../../config/Redux/DataSlice'

const YearDataComponent = ({
  name,
  classProperty,
  onClickDiv,
  setOnClickDiv,
  quarterIndex,
  type,
  yearIndex,
}) => {
  const dispatch = useDispatch()
  return (
    <div
      className={
        // eslint-disable-next-line max-len
        'min-w-[100px] h-9 flex mx-[5px] my-[1px] text-center items-center justify-center hover:bg-blue-500 text-gray-500 font-semibold hover:text-white py-2 px-2 border border-blue-300 hover:border-transparent rounded-2xl cursor-pointer ' +
        classProperty
      }
      onClick={() => {
        if (type === 'year') {
          // setOpenStatusForYear()
          // console.log(yearIndex, type)
          dispatch(toggleYearOpened(yearIndex))
        }
        if (type === 'quarter') {
          // console.log(quarterIndex, type)
          dispatch(toggleQuarterOpened({ yearIndex, quarterIndex })) // For FY 2023 Q1 if FY 2023 is at year index 1 and Q1 is at quarter index 0
        }
        setOnClickDiv(!onClickDiv)
      }}
    >
      {name}
    </div>
  )
}

export default YearDataComponent
