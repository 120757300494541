// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_container_css {
    display: flex;
    flex-direction: column; /* Stack the divs vertically */
    align-items: center; /* Center horizontally within the container */
    justify-content: center; /* Center vertically within the container */
    height: 100vh; /* Take up the full viewport height */

  }`, "",{"version":3,"sources":["webpack://./src/components/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB,EAAE,8BAA8B;IACtD,mBAAmB,EAAE,6CAA6C;IAClE,uBAAuB,EAAE,2CAA2C;IACpE,aAAa,EAAE,qCAAqC;;EAEtD","sourcesContent":[".login_container_css {\n    display: flex;\n    flex-direction: column; /* Stack the divs vertically */\n    align-items: center; /* Center horizontally within the container */\n    justify-content: center; /* Center vertically within the container */\n    height: 100vh; /* Take up the full viewport height */\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
