import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Select from 'react-select'

import FormulaItem from './FormulaItem'
import { ComparatorListForFormulaBuilder } from '../../Okr utils/dropdownOptionsInitialStateMap'

const FormulaBuilder = ({
  formulaMetrics,
  formula,
  setFormula,
  comparator,
  setComparator,
  isEditable,
  selectedOkrData,
}) => {
  const moveItem = (dragIndex, hoverIndex) => {
    const dragItem = formula[dragIndex]
    const newFormula = [...formula]
    newFormula.splice(dragIndex, 1)
    newFormula.splice(hoverIndex, 0, dragItem)
    setFormula(newFormula)
  }

  const handleSelect = selectedOption => {
    setFormula([...formula, selectedOption])
  }

  const handleComparatorSelect = selectedOption => {
    setComparator(selectedOption)
  }

  const handleDelete = index => {
    setFormula(formula.filter((_, idx) => idx !== index))
  }

  const clearFormula = () => {
    setFormula([])
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div className="flex m-2 items-center">
          <div className="m-2 mr-8 ml-8 font-bold text-xs">CUSTOM FORMULA</div>
          <Select
            getOptionLabel={options => options.name}
            isDisabled={!isEditable}
            options={formulaMetrics}
            onChange={handleSelect}
            className="p-2 rounded"
            placeholder="Select Symbol or Search"
            value={''}
          />
        </div>
        <div className="flex">
          <div
            className={`flex space-x-2 p-1 border-2 rounded m-4 ml-8 overflow-x-auto max-w-[80%] ${
              !isEditable && 'pointer-events-none'
            }`}
          >
            {formula.length === 0 && selectedOkrData?.expression?.length === 0 && (
              <div>Please select inputs from the dropdown above</div>
            )}
            {formula.length !== 0
              ? formula.map((item, index) => (
                  <FormulaItem
                    key={index}
                    index={index}
                    id={item + index}
                    text={item.value}
                    moveItem={moveItem}
                    onDelete={handleDelete}
                  />
                ))
              : selectedOkrData?.expression?.map((item, index) => (
                  <FormulaItem
                    key={index}
                    index={index}
                    id={item + index}
                    text={item.value}
                    moveItem={moveItem}
                    onDelete={handleDelete}
                  />
                ))}
            {isEditable && formula.length !== 0 && (
              <div
                className="m-4 p-2 bg-slate-300 rounded-lg"
                onClick={() => {
                  clearFormula()
                }}
              >
                <button className="min-w-fit whitespace-nowrap">Clear All</button>
              </div>
            )}
          </div>
          <div className="flex m-2 items-center">
            <Select
              getOptionLabel={option => option.name}
              isDisabled={!isEditable}
              options={ComparatorListForFormulaBuilder}
              onChange={handleComparatorSelect}
              className="p-2 rounded"
              // placeholder={showComparator}
              // value={comparator}
              value={ComparatorListForFormulaBuilder.filter(option => {
                return option?.name === comparator?.name
              })}
            />
          </div>
        </div>
      </div>
    </DndProvider>
  )
}

export default FormulaBuilder
