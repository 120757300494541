import React, { useState } from 'react'

import YearDataComponent from './YearDataComponent'

const getMonthName = monthNum => {
  if (monthNum === 10) {
    return 'Jan'
  }
  if (monthNum === 11) {
    return 'Feb'
  }
  if (monthNum === 12) {
    return 'March'
  }
  if (monthNum === 1) {
    return 'Apr'
  }
  if (monthNum === 2) {
    return 'May'
  }
  if (monthNum === 3) {
    return 'Jun'
  }
  if (monthNum === 4) {
    return 'Jul'
  }
  if (monthNum === 5) {
    return 'Aug'
  }
  if (monthNum === 6) {
    return 'Sept'
  }
  if (monthNum === 7) {
    return 'Oct'
  }
  if (monthNum === 8) {
    return 'Nov'
  }
  if (monthNum === 9) {
    return 'Dec'
  }
}

const QuarterData = ({ name, months, yearIndex, type, quarterIndex }) => {
  const [onClickQuarter, setClickQuarter] = useState(false)
  // console.log('YearIndex: ' + yearIndex + ' quarterIndex: ' + quarterIndex)
  return (
    <>
      <div className="flex-1">
        <YearDataComponent
          name={name}
          classProperty={onClickQuarter && 'bg-blue-400 text-white hover:bg-blue-400'}
          onClickDiv={onClickQuarter}
          setOnClickDiv={setClickQuarter}
          quarterIndex={quarterIndex}
          type={type}
          yearIndex={yearIndex}
        />
        <div className="flex">
          {onClickQuarter && (
            <>
              <YearDataComponent
                name={name}
                classProperty={
                  'flex-1 pointer-events-none bg-blue-400 text-white hover:bg-blue-400'
                }
              />
              <YearDataComponent name={months[0]} classProperty={'flex-1 pointer-events-none '} />
              <YearDataComponent name={months[1]} classProperty={'flex-1 pointer-events-none '} />
              <YearDataComponent name={months[2]} classProperty={'flex-1 pointer-events-none '} />
              {/* {data.months.map((item, index) => {
                // console.log(item)
                return (
                  <>
                    <YearDataComponent
                      name={getMonthName(item.fm)}
                      classProperty={'flex-1 pointer-events-none '}
                    />
                  </>
                )
              })} */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default QuarterData
