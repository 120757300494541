// useScrollSync.js

import { useEffect } from 'react'

const useScrollSync = (headerRef, contentRef, scrollAmount = 100) => {
  let isProgrammaticScroll = false

  const scrollLeft = () => {
    if (headerRef.current) {
      headerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth',
      })
    }
    if (contentRef.current) {
      contentRef.current.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  const scrollRight = () => {
    if (headerRef.current) {
      headerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      })
    }
    if (contentRef.current) {
      contentRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    const handleHeaderScroll = () => {
      if (isProgrammaticScroll) {
        isProgrammaticScroll = false
        return
      }
      const scrollLeft = headerRef.current.scrollLeft
      isProgrammaticScroll = true // Set flag before programmatically scrolling contentRef
      contentRef.current.scrollLeft = scrollLeft
    }

    const handleContentScroll = () => {
      if (isProgrammaticScroll) {
        isProgrammaticScroll = false
        return
      }
      const scrollLeft = contentRef.current.scrollLeft
      isProgrammaticScroll = true // Set flag before programmatically scrolling headerRef
      headerRef.current.scrollLeft = scrollLeft
    }

    if (headerRef.current) {
      headerRef.current.addEventListener('scroll', handleHeaderScroll)
    }
    if (contentRef.current) {
      contentRef.current.addEventListener('scroll', handleContentScroll)
    }

    return () => {
      if (headerRef.current) {
        headerRef.current.removeEventListener('scroll', handleHeaderScroll)
      }
      if (contentRef.current) {
        contentRef.current.removeEventListener('scroll', handleContentScroll)
      }
    }
  }, [headerRef, contentRef])

  return { scrollLeft, scrollRight }
}

export default useScrollSync
