import React from 'react'
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineCaretRight,
  AiOutlineCaretLeft,
} from 'react-icons/ai'
import { FaBarsStaggered } from 'react-icons/fa6'
import {
  TbArticle,
  TbArticleOff,
  TbLayoutSidebarLeftExpandFilled,
  TbLayoutSidebarRightCollapseFilled,
} from 'react-icons/tb'

import { getBgColorClass, getMarginClass } from '../../../../config/Utils/Utils'
import '../Dive.css'

const MetricsComponent = ({
  siblings = false,
  siblingStyleClose,
  siblingStyleOpen,
  normalStyle,
  metricsStyleOpen,
  metricsStyleClose,
  level,
  showFiltersChild = false,
  setShowFiltersChild,
  data,
  setShowChild,
  showChild,
  gridLeft,
}) => {
  // const columnSpan=`col-span-`+(22 - gridLeft)
  // const gridSpan='grid-cols-'+(22-gridLeft)
  // console.log(data)
  return (
    <>
      <div className={` col-span-22 flex items-center`}>
        <div
          className={
            !showFiltersChild
              ? !siblings
                ? metricsStyleClose
                : siblingStyleClose
              : !siblings
              ? metricsStyleOpen
              : siblingStyleOpen
          }
        >
          {/* if we have any child in revenue like avg. price if yes show down and up arror */}

          {!siblings ? (
            <>
              {data?.children?.length > 0 && (
                <div
                  className={`flex justify-center text-center flex-col cursor-pointer`}
                  onClick={() => {
                    setShowChild(!showChild)
                  }}
                >
                  {showChild ? <AiOutlineCaretDown /> : <AiOutlineCaretRight />}
                </div>
              )}
              <h1 className="flex-grow text-center  ">{data?.label}</h1>
              {/* if we have any dimensions selected like global or all utility if so show right or left arrow in right of revenue */}
              {data?.data?.[0]?.children?.length > 0 && (
                <div
                  className="flex justify-center text-center flex-col cursor-pointer"
                  onClick={() => setShowFiltersChild(!showFiltersChild)}
                >
                  {showFiltersChild ? <TbArticleOff /> : <TbArticle />}
                </div>
              )}
            </>
          ) : (
            <>
              <h1 className="flex-grow text-center ">{data?.label}</h1>
              {/* if we have any dimensions selected like global or all utility if so show right or left arrow in right of revenue */}
              {data?.children?.length > 0 && (
                <div
                  className="flex justify-center text-center flex-col cursor-pointer"
                  onClick={() => setShowFiltersChild(!showFiltersChild)}
                >
                  {showFiltersChild ? <TbArticleOff /> : <TbArticle />}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MetricsComponent
