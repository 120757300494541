export const OwnerDropdownOptions = [
  { name: "A", id: "ABC", value: "A" },
  { name: "B", id: "ABC", value: "B" },
  { name: "C", id: "ABC", value: "C" },
]
export const visiblityDropDownOptions = [
  { name: "Public", value: "public" },
  { name: "Private", value: "private" },
]
export const trackingDropdownOptions = [
  { name: "Yearly", value: "yearly" },
  { name: "Quarterly", value: "quarterly" },
  { name: "Monthly", value: "monthly" },
]

export const formulaItemsMetrics = [
  { id: "(", slug: "(", name: "(", value: "(" },
  { id: ")", slug: ")", name: ")", value: ")" },
  { id: "/", slug: "/", name: "/", value: "/" },
  { id: "*", slug: "*", name: "*", value: "*" },
  { id: "+", slug: "+", name: "+", value: "+" },
  { id: "-", slug: "-", name: "-", value: "-" },
]
export const monthsDropdownOptions = [
  { value: 1, name: "April" },
  { value: 2, name: "May" },
  { value: 3, name: "June" },
  { value: 4, name: "July" },
  { value: 5, name: "August" },
  { value: 6, name: "September" },
  { value: 7, name: "October" },
  { value: 8, name: "November" },
  { value: 9, name: "December" },
  { value: 10, name: "January" },
  { value: 11, name: "February" },
  { value: 12, name: "March" },
]
export const quarterDropdownOptions = [
  { value: 1, name: "Q1" },
  { value: 2, name: "Q2" },
  { value: 3, name: "Q3" },
  { value: 4, name: "Q4" },
]
export const yearDropdownOptions = [
  { name: "FY2023", value: 2023 },
  { name: "FY2024", value: 2024 },
]

export const ComparatorListForFormulaBuilder = [
  { name: "<= Planned", value: "lte" },
  { name: ">= Planned", value: "gte" },
  { name: "< Planned", value: "lt" },
  { name: "> Planned", value: "gt" },
]

export const productDropdownOptions = [
  { id: "1", name: "Laptop", key: "1-2-2", value: "Laptop" },
]
export const geographyDropdownOptions = [
  { id: "1", name: "India", key: "1-2-2", value: "India" },
]

// Hashmap of dropdown options
const dropdownOptionsInitialStateMap = {
  OBJECTIVE: {
    title: "OBJECTIVE",
    value: "",
    placeholder: "Write the Objective...",
  },
  OWNER: {
    title: "OWNER",
    value: null,
    ownerId: null,
    options: OwnerDropdownOptions,
    placeholder: "Select...",
  },
  VISIBLITY: {
    title: "VISIBLITY",
    value: null,
    options: visiblityDropDownOptions,
    placeholder: "Select...",
  },
  TRACKING: {
    title: "TRACKING",
    value: null,
    options: trackingDropdownOptions,
    placeholder: "Select...",
  },
  "FROM-MONTH": {
    title: "FROM-MONTH",
    value: null,
    options: monthsDropdownOptions,
    placeholder: "Select...",
  },
  "FROM-QUARTER": {
    title: "FROM-QUARTER",
    value: null,
    options: quarterDropdownOptions,
    placeholder: "Select...",
  },
  "FROM-YEAR": {
    title: "FROM-YEAR",
    value: null,
    options: yearDropdownOptions,
    placeholder: "Select...",
  },
  "TO-MONTH": {
    title: "TO-MONTH",
    value: null,
    options: monthsDropdownOptions,
    placeholder: "Select...",
  },
  "TO-QUARTER": {
    title: "TO-QUARTER",
    value: null,
    options: quarterDropdownOptions,
    placeholder: "Select...",
  },
  "TO-YEAR": {
    title: "TO-YEAR",
    value: null,
    options: yearDropdownOptions,
    placeholder: "Select...",
  },
  "PRODUCT-DIMENSIONS": {
    title: "PRODUCT-DIMENSIONS",
    value: null,
    options: productDropdownOptions,
    placeholder: "Select...",
  },
  "GEOGRAPHY-DIMENSIONS": {
    title: "GEOGRAPHY-DIMENSIONS",
    value: null,
    options: geographyDropdownOptions,
    placeholder: "Select...",
  },
}

export default dropdownOptionsInitialStateMap
