import { Tooltip } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import InviteModal from "./InviteModal"
import UserDetailedComponent from "./UserDetailedComponent"
import UsersListComponent from "./UsersListComponent"
import {
  axiosErrorHandler,
  axiosUtilsGet,
} from "../../../../config/Utils/AxiosUtils"
import Spinner from "../../../shared/Spinner/Spinner"

const UserManagement = ({ maxPermission }) => {
  const [loading, setLoading] = useState(false)
  const [openViewCard, setOpenViewCard] = useState(false)
  const [userOrderNumber, setUserOrderNumber] = useState(null)
  const [allUsersDetails, setAllUsersDetails] = useState([])
  const [dimensions, setDimensions] = useState({})
  const [allRoles, setAllRoles] = useState([])
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [updateControls, setUpdateControls] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true)
    const geographyApi = axiosUtilsGet("/api/geographies/")
    const productApi = axiosUtilsGet("/api/products/")
    const industryApi = axiosUtilsGet("/api/industries/")
    const allUsersDetails = axiosUtilsGet("/api/users/details")
    const allUsersRoles = axiosUtilsGet("/api/users/roles")

    Promise.all([
      geographyApi,
      productApi,
      allUsersDetails,
      allUsersRoles,
      industryApi,
    ])
      .then((res) => {
        setLoading(false)
        setAllUsersDetails(res[2].data)
        const geographyPaths = res[0].data.map((item) => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(" >> "),
        }))
        const productPaths = res[1].data.map((item) => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(" >> "),
        }))
        const industryPaths = res[4].data.map((item) => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(" >> "),
        }))
        setDimensions({
          geographies: geographyPaths,
          products: productPaths,
          industries: industryPaths,
        })
        const modifiedRoles = res[3].data.map((item) => ({
          id: item.id,
          key: item.id,
          value: item.id,
          role_id: item.id,
          name: item.name,
        }))
        setAllRoles(modifiedRoles)
      })
      .catch((err) => {
        axiosErrorHandler(err, navigate)
        setLoading(false)
      })
  }, [])
  useEffect(() => {
    axiosUtilsGet("/api/users/details")
      .then((res) => {
        setAllUsersDetails(res.data)
      })
      .catch((err) => {
        axiosErrorHandler(err, navigate)
      })
  }, [updateControls])

  return (
    <div className="flex w-full hide-scrollbar h-full">
      {/* {loading && <Spinner />} */}
      <div
        className={` ${
          openViewCard ? "w-2/6" : "w-full"
        }  h-full bg-white mx-1 shadow-sm rounded-md   overflow-auto relative`}
      >
        <Tooltip
          title={
            maxPermission > 2
              ? "You do not have necessary permissions to perform this action"
              : "Invite user"
          }
        >
          <div
            className={` sticky bottom-0  py-1 bg-blue-400 text-white justify-center flex items-center cursor-pointer ${
              maxPermission > 2 ? "bg-gray-400 !cursor-not-allowed" : ""
            }`}
            onClick={(e) => !loading && setShowInviteModal(!showInviteModal)}
          >
            + Invite User
          </div>
        </Tooltip>

        {showInviteModal && (
          <InviteModal
            setOpenViewCard={setOpenViewCard}
            allRoles={allRoles}
            setShowInviteModal={setShowInviteModal}
            showInviteModal={showInviteModal}
            dimensions={dimensions}
            setLoading={setLoading}
            updateControls={updateControls}
            setUpdateControls={setUpdateControls}
          />
        )}

        <UsersListComponent
          loading={loading}
          setUserOrderNumber={setUserOrderNumber}
          allUsersDetails={allUsersDetails}
          setOpenViewCard={setOpenViewCard}
        />
      </div>
      {openViewCard && (
        <div className="w-4/6 h-full bg-white  shadow-sm rounded-md h-full overflow-auto">
          <UserDetailedComponent
            allRoles={allRoles}
            userDetails={allUsersDetails[userOrderNumber]}
            userRoles={allUsersDetails[userOrderNumber]?.user_roles.map(
              (selectedRole) => {
                return allRoles.find(
                  (role) => role.role_id === selectedRole.role_id
                )
              }
            )}
            setOpenViewCard={setOpenViewCard}
            dimensions={dimensions}
            setLoading={setLoading}
            updateControls={updateControls}
            setUpdateControls={setUpdateControls}
            maxPermission={maxPermission}
          />
        </div>
      )}
    </div>
  )
}

export default UserManagement
