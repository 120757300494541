import { Tooltip } from "@mui/material"
import React, { useState } from "react"
import { FaUpload, FaPlus } from "react-icons/fa"
import { useOutletContext } from "react-router-dom"

import CreateObjectives from "./CreateObjectives/CreateObjectives"
import EditObjective from "./EditObjective/EditObjective"
import { verticalMenuOptionConstants } from "./Okr utils/okrContants"
import OkrsExpandableRow from "./OkrsExpandableRow/OkrsExpandableRow"
import Upload from "../Upload/Upload"

const ButtonComponent = ({ label, onClick, disabled }) => {
  const buttonClass =
    " px-4 py-2 rounded box-border text-white text-xs flex mx-4 "
  return (
    <div className="flex">
      <Tooltip
        title={
          disabled
            ? "You do not have necessary permissions to perform this action"
            : ""
        }
      >
        <button
          className={`${buttonClass} bg-blue-400  cursor-pointer ${
            disabled ? "bg-gray-400 !cursor-not-allowed !hover:bg-gray-500" : ""
          }`}
          onClick={onClick}
          disabled={disabled}
        >
          <div className="flex justify-center items-center h-full">
            <FaPlus className="mr-2 " />
          </div>
          <div>{label}</div>
        </button>
      </Tooltip>
    </div>
  )
}

export default function Okr() {
  const [verticalMenuSelection, setVerticalMenuSelection] = useState(null)
  const [idAfterClick, setIdAfterClick] = useState("")
  const [parentOKRId, setParentOKRId] = useState(null)

  // const handleUploadClick = () => {
  //   setUploadButtonSelected(!isUploadButtonSelected)
  //   setObjectiveButtonSelected(false)
  // }

  const handleObjectiveClick = () => {
    setVerticalMenuSelection(verticalMenuOptionConstants.CREATE)
  }

  const [hideHomeHeader, maxPermission] = useOutletContext()

  return (
    <>
      {(verticalMenuSelection === null ||
        verticalMenuSelection === verticalMenuOptionConstants.DELETE) && (
        <>
          {" "}
          <div className="flex justify-center p-2">
            <ButtonComponent
              label="CREATE OBJECTIVE"
              onClick={() => {
                handleObjectiveClick()
              }}
              disabled={maxPermission > 2}
            />
          </div>
          <OkrsExpandableRow
            setVerticalMenuSelection={setVerticalMenuSelection}
            setIdAfterClick={setIdAfterClick}
            setParentOKRId={setParentOKRId}
            maxPermission={maxPermission}
          />
        </>
      )}

      {verticalMenuSelection === verticalMenuOptionConstants.EDIT && (
        <EditObjective
          setVerticalMenuSelection={setVerticalMenuSelection}
          verticalMenuSelection={verticalMenuSelection}
          isEditable={true}
          // setData={setData}
          id={idAfterClick}
          maxPermission={maxPermission}
        />
      )}
      {verticalMenuSelection === verticalMenuOptionConstants.VIEW && (
        <EditObjective
          setVerticalMenuSelection={setVerticalMenuSelection}
          verticalMenuSelection={verticalMenuSelection}
          isEditable={false}
          // setData={setData}
          id={idAfterClick}
          maxPermission={maxPermission}
        />
      )}
      {verticalMenuSelection === verticalMenuOptionConstants.CREATE && (
        <CreateObjectives
          setVerticalMenuSelection={setVerticalMenuSelection}
          verticalMenuSelection={verticalMenuSelection}
          isEditable={true}
          hideHomeHeader={hideHomeHeader}
          parentOKRId={parentOKRId}
          maxPermission={maxPermission}
        />
      )}
    </>
  )
}
