import React from "react"

const Actual_Planned_DataComponent = ({
  data,
  classProperty,
  isEmpty,
  timeLine,
}) => {
  return (
    <div className="data_component flex bg-white">
      <h1
        className={
          // eslint-disable-next-line max-len
          "w-[100px] flex mx-[5px] justify-center text-gray-500  font-extrabold text-xs  px-4  " +
          classProperty
        }
      >
        {!isEmpty
          ? new Intl.NumberFormat("en-IN", { maximumFractionDigits: 0 }).format(
              data?.value
            )
          : "-"}
      </h1>

      {timeLine?.opened &&
        timeLine.quarters.map((items, index) => {
          const quarterData = data?.quarters?.find((q) => q.fq === items.fq)
          return (
            <>
              <h1
                className={
                  // eslint-disable-next-line max-len
                  "w-[100px] flex mx-[5px] justify-center text-gray-500  font-extrabold text-xs  px-4  " +
                  classProperty
                }
              >
                {isEmpty
                  ? "-"
                  : quarterData != undefined
                  ? new Intl.NumberFormat("en-IN", {
                      maximumFractionDigits: 0,
                    }).format(quarterData.value)
                  : "-"}
              </h1>

              {items?.opened && (
                <>
                  {items.months.map((unit) => {
                    // console.log(unit)
                    // console.log(quarterData)
                    const monthsData = quarterData?.months.find(
                      (m) => m.fm === unit.fm
                    )
                    // console.log(monthsData)
                    return (
                      <>
                        <h1
                          className={
                            // eslint-disable-next-line max-len
                            "w-[100px] flex mx-[5px] justify-center text-gray-500  font-extrabold text-xs  px-4  " +
                            classProperty
                          }
                        >
                          {isEmpty
                            ? "-"
                            : quarterData === undefined
                            ? "-"
                            : monthsData != undefined
                            ? new Intl.NumberFormat("en-IN", {
                                maximumFractionDigits: 0,
                              }).format(monthsData.value)
                            : "-"}
                        </h1>
                      </>
                    )
                  })}
                </>
              )}
            </>
          )
        })}
    </div>
  )
}

export default Actual_Planned_DataComponent
