import React from 'react'

import ProfileCard from './ProfileCard/ProfileCard'

const users = require('./mockAccounts.json')

function App() {
  return (
    <div>
      {users.map(user => (
        <ProfileCard user={user} key={user.email} />
      ))}
    </div>
  )
}

export default App
