import React, { useRef, useState } from 'react'
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineCaretRight,
  AiOutlineCaretLeft,
} from 'react-icons/ai'
import { FaBarsStaggered } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import '../Dive.css'

import Actual_Planned_DataComponent from './Actual_Planned_DataComponent'
import DataComponent from './DataComponent'
import MetricsAndDataComponent from './MetricsAndDataComponent'
import MetricsComponent from './MetricsComponent'
import useScrollSync from '../../../../config/customHooks/useScrollHooks'
import { getBgColorClass, getMarginClass } from '../../../../config/Utils/Utils'

const ShowData = ({
  hideHomeHeader,
  isHiddenControls,
  data,
  level,
  title,
  siblings = false,
  gridLeft,
  siblingLevels,
}) => {
  const [showChild, setShowChild] = useState(false)
  const [showFiltersChild, setShowFiltersChild] = useState(false)

  // console.log(data)
  // // console.log(title)
  // console.log(isHiddenControls)

  return (
    <>
      <>
        {!showFiltersChild && (
          // <div
          //   className={` ${
          //     isHiddenControls ? `relative col-span-${94 - gridLeft}` : `col-span-${76 - gridLeft}`
          //   }    `}
          // >
          //   {' '}
          //   <div
          //     className={`grid relative  ${
          //       isHiddenControls ? `grid-cols-${94 - gridLeft}` : `grid-cols-${76 - gridLeft}`
          //     } `}
          //     style={hideHomeHeader ? { maxHeight: '75vh' } : { maxHeight: '60vh' }}
          //     // ref={contentRef}
          //   >
          <MetricsAndDataComponent
            siblingLevels={siblingLevels}
            gridLeft={gridLeft}
            siblings={siblings}
            level={level}
            showFiltersChild={showFiltersChild}
            setShowFiltersChild={setShowFiltersChild}
            data={data}
            setShowChild={setShowChild}
            showChild={showChild}
            isHiddenControls={isHiddenControls}
          />
          //   </div>
          // </div>
        )}
      </>

      {showFiltersChild && (
        <>
          <MetricsAndDataComponent
            siblingLevels={siblingLevels}
            gridLeft={gridLeft}
            siblings={siblings}
            level={level}
            showFiltersChild={showFiltersChild}
            setShowFiltersChild={setShowFiltersChild}
            data={data}
            setShowChild={setShowChild}
            showChild={showChild}
            isHiddenControls={isHiddenControls}
          />

          {!siblings ? (
            <>
              {/* <div
                className={`${
                  isHiddenControls ? ` col-span-${94 - gridLeft} ` : ` col-span-${76 - gridLeft} `
                }   `}
              >
                <div
                  className={` grid  ${
                    isHiddenControls
                      ? ` grid-cols-${94 - gridLeft} `
                      : ` grid-cols-${76 - gridLeft} `
                  }  `}
                  style={hideHomeHeader ? { maxHeight: '75vh' } : { maxHeight: '60vh' }}
                >
                  {data?.data?.[0]?.children.length > 0 && (
                    <div
                      className={`col-span-3 flex  z-20 flex-col  items-center w-[90%] ${marginclass} h-[98%] rounded-xl  ${bgColorClass} ${
                        level >= 7
                          ? ' text-slate-100 border-slate-100 '
                          : ' text-slate-900 border-slate-800 '
                      } `}
                    >
                      {data?.data?.[0]?.children?.length > 0 && (
                        <div
                          className="flex justify-center text-center flex-col cursor-pointer text-white pt-2"
                          onClick={() => setShowFiltersChild(!showFiltersChild)}
                        >
                          {showFiltersChild ? <FaBarsStaggered /> : <FaBarsStaggered />}
                        </div>
                      )}

                      <p className="flex h-full items-center rounded-xl text-white px-2 text-sm">
                        {data?.label.slice(0, 3)}
                      </p>
                      {data?.children?.length > 0 && (
                        <div
                          className={`flex justify-center text-center flex-col cursor-pointer text-white pb-2`}
                          onClick={() => {
                            setShowChild(!showChild)
                          }}
                        >
                          {showChild ? <AiOutlineCaretDown /> : <AiOutlineCaretRight />}
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={`${
                      isHiddenControls
                        ? ` ${marginclass} col-span-${91 - gridLeft} `
                        : ` col-span-${73 - gridLeft} `
                    }   `}
                  >
                    <div
                      className={` grid  ${
                        isHiddenControls
                          ? ` grid-cols-${91 - gridLeft} `
                          : ` grid-cols-${73 - gridLeft} `
                      }   `}
                      style={hideHomeHeader ? { maxHeight: '75vh' } : { maxHeight: '60vh' }}
                    ></div>
                  </div>
                </div>
              </div> */}
              {data?.data?.[0]?.children[0].label &&
                data?.data?.[0]?.children?.map((item, key) => {
                  return (
                    <>
                      {/* {key >= 1 && <div className="col-span-3"></div>} */}
                      <ShowData
                        siblingLevels={siblingLevels - 1}
                        hideHomeHeader={hideHomeHeader}
                        gridLeft={gridLeft}
                        siblings={true}
                        isHiddenControls={isHiddenControls}
                        data={item}
                        key={key}
                        level={level}
                        title={title}
                        // childData={arr}
                      />
                    </>
                  )
                })}
            </>
          ) : (
            <>
              {/* <div
                className={`${
                  isHiddenControls ? ` col-span-${94 - gridLeft} ` : ` col-span-${76 - gridLeft} `
                }   `}
              >
                <div
                  className={` grid  ${
                    isHiddenControls
                      ? ` grid-cols-${94 - gridLeft} `
                      : ` grid-cols-${76 - gridLeft} `
                  }  `}
                  style={hideHomeHeader ? { maxHeight: '75vh' } : { maxHeight: '60vh' }}
                >
                  {data?.children.length > 0 && (
                    <div
                      className={`col-span-3 z-20   flex  flex-col items-center  ${marginclass} w-[90%] h-[95%] justify-center rounded-xl  ${bgColorClass} ${
                        level >= 7
                          ? ' text-slate-100 border-slate-100 '
                          : ' text-slate-900 border-slate-800 '
                      } `}
                    >
                      {data?.children?.length > 0 && (
                        <div
                          className="flex justify-center text-center flex-col cursor-pointer items-center text-white pt-2"
                          onClick={() => setShowFiltersChild(!showFiltersChild)}
                        >
                          {showFiltersChild ? <FaBarsStaggered /> : <FaBarsStaggered />}
                        </div>
                      )}

                      <p className="flex h-full items-center rounded-xl text-white px-2 text-sm">
                        {data?.label.slice(0, 3)}
                      </p>
                      {data?.data?.[0]?.children?.length > 0 && (
                        <div
                          className={`flex justify-center text-center flex-col cursor-pointer text-white pb-2`}
                          onClick={() => {
                            setShowChild(!showChild)
                          }}
                        >
                          {showChild ? <AiOutlineCaretDown /> : <AiOutlineCaretRight />}
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={`${
                      isHiddenControls
                        ? `${marginclass} col-span-${91 - gridLeft} `
                        : ` col-span-${73 - gridLeft} `
                    }   `}
                  >
                    <div
                      className={` grid  ${
                        isHiddenControls
                          ? ` grid-cols-${91 - gridLeft} `
                          : ` grid-cols-${73 - gridLeft} `
                      }  `}
                      style={hideHomeHeader ? { maxHeight: '75vh' } : { maxHeight: '60vh' }}
                    >

                    </div>
                  </div>
                </div>
              </div> */}
              {data?.children?.map((item, key) => {
                return (
                  <>
                    {/* {key >= 1 && <div className="col-span-3"></div>} */}
                    <ShowData
                      siblingLevels={siblingLevels - 1}
                      hideHomeHeader={hideHomeHeader}
                      gridLeft={gridLeft}
                      siblings={true}
                      isHiddenControls={isHiddenControls}
                      data={item}
                      key={key}
                      level={level}
                      title={title}
                      // childData={arr}
                    />
                  </>
                )
              })}
            </>
          )}
        </>
      )}
      {/**Bottom TreeView */}
      {/* <div className={`${isHiddenControls ? ' col-span-94 ' : ' col-span-76 '}   `}>
        <div
          className={` grid ${
            isHiddenControls ? ' grid-cols-94 ' : ' grid-cols-76 '
          }  `}
          style={hideHomeHeader ? { maxHeight: '75vh' } : { maxHeight: '60vh' }}
          ref={contentRef}
        > */}
      {showChild &&
        data?.children?.map((item, key) => {
          return (
            <ShowData
              siblingLevels={siblingLevels}
              hideHomeHeader={hideHomeHeader}
              gridLeft={gridLeft}
              isHiddenControls={isHiddenControls}
              data={item}
              key={key}
              level={level - 1}
              title={title}
            />
          )
        })}
      {/* </div>
      </div> */}
    </>
  )
}

export default ShowData
