import React from 'react'

const LabelInput = ({ label, text, textStyling, isReadOnly = false }) => {
  return (
    <div className="mr-[5rem]">
      <div className="label font-bold text-xs p-2">{label}</div>
      <div>
        <input
          className={`border rounded p-2 m-2 w-[128px] ${textStyling} ${
            isReadOnly ? 'cursor-not-allowed' : ''
          }`}
          value={text}
          readOnly={isReadOnly}
        />
      </div>
    </div>
  )
}

export default LabelInput
