import axios from 'axios'
import { parse } from 'papaparse'
import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { axiosErrorHandler, axiosUtilsGet } from '../../../config/Utils/AxiosUtils'
import Spinner from '../../shared/Spinner/Spinner'

const Objects = () => {
  const [tables, setTables] = useState([])
  const [selectedTable, setSelectedTable] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState(null)
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage] = useState(20) // Adjust as needed
  const [showLoading, setShowLoading] = useState(false)
  const [hideHomeHeader] = useOutletContext()
  const navigate = useNavigate()

  useEffect(() => {
    setShowLoading(true)
    axiosUtilsGet('/api/cleaned-data-tables')
      .then(response => {
        setTables(response)
        setShowLoading(false)
      })
      .catch(function (error) {
        axiosErrorHandler(error, navigate)
        setShowLoading(false)
      })
  }, [])

  const handleRowClick = (tableName, index) => {
    setSelectedTable(tableName)
    setSelectedRowIndex(index)
    setCurrentPage(1) // Reset to first page
    setShowLoading(true)

    axiosUtilsGet(`/api/cleaned-data-tables/${tableName}/csv?limit=50`)
      .then(response => {
        const parsedData = parse(response, { header: true }).data
        setTableData(parsedData)
        setShowLoading(false)
      })
      .catch(function (error) {
        axiosErrorHandler(error, navigate)
        setShowLoading(false)
      })
  }

  const downloadCSV = async () => {
    axiosUtilsGet(`/api/cleaned-data-tables/${selectedTable}/csv`)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${selectedTable}.csv`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
      .catch(error => {
        axiosErrorHandler(error, navigate)
        console.error('Error downloading CSV:', error)
      })
  }

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow)
  const totalPages = Math.ceil(tableData.length / rowsPerPage)

  const paginate = direction => {
    setCurrentPage(prev => {
      if (direction === 'next' && prev < totalPages) {
        return prev + 1
      } else if (direction === 'prev' && prev > 1) {
        return prev - 1
      } else {
        return prev
      }
    })
  }

  return (
    <>
      {showLoading && <Spinner />}
      <div className={`max-w-full m-8 ${showLoading && 'blur-sm'}`}>
        {/* Flex container for two panes */}
        <div className="flex w-full">
          {/* Left Pane for Table Names */}
          <div
            className={`w-1/4 bg-blue-100 overflow-auto mr-8 ${
              hideHomeHeader ? 'max-h-[80vh]' : 'max-h-[65vh]'
            }`}
          >
            {tables.map((table, index) => (
              <button
                key={index}
                onClick={() => handleRowClick(table, index)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-2 w-full"
              >
                {table}
              </button>
            ))}
          </div>

          {/* Right Pane for Table Data and Other Components */}
          <div className="w-3/4">
            {!selectedTable && (
              <div
                className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md"
                role="alert"
              >
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="fill-current h-6 w-6 text-blue-500 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold">Please select an object from left pane.</p>
                    <p className="text-sm">
                      First 50 rows are shown as sample. <br></br>
                      Click on &quot;Download CSV&quot; button to download all rows in .csv format
                    </p>
                  </div>
                </div>
              </div>
            )}
            {selectedRowIndex !== null && selectedTable && !showLoading && (
              <div className="mb-5">
                <div
                  className={`w-full overflow-auto border-2 ${
                    hideHomeHeader ? 'max-h-[80vh]' : 'max-h-[65vh]'
                  }`}
                >
                  <table className="table-auto w-full">
                    <thead>
                      <tr>
                        {Object.keys(tableData[0] || {}).map((key, idx) => (
                          <th key={idx} className="px-4 py-2">
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {currentRows.map((row, idx) => (
                        <tr key={idx}>
                          {Object.values(row).map((val, index) => (
                            <td key={index} className="border px-4 py-2">
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-4 flex items-center justify-center">
                  <button
                    onClick={() => paginate('prev')}
                    disabled={currentPage === 1}
                    className={
                      'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-2 rounded' +
                      (currentPage == 1 ? ' disabled:pointer-events-none disabled:opacity-20' : '')
                    }
                  >
                    -
                  </button>
                  <span className="text-gray-700">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => paginate('next')}
                    disabled={currentPage === totalPages}
                    className={
                      'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 ml-2 rounded' +
                      (currentPage == totalPages
                        ? ' disabled:pointer-events-none disabled:opacity-20'
                        : '')
                    }
                  >
                    +
                  </button>
                </div>
                <div className="mt-2 flex justify-end">
                  <button
                    onClick={downloadCSV}
                    className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4"
                  >
                    <div>Download CSV</div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Objects
