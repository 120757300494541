import IconButton from "@mui/material/IconButton"
import Switch from "@mui/material/Switch"
import Tooltip from "@mui/material/Tooltip"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FaInfoCircle } from "react-icons/fa"
import { IoClose } from "react-icons/io5"
import { MdDelete } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import Select from "react-select"

import { AccessControlsHandlers } from "./AccessControls/AccessControlsHandlers"
import DimensionsAccessControl from "./AccessControls/DimensionsAccessControl"
import {
  axiosErrorHandler,
  axiosUtilsPost,
} from "../../../../config/Utils/AxiosUtils"
import DialogComponent from "../../../shared/DialogComponent/DialogComponent"
import { InputOption } from "../../Dive/Dimensions/DimensionUtils"
import ExpandableRow from "../AccessControls/ExpandableRow"

const UserDetailedComponent = ({
  userDetails,
  setOpenViewCard,
  dimensions,
  setLoading,
  allRoles,
  userRoles,
  updateControls,
  setUpdateControls,
  maxPermission,
}) => {
  const [saveNewDimensions, setSaveNewDimensions] = useState(
    userDetails?.user_accesses
  )

  const [saveNewUserRole, setSaveNewUserRole] = useState(userRoles)
  const [showMessage, setShowMessage] = useState("")
  const [editIndex, setEditIndex] = useState(null)
  const [editUserDetailsBtn, setEditUserDetailsBtn] = useState(true)
  const [toggleCheckedBtn, setToggleCheckedBtn] = useState(
    userDetails.status_id === 1
      ? false
      : userDetails.status_id === 2
        ? true
        : false
  )
  const [showDeleteWarningDialog, setShowDeleteWarningDialog] = useState(false)
  const navigate = useNavigate()

  const {
    handleEdit,
    handleDelete,
    handleSaveButton,
    handleCancelButton,
    handleAddNewDimension,
  } = AccessControlsHandlers(
    saveNewDimensions,
    setSaveNewDimensions,
    setEditIndex,
    setShowMessage,
    userDetails,
    userRoles,
    saveNewUserRole,
    setSaveNewUserRole,
    setLoading,
    updateControls,
    setUpdateControls,
    setEditUserDetailsBtn
  )

  useEffect(() => {
    setSaveNewDimensions(userDetails?.user_accesses)
    setSaveNewUserRole(userRoles)
    setEditUserDetailsBtn(false)
    setToggleCheckedBtn(
      userDetails.status_id === 1
        ? false
        : userDetails.status_id === 2
          ? true
          : false
    )
  }, [userDetails, updateControls])

  const handleToggleChangeBtn = () => {
    if (userDetails.status_id != 1) {
      setLoading(true)
      axiosUtilsPost("/api/users/status", {
        user_id: userDetails?.id,
        status_id: userDetails.status_id === 2 ? 3 : 2,
      })
        .then((res) => {
          setUpdateControls(!updateControls)
          setLoading(false)
          // console.log(res)
        })
        .catch((err) => {
          axiosErrorHandler(err, navigate)
          // console.log(err)
        })
    }
  }
  const handleDeleteAndCancelUserBtn = () => {
    if (userDetails.status_id === 1) {
      setLoading(true)
      axiosUtilsPost("/api/users/invite/cancel", {
        user_id: userDetails?.id,
        invite_ids: userDetails?.user_invitations?.map((item) => item.id),
      })
        .then((res) => {
          // console.log(res)
          setLoading(false)
          setOpenViewCard(false)
          setUpdateControls(!updateControls)
        })
        .catch((err) => {
          axiosErrorHandler(err, navigate)
          setLoading(false)
        })
    } else {
      axiosUtilsPost("/api/users/status", {
        user_id: userDetails?.id,
        status_id: 4,
        //1 -> invited
        //2 -> Active
        //3 -> Disable
        //4 -> Deleted
      })
        .then((res) => {
          // console.log(res)
          setLoading(false)
          setOpenViewCard(false)
          setUpdateControls(!updateControls)
        })
        .catch((err) => {
          axiosErrorHandler(err, navigate)
          setLoading(false)
        })
    }
  }

  return (
    <div className="mx-2  ">
      <div className="flex justify-end ">
        <IoClose
          size={25}
          onClick={(e) => setOpenViewCard(false)}
          className="hover:bg-blue-100 cursor-pointer"
        />
      </div>
      <div className=" shadow-xl rounded-lg  bg-white flex flex-col mb-5">
        <div className="bg-gray-50 border-b-0 px-5 py-5 flex justify-between">
          <div className="flex flex-col">
            <div className="font-semibold text-xl">
              {userDetails.status_id === 1
                ? ""
                : userDetails.first_name + " " + userDetails.last_name}
            </div>
            <div className=" text-base">{userDetails.email}</div>
          </div>
          <div className="flex flex-col">
            <div className="mb-5">
              {userDetails.status_id === 1
                ? `🔵 Invited`
                : userDetails.status_id === 2
                  ? `🟢 Active`
                  : `🔴 Not Active`}
            </div>
            <div className="flex relative justify-end flex-col bottom-0 text-red-500">
              {/* <Tooltip title="Delete user">
                <IconButton>
                  <MdDelete size={25} className="cursor-pointer text-red-500" />
                </IconButton>
              </Tooltip> */}
              {userDetails.status_id !== 1 && (
                <Switch
                  checked={toggleCheckedBtn}
                  onChange={handleToggleChangeBtn}
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={maxPermission === 3}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end mx-4 mt-2 ">
          <Tooltip
            title={
              maxPermission !== 1
                ? "You do not have necessary permissions to perform this action"
                : "Delete user"
            }
          >
            <button
              className={`${userDetails.status_id === 1 ? "bg-blue-500" : "bg-red-500"
                } text-white rounded-md px-4 py-2 cursor-pointer mx-2 mb-4 ${maxPermission !== 1 ? "bg-gray-400 !cursor-not-allowed" : ""
                }`}
              onClick={(e) => setShowDeleteWarningDialog(true)}
              disabled={maxPermission !== 1}
            >
              {userDetails.status_id === 1
                ? "Cancel Invitation"
                : "Delete User"}
            </button>
          </Tooltip>

          {showDeleteWarningDialog && (
            <DialogComponent
              handleDeleteAndCancelUserBtn={handleDeleteAndCancelUserBtn}
              setShowDialog={setShowDeleteWarningDialog}
              showDialog={showDeleteWarningDialog}
              warningTitle={`${userDetails.status_id === 1
                ? "Are you sure you want to cancel this invitation?"
                : "Are you sure you want to delete this user?"
                }`}
              warningText={`${userDetails.status_id === 1
                ? ""
                : "The user will be deleted from the database and can only rejoin after being invited again."
                }`}
              yesButton={"Yes"}
              noButton={"Cancel"}
            />
          )}
          {/* {!editUserDetailsBtn && (
            <Tooltip
              title={
                maxPermission !== 1
                  ? "You do not have necessary permissions to perform this action"
                  : "Edit user"
              }
            >
              <button
                className={`bg-blue-500 text-white rounded-md px-4 py-2 cursor-pointer ${maxPermission > 2 ? "bg-gray-400 !cursor-not-allowed" : ""
                  }`}
                onClick={(e) => setEditUserDetailsBtn(true)}
                disabled={maxPermission > 2}
              >
                Edit
              </button>
            </Tooltip>
          )} */}
        </div>
        {/* <div className="bg-white flex  px-5 py-5">
          <div className="flex items-center">Role : </div>
          <div className="ml-10">
            <Select
              isDisabled={!editUserDetailsBtn}
              className="w-[100%] "
              getOptionLabel={(option) => option.name}
              defaultValue={[]}
              value={saveNewUserRole}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(options) => {
                setSaveNewUserRole(options)
              }}
              placeholder={"Select Role..."}
              options={allRoles}
              components={{
                Option: InputOption,
              }}
            />
          </div>
          <div className="flex justify-center items-center mx-2">
            <Tooltip
              // title="Super Admin : Can Create, Modify, Delete Resources"
              title={
                <div>
                  <p>Super Admin : Can Create, Modify, Delete Resources</p>
                  <p>Admin : Can Create, Modify Resources</p>
                  <p>Normal User : Can View Resources</p>
                </div>
              }
            >
              <span>
                <FaInfoCircle size={15} />
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="bg-white px-5 py-5">
          <div>Access Controls :</div>
          <div className="relative">
            <DimensionsAccessControl
              isDisabled={!editUserDetailsBtn}
              handleAddNewDimension={handleAddNewDimension}
              saveNewDimensions={saveNewDimensions}
              dimensions={dimensions}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </div>
        </div>
        <div className="text-red-500 mx-5">{showMessage}</div>
        <div className="flex mx-6 mb-5 ">
          <div className="flex">
            <button
              className={` ${
                editUserDetailsBtn ? " bg-green-500 " : " bg-gray-300 "
              } text-white rounded-md px-5 py-2 mr-5 cursor-pointer `}
              onClick={handleSaveButton}
              disabled={!editUserDetailsBtn}
            >
              Save
            </button>
          </div>
          <div className="flex">
            <button
              className={`${
                editUserDetailsBtn ? "  bg-red-500 " : " bg-gray-300 "
              } text-white rounded-md px-5 py-2 cursor-pointer`}
              onClick={handleCancelButton}
              disabled={!editUserDetailsBtn}
            >
              Cancel
            </button>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default UserDetailedComponent
