import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './createObjectives.css'
import { useNavigate } from 'react-router-dom'

import {
  axiosErrorHandler,
  axiosUtilsGet,
  axiosUtilsPost,
} from '../../../../config/Utils/AxiosUtils'
import LabelInput from '../../../shared/LabelInput/LabelInput'
import Spinner from '../../../shared/Spinner/Spinner'
import dropdownOptionsInitialStateMap, {
  formulaItemsMetrics,
} from '../Okr utils/dropdownOptionsInitialStateMap'
import {
  checkingNotNull,
  handleDropdownChange,
  shouldDropdownBeEnabled,
} from '../Okr utils/handleChangeOperationsInOKR'
import { verticalMenuOptionConstants } from '../Okr utils/okrContants'
import Filter from '../SharedOkrComponents/Filter/Filter'
import FormulaBuilder from '../SharedOkrComponents/FormulaBuilder/FormulaBuilder'
import CustomLineChart from '../SharedOkrComponents/LineChart/LineChart'
import ObjectiveExpandableRow from '../SharedOkrComponents/ObjectiveExpandableRow/ObjectiveExpandableRow'
import Specifics from '../SharedOkrComponents/Specifics/Specifics'

function CreateObjectives({ hideHomeHeader, isEditable, setVerticalMenuSelection, parentOKRId, maxPermission }) {
  const navigate = useNavigate()
  const [dropdowns, setDropdowns] = useState(dropdownOptionsInitialStateMap)
  const [formula, setFormula] = useState([])
  const [comparator, setComparator] = useState(null)

  const [showMandatoryMessage, setShowMandatoryMessage] = useState('')
  const [showLoading, setShowLoading] = useState(false)

  const [formulaMetrics, setFormulaMetrics] = useState(formulaItemsMetrics)

  useEffect(() => {
    //all list of users
    setShowLoading(true)
    const allUsersForOwner = axiosUtilsGet('/api/users')
    //geography list
    const geographyList = axiosUtilsGet('/api/geographies/')
    //product list
    const productList = axiosUtilsGet('/api/products/')
    //Metrics List for BODMAS FORMAULA
    const allMetricsList = axiosUtilsGet('/api/metrics')

    Promise.all([allUsersForOwner, geographyList, productList, allMetricsList])
      .then(responses => {
        // setParticularOkrData(responses[0]?.data?.data)
        setShowLoading(false)
        const modifiedOwnerList = responses[0].map(item => ({
          name: item.first_name + ' ' + item.last_name,
          id: item.id,
          value: item.id,
        }))
        const updatedNewData = responses[3].data.map(({ id, name, slug }) => ({
          id,
          name,
          slug,
          value: name,
        }))
        const GeographyPaths = responses[1].data.map(item => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(' >> '),
        }))
        const ProductPaths = responses[2].data.map(item => ({
          id: item.id,
          key: item.id,
          value: item.id,
          name: item.path.join(' >> '),
        }))

        setFormulaMetrics([...formulaMetrics, ...updatedNewData])
        setDropdowns(prevState => ({
          ...prevState, // This spreads the previous state to retain all existing properties
          OWNER: {
            ...prevState.OWNER, // This retains all properties of OWNER
            options: modifiedOwnerList,
          },
          'PRODUCT-DIMENSIONS': {
            ...prevState['PRODUCT-DIMENSIONS'],
            options: ProductPaths,
          },
          'GEOGRAPHY-DIMENSIONS': {
            ...prevState['GEOGRAPHY-DIMENSIONS'],
            options: GeographyPaths,
          },
        }))
      })
      .catch(error => {
        // console.log(error)
        axiosErrorHandler(error, navigate)
        setShowLoading(false)
      })
  }, [])

  const objectiveOptions = [
    {
      name: 'SPECIFICS',
      data: (
        <Specifics
          isEditable={isEditable}
          dropdowns={dropdowns}
          setDropdowns={setDropdowns}
          handleDropdownChange={handleDropdownChange}
        />
      ),
    },
    {
      name: 'DEFINE',
      data: (
        <>
          <div className="m-4"></div>
          <FormulaBuilder
            isEditable={isEditable}
            formulaMetrics={formulaMetrics}
            formula={formula}
            setFormula={setFormula}
            comparator={comparator}
            setComparator={setComparator}
          />
        </>
      ),
    },
    {
      name: 'FILTER',
      data: (
        <Filter
          isEditable={isEditable}
          dropdowns={dropdowns}
          setDropdowns={setDropdowns}
          shouldDropdownBeEnabled={shouldDropdownBeEnabled}
          handleDropdownChange={handleDropdownChange}
        />
      ),
    },
  ]

  const [openRow, setOpenRow] = useState(null)

  const toggleRow = name => {
    setOpenRow(name === openRow ? null : name)
  }

  const handleCreateNewOKR = () => {
    const checkinMessageAndNotNull = checkingNotNull(dropdowns, formula, comparator)
    setShowMandatoryMessage(checkinMessageAndNotNull[0])
    // console.log(dropdowns)
    if (checkinMessageAndNotNull[1]) {
      setShowLoading(true)
      axiosUtilsPost('/api/okrs/', {
        okr: {
          title: dropdowns?.OBJECTIVE?.value,
          expression: formula.map(item => item.slug),
          comparator: comparator.value,
          parent_id: parentOKRId,
          visibility: dropdowns?.VISIBLITY.value.value,
          start_financial_year: dropdowns['FROM-YEAR'].value.value,
          start_financial_quarter: dropdowns['FROM-QUARTER']?.value?.value,
          start_financial_month: dropdowns['FROM-MONTH']?.value?.value,
          end_financial_year: dropdowns['TO-YEAR'].value.value,
          end_financial_quarter: dropdowns['TO-QUARTER']?.value?.value,
          end_financial_month: dropdowns['TO-MONTH']?.value?.value,
          interval: dropdowns.TRACKING.value.value,
          owner_id: dropdowns.OWNER.value.id,
          // geography_id: dropdowns['GEOGRAPHY-DIMENSIONS'].value.id,
          // product_id: dropdowns['PRODUCT-DIMENSIONS']?.value?.id,
          geography_id: 1,
          product_id: 1,
        },
      })
        .then(res => {
          // console.log(res)
          setShowLoading(false)
          setVerticalMenuSelection(null)
        })
        .catch(err => {
          axiosErrorHandler(err, navigate)
          setShowLoading(false)
          setVerticalMenuSelection(null)
        })
    }
  }
  return (
    <div className={`bg-gray-100  ${hideHomeHeader ? 'h-[95vh]' : 'h-[80vh]'} `}>
      <div className=" py-5  px-12 text-gray-700">
        {showLoading && <Spinner />}
        <div
          className={`${
            hideHomeHeader ? 'h-[80vh]' : 'h-[65vh]'
          }  border-[1px]  bg-white rounded-xl overflow-x-hidden ${showLoading && 'blur-sm'}`}
        >
          <div className="flex justify-start items-center mb-4 mx-5 mt-5">
            <div className="mr-[5.6rem] w-[90%] px-4">
              <div className="label font-bold p-2">WRITE OBJECTIVE</div>
              <div>
                <input
                  required
                  value={dropdowns?.OBJECTIVE?.value}
                  className={`border rounded p-2 m-2 w-full`}
                  placeholder={dropdowns?.OBJECTIVE?.placeholder}
                  onChange={e => handleDropdownChange(e.target.value, 'OBJECTIVE', setDropdowns)}
                />
              </div>
            </div>
          </div>
          <div className="mt-1 mx-5">
            {objectiveOptions.map(option => (
              <ObjectiveExpandableRow
                key={option.name}
                name={option.name}
                isOpen={option.name === openRow}
                toggleRow={() => toggleRow(option.name)}
                data={<div>{option.data}</div>}
              />
            ))}
          </div>
        </div>
        <p className="text-red-500">{showMandatoryMessage}</p>
        <div className="flex justify-center m-2">
          <button
            className="bg-blue-600 px-4 py-2 rounded text-white mr-4"
            onClick={handleCreateNewOKR}
          >
            CREATE
          </button>
          <button
            className="bg-red-600 px-4 py-2 rounded text-white"
            onClick={() => {
              setVerticalMenuSelection(null)
            }}
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateObjectives
