import React, { useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

const InputField = props => {
  const [type, setType] = useState(props.type)
  return (
    <div className="mt-[30px] relative">
      <label>
        <h1 className="my-[10px]">{props.name}:</h1>
        <input
          type={type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={e => props.dispatch({ type: props.type, value: e.target.value })}
          className="bg-white border border-gray-300 rounded-md w-full p-2.5"
          required
        />
        {props.name === 'Password' &&
          (type === 'password' ? (
            <FaRegEyeSlash
              className="absolute top-11 right-4 cursor-pointer justify-end"
              onClick={() => setType(type == 'text' ? 'password' : 'text')}
            />
          ) : (
            <FaRegEye
              className="absolute top-11 right-4 cursor-pointer justify-end"
              onClick={() => setType(type == 'text' ? 'password' : 'text')}
            />
          ))}
      </label>
    </div>
  )
}

export default InputField
