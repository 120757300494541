import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'

import UserCard from './UserCard'

const UsersListComponent = ({ loading, allUsersDetails, setOpenViewCard, setUserOrderNumber }) => {
  const [hideHomeHeader] = useOutletContext()

  const handleOnClickCard = index => {
    setOpenViewCard(true)
    setUserOrderNumber(index)
  }
  const defaultDetails = {
    user_sessions: [],
    profile_picture_url: '',
    status_id: 1,
    email: '',
    first_name: '',
    last_name: '',
  }

  // console.log(allUsersDetails.length)

  return (
    <div className={`relative`}>
      {allUsersDetails.length > 0 ? (
        allUsersDetails.map((item, index) => {
          // console.log(item)
          return (
            <div key={index} onClick={e => handleOnClickCard(index)} className="cursor-pointer ">
              <UserCard details={item} loading={loading} />
            </div>
          )
        })
      ) : (
        <>
          <UserCard details={defaultDetails} loading={loading} />
          {/* <UserCard details={defaultDetails} loading={loading} />
          <UserCard details={defaultDetails} loading={loading} />
          <UserCard details={defaultDetails} loading={loading} /> */}
        </>
      )}
    </div>
  )
}

export default UsersListComponent
