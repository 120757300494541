import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet } from "react-router-dom"
import { Tooltip } from "recharts"

import HomeHeader from "./HomeHeader/HomeHeader"
import HomeOptions from "./HomeOptions"
import { axiosErrorHandler, axiosUtilsGet } from "../../config/Utils/AxiosUtils"
import FullScreen from "../shared/FullScreen/FullScreen"

export const homeOptions = {
  PERFORMANCE: "PERFORMANCE (OKRS)",
  MIS: "MIS (MANAGEMENT INFORMATION SYSTEM",
  UPLOAD: "UPLOAD PLANNED DATA",
  DATA: "MY ACTUAL DATA",
  SETTINGS: "SETTINGS",
}

export const handleHomeOptionsClick = (path, navigate) => {
  const noNavigation = [
    "/dashboard",
    "/results",
    "/reports",
    "/predictions",
    "/decisions",
    "/alerts",
  ]

  if (!noNavigation.includes(path)) {
    navigate("/home" + path)
  }
  if (path === "/settings") {
    navigate("/home" + path + "/user/profile")
  }
}

const Home = () => {
  const [hideHomeHeader, setHideHomeHeader] = useState(false)
  const [allUsersDetails, setAllUsersDetails] = useState([])
  const isDataSourceConnected = true
  const userInfo = useSelector(
    (stores) => stores.userInfoStoreReducer.userDetails
  )

  useEffect(() => {
    axiosUtilsGet("/api/users/details")
      .then((res) => {
        setAllUsersDetails(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const currentUserDetails = allUsersDetails.filter((user) => {
    if (user.id === userInfo.id) {
      return user
    }
  })
  const currentUserPermissions = currentUserDetails[0]?.user_roles
  let maxPermission
  if (currentUserDetails.length > 0) {
    maxPermission = Math.min(
      ...currentUserPermissions.map((perm) => perm.role_id)
    )
    console.log(maxPermission)
  }

  return (
    <div>
      <div className={`${hideHomeHeader ? "hidden" : ""}`}>
        <HomeHeader />
        <div className="flex border-2 py-2 overflow-x-auto whitespace-no-wrap justify-center bg-slate-50">
          <HomeOptions
            name="CONNECT"
            label="CONNECT"
            setClick={handleHomeOptionsClick}
          />
          <HomeOptions
            name="CLEAN"
            label="CLEAN"
            setClick={handleHomeOptionsClick}
          />
          {/* <HomeOptions
            name="MIS"
            label="MIS"
            setClick={handleHomeOptionsClick}
            isDisabled={!isDataSourceConnected}
          />
          <HomeOptions
            name="PERFORMANCE"
            label="PERFORMANCE"
            setClick={handleHomeOptionsClick}
            isDisabled={!isDataSourceConnected}
          /> */}
          <HomeOptions
            name="METRICS"
            label="CREATE"
            setClick={handleHomeOptionsClick}
            isDisabled={!isDataSourceConnected}
          />
          {/* <HomeOptions
            name="UPLOAD"
            label="PLANNED DATA"
            setClick={handleHomeOptionsClick}
            isDisabled={!isDataSourceConnected || maxPermission > 2}
            maxPermission={maxPermission}
          /> */}
          {/* <HomeOptions
            name="DATA"
            label="MY DATA"
            setClick={handleHomeOptionsClick}
            isDisabled={!isDataSourceConnected}
          /> */}
          <HomeOptions
            name="SETTINGS"
            label="SETTINGS"
            setClick={handleHomeOptionsClick}
            isDisabled={false}
          />
          {/* <HomeOptions name="ALERTS" label="ALERTS" setClick={handleHomeOptionsClick} isDisabled={true} />
          <HomeOptions name="DASHBOARD" label="DASHBOARD" setClick={handleHomeOptionsClick} isDisabled={true} />
          <HomeOptions name="REPORTS" label="REPORTS" setClick={handleHomeOptionsClick} isDisabled={true} />
          <HomeOptions name="PREDICTION" label="PREDICTION" setClick={handleHomeOptionsClick} isDisabled={true} />
          <HomeOptions name="DECISION" label="DECISION" setClick={handleHomeOptionsClick} isDisabled={true} /> */}
          {/* <HomeOptions name="Developers" setClick={handleHomeOptionsClick} /> */}
        </div>
      </div>
      <div className="mx-2">
        <FullScreen
          isFullScreen={hideHomeHeader}
          setIsFullScreen={setHideHomeHeader}
        />
      </div>
      <div>
        <Outlet context={[hideHomeHeader, maxPermission]} />
      </div>
    </div>
  )
}

export default Home
