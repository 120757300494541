import React from "react"

import profileImage from "../../../../../assets/images/userProfileImage.png"

const ProfileCard = ({ user }) => {
  return (
    <div className="w-full bg-white rounded-lg shadow-lg p-4 mb-4">
      <div className="flex items-center">
        <img
          src={user.imageUrl}
          alt={user.name}
          className="w-12 h-12 rounded-full mr-4"
          onError={(e) => {
            e.target.src = profileImage
          }}
        />
        <div>
          <p className="text-base font-semibold">{user.name}</p>
          <p className="text-gray-600 text-sm">{user.email}</p>
        </div>
      </div>

      <div className="my-4 flex p-1">
        {user.labels.slice(0, 6).map((label, index) => (
          <div key={index} className="flex-1 p-3">
            <p className="font-semibold underline text-sm">{label.name}</p>
            <p className="text-gray-600 text-sm">{label.value}</p>
          </div>
        ))}
      </div>

      <div className="my-4 flex p-1">
        {user.labels.slice(6).map((label, index) => (
          <div key={index} className="flex-1 p-3">
            <p className="font-semibold underline text-base">{label.name}</p>
            <p className="text-gray-600 text-base">{label.value}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfileCard
