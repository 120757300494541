import Skeleton from "@mui/material/Skeleton"
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import {
  BiPlusCircle,
  BiMinusCircle,
  BiDotsVerticalRounded,
} from "react-icons/bi"
import { FaPlus, FaMinus } from "react-icons/fa6"
import "./OkrsExpandableRow.css"
import { useNavigate } from "react-router-dom"

import profileImage from "../../../../assets/images/userProfileImage.png"
import {
  axiosErrorHandler,
  axiosUtilsDelete,
  axiosUtilsGet,
  axiosUtilsPost,
} from "../../../../config/Utils/AxiosUtils"
import DialogComponent from "../../../shared/DialogComponent/DialogComponent"
import Spinner from "../../../shared/Spinner/Spinner"
import { verticalMenuOptionConstants } from "../Okr utils/okrContants"

function RecursiveRow({
  showLoading,
  data,
  setVerticalMenuSelection,
  setIdAfterClick,
  id,
  setDeleteOKR,
  setParentOKRId,
  maxPermission,
}) {
  console.log(maxPermission)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const menuRef = useRef() // Reference to the menu
  const menuButtonRef = useRef() // Reference to the menu button (three dots)
  const navigate = useNavigate()

  const handleToggle = (e) => {
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  const toggleMenu = (e) => {
    e.stopPropagation()
    setIsMenuOpen((prev) => !prev) // Toggle the state of the menu
  }

  const handleParticularOKRClick = (e, option) => {
    //
    // setShowDialog(true)
    e.stopPropagation()
    setIsMenuOpen(false)
    if (option === "DELETE") {
      setShowDialog(true)
    }
    // Stop the event from bubbling up
    // console.log(option)
    // console.log(data.id)
    setIdAfterClick(data.id)
    // if (option === 'VIEW' || option === 'EDIT') {
    //   setIdAfterClick(data.id)
    // }
    if (option === verticalMenuOptionConstants.CREATE) {
      setParentOKRId("" + data.id)
    }
    if (option === verticalMenuOptionConstants.DUPLICATE) {
      fetchOneOkrCreateThatOkr()
    }
    setVerticalMenuSelection(option)
  }
  const fetchOneOkrCreateThatOkr = () => {
    // console.log(data.id)
    setDeleteOKR(true)
    axiosUtilsGet("/api/okrs/" + data.id)
      .then((res) => {
        // console.log(res.data.data)
        return axiosUtilsPost("/api/okrs/", {
          okr: {
            title: res.data.data?.title,
            expression: res.data.data?.expression,
            comparator: res.data.data?.comparator,
            parent_id: res.data.data?.parent_id,
            visibility: res.data.data?.visibility,
            start_financial_year: res.data.data?.start_financial_year,
            start_financial_quarter: res.data.data?.start_financial_quarter,
            start_financial_month: res.data.data?.start_financial_month,
            end_financial_year: res.data.data?.end_financial_year,
            end_financial_quarter: res.data.data?.end_financial_quarter,
            end_financial_month: res.data.data?.end_financial_month,
            interval: res.data.data?.interval,
            owner_id: res.data.data?.owner_id,
            geography_level: res.data.data?.geography_level,
            geography_ids: res.data.data?.geography_ids,
            product_level: res.data.data?.product_level,
            product_ids: res.data.data?.product_ids,
          },
        })
      })
      .then((res) => {
        setDeleteOKR(false)
      })
      .catch((err) => {
        axiosErrorHandler(err, navigate)
        setDeleteOKR(false)
      })
  }

  const handleDeletOKRClick = () => {
    // // console.log(data.id)
    setDeleteOKR(true)
    axiosUtilsDelete("/api/okrs/" + data.id)
      .then((res) => {
        // console.log('OKR delete Successfully!')
        setDeleteOKR(false)
      })
      .catch((err) => {
        axiosErrorHandler(err, navigate)
        setDeleteOKR(false)
      })
  }

  const handleCreateDuplicate = () => {
    // console.log(id)
  }

  const isVerticalMenuItemDisabled = (option) => {
    return verticalMenuOptionConstants[option] === "VIEW"
      ? false
      : maxPermission > 2 ||
          (maxPermission === 2 &&
            verticalMenuOptionConstants[option] === "DELETE")
  }

  // Close the menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [menuRef])

  return (
    <>
      <div className=" bg-white py-3  my-2 shadow-lg rounded-md">
        {showDialog && (
          <DialogComponent
            handleDeleteAndCancelUserBtn={handleDeletOKRClick}
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            warningTitle={"Do you want to delete it?"}
            warningText={
              "Are you sure you want to delete this OKR? All KRs for this OKR will also be deleted."
            }
            yesButton={"YES"}
            noButton={"CANCEL"}
          />
        )}
        <div
          onClick={(e) => {
            !showLoading &&
              setVerticalMenuSelection(verticalMenuOptionConstants.VIEW)
            !showLoading && setIdAfterClick(id)
          }}
          className="mb-2 cursor-pointer"
        >
          <div className="flex justify-center">
            <div className="w-full p-2 h-auto  flex-col justify-start items-center relative  rounded-md">
              <div className="flex justify-start items-center border-b-2 mb-2 ">
                <div className="w-2/5 small-label mb-1">
                  {showLoading ? <Skeleton width={0} /> : `OBJECTIVE`}
                </div>
                <div className="flex w-3/5 gap-9 justify-between custom-width-row small-label mb-1">
                  <div>{showLoading ? <></> : `CREATED BY`}</div>
                  <div>{showLoading ? <></> : `OWNER`}</div>
                  <div>{showLoading ? <></> : `Target`}</div>
                  <div>{showLoading ? <></> : `STATUS`}</div>
                </div>
              </div>
              <div className="flex justify-start items-center text-gray-700">
                <div className="w-2/5 font-bold">
                  {showLoading ? (
                    <Skeleton width={100} height={20} />
                  ) : (
                    data?.data?.title
                  )}
                </div>
                <div className="flex w-3/5 gap-9 justify-between custom-width-row">
                  <div className="flex w-full">
                    <div className="flex justify-center items-center mr-2">
                      {showLoading ? (
                        <Skeleton variant="circular" width={20} height={20} />
                      ) : (
                        <img
                          src={data?.data?.creator_profile_picture_url}
                          className="w-[20px] h-[20px] rounded-full  "
                          onError={(e) => {
                            e.target.src = profileImage
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {showLoading ? (
                        <Skeleton width={80} height={20} />
                      ) : (
                        data?.data?.creator_name
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex justify-center items-center mr-2">
                      {showLoading ? (
                        <Skeleton variant="circular" width={20} height={20} />
                      ) : (
                        <img
                          src={data?.data?.owner_profile_picture_url}
                          className="w-[20px] h-[20px] rounded-full  "
                          onError={(e) => {
                            e.target.src = profileImage
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {showLoading ? (
                        <Skeleton width={80} height={20} />
                      ) : (
                        data?.data?.owner_name
                      )}
                    </div>
                  </div>
                  <div>
                    {showLoading ? (
                      <Skeleton width={100} height={20} />
                    ) : (
                      data?.data?.combined_okr_values?.[0].planned
                    )}
                  </div>
                  <div
                    className={`${
                      data?.data?.combined_okr_values?.[0].status === "Lagging"
                        ? "text-red-500"
                        : "text-green-500"
                    }`}
                  >
                    {showLoading ? (
                      <Skeleton width={100} height={20} />
                    ) : (
                      data?.data?.combined_okr_values?.[0].status
                    )}
                  </div>
                </div>
              </div>
              {!showLoading && data?.children?.length !== 0 && (
                <div
                  className="absolute left-[-2rem] top-[35%] hover:cursor-pointer rounded-sm p-[2px] bg-blue-100 text-blue-500"
                  onClick={handleToggle}
                >
                  {isExpanded ? (
                    <div className="flex ">
                      <FaMinus size={"1.25em"} />
                    </div>
                  ) : (
                    <FaPlus size={"1.25em"} />

                    // <BiPlusCircle size={'1.5em'} />
                  )}
                </div>
              )}
              {/* Three-dot menu icon */}
              {!showLoading && (
                <div
                  ref={menuButtonRef}
                  className="absolute top-2 right-2 hover:cursor-pointer hover:bg-blue-100 z-50"
                  onClick={toggleMenu}
                >
                  <BiDotsVerticalRounded size={"1.5em"} />
                </div>
              )}

              {/* Menu options */}
              {isMenuOpen && (
                <div
                  ref={menuRef}
                  className="absolute top-8 right-2 bg-white border rounded z-[100]"
                >
                  {Object.values(verticalMenuOptionConstants).map(
                    (option, index) => (
                      // <Tooltip
                      //   key={index}
                      //   title={
                      //     isVerticalMenuItemDisabled(option)
                      //       ? "You do not have necessary permissions to perform this action"
                      //       : ""
                      //   }
                      // >
                      <button
                        key={index}
                        className={`block w-full text-left hover:bg-gray-200 cursor-pointer p-2 border-b last:border-b-0 ${
                          isVerticalMenuItemDisabled(option)
                            ? "bg-gray-100 !cursor-not-allowed"
                            : ""
                        }`}
                        onClick={(e) => {
                          handleParticularOKRClick(e, option)
                        }}
                        disabled={isVerticalMenuItemDisabled(option)}
                      >
                        {option}
                      </button>
                      // </Tooltip>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isExpanded && data?.children !== 0 && (
        <div className="ml-[10px] flex">
          <div className="w-[1.5px] bg-gray-300 mx-5"></div>
          <div className="w-full">
            {data?.children.map((child) => {
              return (
                <RecursiveRow
                  setDeleteOKR={setDeleteOKR}
                  id={child.id}
                  data={child}
                  key={child.id}
                  setParentOKRId={setParentOKRId}
                  setIdAfterClick={setIdAfterClick}
                  setVerticalMenuSelection={setVerticalMenuSelection}
                  maxPermission={maxPermission}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

function OkrsExpandableRow({
  setVerticalMenuSelection,
  setIdAfterClick,
  setParentOKRId,
  maxPermission,
}) {
  const [OkrData, setOkrData] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [deleteOKR, setDeleteOKR] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    // // console.log('okrExpandable')
    setShowLoading(true)
    axiosUtilsGet("/api/okrs/")
      .then((res) => {
        setOkrData(res)
        setShowLoading(false)
      })
      .catch((err) => {
        axiosErrorHandler(err, navigate)
        setShowLoading(false)
      })
  }, [deleteOKR])
  return (
    <div className="flex justify-center my-4">
      {showLoading || deleteOKR ? (
        // <Spinner />
        <div className="w-4/5 h-auto flex-col justify-start items-center relative">
          {[1, 2, 3, 4, 5, 6].map((item) => {
            // // console.log(item)
            return (
              <RecursiveRow
                showLoading={showLoading}
                setDeleteOKR={setDeleteOKR}
                key={item.id}
                data={item}
                id={item.id}
                setIdAfterClick={setIdAfterClick}
                setParentOKRId={setParentOKRId}
                setVerticalMenuSelection={setVerticalMenuSelection}
                maxPermission={maxPermission}
              />
            )
          })}
        </div>
      ) : (
        <div className="w-4/5 h-auto flex-col justify-start items-center relative">
          <div>
            <div className="flex flex-col justify-center items-center">
              {OkrData.length === 0 && (
                <>
                  <img
                    src={require("../../../../assets/images/coding_okr.png")}
                  />
                  <p className="text-xl font-extrabold ">
                    No Performance to Show
                  </p>
                </>
              )}
            </div>
            {OkrData.map((item) => {
              // // console.log(item)
              return (
                <RecursiveRow
                  showLoading={showLoading}
                  setDeleteOKR={setDeleteOKR}
                  key={item.id}
                  data={item}
                  id={item.id}
                  setIdAfterClick={setIdAfterClick}
                  setParentOKRId={setParentOKRId}
                  setVerticalMenuSelection={setVerticalMenuSelection}
                  maxPermission={maxPermission}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default OkrsExpandableRow
