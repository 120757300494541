import React, { useEffect, useState } from 'react'

import QuarterData from './QuarterData'
import YearDataComponent from './YearDataComponent'

const YearData = ({ name, yearIndex, type }) => {
  const [onClickYear, setOnClickYear] = useState(false) //after we click on any year

  return (
    <div className="relative">
      <YearDataComponent
        name={name}
        classProperty={onClickYear && 'bg-blue-400 text-white hover:bg-blue-400'}
        onClickDiv={onClickYear}
        setOnClickDiv={setOnClickYear}
        yearIndex={yearIndex}
        type={type}
      />

      {onClickYear && (
        <div className="flex relative">
          <YearDataComponent
            name={name}
            classProperty="pointer-events-none bg-blue-400 text-white"
          />
          <QuarterData
            name="Q1"
            months={['April', 'May', 'June']}
            type="quarter"
            yearIndex={yearIndex}
            quarterIndex="0"
          />
          <QuarterData
            name="Q2"
            months={['July', 'Aug', 'Sept']}
            type="quarter"
            yearIndex={yearIndex}
            quarterIndex="1"
          />
          <QuarterData
            name="Q3"
            months={['Oct', 'Nov', 'Dec']}
            type="quarter"
            yearIndex={yearIndex}
            quarterIndex="2"
          />
          <QuarterData
            name="Q4"
            months={['Jan', 'Feb', 'March']}
            type="quarter"
            yearIndex={yearIndex}
            quarterIndex="3"
          />

          {/* {data.quarters.map((item, index) => {
            // console.log(item)
            return (
              <>
                <QuarterData
                  data={item}
                  name={'Q' + item.fq}
                  months={['July', 'Aug', 'Sept']}
                  type="quarter"
                  yearIndex={yearIndex}
                  quarterIndex={index}
                />
              </>
            )
          })} */}
        </div>
      )}
    </div>
  )
}

export default YearData
