// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari */
}

.sticky_data {
  position: sticky;
  left: 0;
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
  /* This will ensure that the header sticks to the left side while scrolling */
  /* z-index: 10; Optional: in case there's other overlapping content */

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 2s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Dive/Dive.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,OAAO;EACP,kBAAkB;EAClB,iCAAiC;EACjC,6EAA6E;EAC7E,qEAAqE;;AAEvE;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".hide-scrollbar::-webkit-scrollbar {\n  display: none;\n  /* for Chrome, Safari */\n}\n\n.sticky_data {\n  position: sticky;\n  left: 0;\n  overflow-x: hidden;\n  /* Prevent horizontal scrolling */\n  /* This will ensure that the header sticks to the left side while scrolling */\n  /* z-index: 10; Optional: in case there's other overlapping content */\n\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.spinner {\n  animation: spin 2s linear infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
