import React, { useState } from "react"
import { useSelector } from "react-redux"

import profileImage from "../../../../assets/images/userProfileImage.png"

const Profile = ({ maxPermission }) => {
  const userInfo = useSelector(
    (stores) => stores.userInfoStoreReducer.userDetails
  )
  const [profileImageUrl, setProfileImageUrl] = useState(
    userInfo?.profile_picture_url || profileImage
  )
  return (
    <div>
      <div className="rounded w-full shadow-sm border border-gray-200 py-5 bg-white pt-10">
        <div className="flex justify-between mx-10 ">
          <div className="flex">
            <div className="flex justify-center items-center">
              <img
                className=" rounded-full mr-5   w-[80px] h-[80px]"
                src={profileImageUrl}
                onError={() => setProfileImageUrl(profileImage)}
                alt="profile_img"
              />
            </div>
            <div className="flex flex-col justify-center ">
              <h1 className="text-xl mb-1">
                {userInfo.first_name + " " + userInfo.last_name}
              </h1>
              <h1>{userInfo.email}</h1>
            </div>
          </div>
          <div className="flex justify-center items-center">
            {/* <button className="bg-green-500 py-2 px-8 rounded shadow-sm text-white h-[50%] hover:bg-green-600">
              Edit
            </button> */}
          </div>
        </div>
        <div className="flex w-[70%]  mx-10 mt-10">
          <div className="flex flex-col w-[500px]">
            <div className="underline">First Name</div>
            <div className="text-lg my-2">{userInfo.first_name}</div>
          </div>
          <div className="flex flex-col">
            <div className="underline">Last Name</div>
            <div className="text-lg my-2">{userInfo.last_name}</div>
          </div>
        </div>
        <div className="flex w-[70%] mx-10 mt-10 mb-5">
          <div className="flex flex-col w-[500px] ">
            <div className="underline">Email </div>
            <div className="text-lg my-2">{userInfo.email}</div>
          </div>
          {/* <div className="flex flex-col">
            <div className="underline">Phone Number </div>
            <div className="text-lg my-2">-</div>
          </div> */}
        </div>
      </div>
      {/* <div className="rounded w-full shadow-sm border border-gray-200  bg-white mt-5 pb-5">
        <div className=" text-gray-600 font-semibold border-b-[1px] bg-gray-50 py-5">
          <h1 className="text-xl mx-10 py-2">WORK DETAILS</h1>
        </div>
        <div className="mx-10 ">
          <div className="flex py-5">
            <div className="w-1/6   flex items-center">Employee Id </div>
            <span className="mx-5  flex items-center">-</span>
            <div className="2/3 text-lg">B123</div>
          </div>
          <div className="flex py-5">
            <div className="w-1/6    flex items-center">Designation</div>
            <span className="mx-5  flex items-center">-</span>
            <div className="2/3  text-lg">Software Engineer</div>
          </div>
          <div className="flex py-5 ">
            <div className="w-1/6    flex items-center">Team</div>
            <span className="mx-5  flex items-center">-</span>
            <div className="2/3  text-lg">Technology</div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Profile
