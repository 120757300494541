import React from 'react'
import Select from 'react-select'

const SimpleDropdown = ({ customStype, data, isDisabled, placeholder, onChange, value }) => {
  return (
    <Select
      getOptionLabel={option => option.name}
      value={value}
      // value={data?.some(option => option?.value === value?.value) ? value : null}
      className={`rounded p-2 m-2 w-[128px] ${customStype}`}
      options={data}
      isDisabled={isDisabled}
      placeholder={placeholder}
      onChange={onChange}
    ></Select>
  )
}

export default SimpleDropdown
