import Skeleton from "@mui/material/Skeleton"
import { formatDistanceToNow } from "date-fns"
import React, { useState } from "react"

import profileImage from "../../../../assets/images/userProfileImage.png"

const UserCard = ({ details, loading }) => {
  const date = new Date(
    details?.user_sessions?.length === 0
      ? "2024-01-02T10:32:49.574Z"
      : details?.user_sessions?.[0]?.created_at
  )
  // const date = new Date('2024-01-02T10:32:49.574Z')
  const timeAgoString = formatDistanceToNow(date, { addSuffix: true })
  const [profileImageUrl, setProfileImageUrl] = useState(
    details?.profile_picture_url || profileImage
  )
  return (
    <div className="rounded-md shadow-md my-6 py-2 mx-2 px-2 ">
      <div className="flex">
        <div className="">
          {!loading ? (
            <img
              src={profileImageUrl}
              onError={() => setProfileImageUrl(profileImage)}
              alt="profile_img"
              className="w-[50px] h-[50px] rounded-full"
            />
          ) : (
            <Skeleton width={50} height={50} variant="circular" />
          )}
        </div>
        <div className="flex flex-col mx-2 justify-center">
          <div className="font-semibold mb-1">
            {loading ? (
              <Skeleton width={150} height={20} />
            ) : details?.status_id === 1 ? (
              ""
            ) : (
              details?.first_name + " " + details?.last_name
            )}
          </div>
          <div className="text-xs">
            {loading ? <Skeleton width={150} height={20} /> : details?.email}
          </div>
        </div>{" "}
      </div>
      <div className="bottom-2 flex justify-between mt-10 mx-2">
        <div className="">
          {loading ? (
            <Skeleton width={200} height={20} />
          ) : (
            details?.user_sessions?.length !== 0 &&
            `Last login: ${timeAgoString}`
          )}
        </div>
        <div>
          {loading ? (
            <Skeleton width={100} height={20} />
          ) : details?.status_id === 1 ? (
            `🔵 Invited`
          ) : details?.status_id === 2 ? (
            `🟢 Active`
          ) : (
            `🔴 Not Active`
          )}
        </div>
      </div>
    </div>
  )
}

export default UserCard
