import axios from "axios"
import React, { useEffect } from "react"
import "./Invite.css"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import profileImage from "../../assets/images/userProfileImage.png"
import {
  axiosErrorHandler,
  axiosUtilsPost,
} from "../../config/Utils/AxiosUtils"

const Invite = () => {
  function normalizePath(path) {
    return path.replace(/\/+/g, "/")
  }
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  //sample url - https://prabhat.actuals.co.in/invite?id=140a29b0-3065-488e-ba6f-02c2b55a1735&token=e7ceaa2f87f2bbfbb8768414d1edd6a2
  useEffect(() => {
    axiosUtilsPost("/api/users/invite/check", {
      token: searchParams.get("token"),
      invite_id: searchParams.get("id"),
    })
      .then((res) => {
        // console.log(res)
        const isValid = res.data.data.valid

        // console.log(isValid)
        if (isValid) {
          // searchParams.append('id')
          const params = new URLSearchParams({
            id: searchParams.get("id"),
            token: searchParams.get("token"),
          })
          return navigate(`/login?${params.toString()}`)
        } else {
          return navigate("/invite/link-expired")
        }
      })
      .catch((err) => {
        axiosErrorHandler(err, navigate)
      })
  }, [])
  return (
    <div className=" flex justify-center items-center">
      <img
        src={require("../../assets/images/logo_icon_actuals.png")}
        className="icon"
        onError={(e) => {
          e.target.src = profileImage
        }}
      />
      <p>Please Wait...</p>
    </div>
  )
}

export default Invite
