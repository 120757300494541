import React from 'react'
import { Link } from 'react-router-dom'

const Error404Page = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="text-center">
        <img
          src={require('../../assets/images/404_error.png')}
          alt="error_img"
          className="w-[600px]"
        />
        <p className="text-3xl mt-[-80px] font-bold">This URL doesn{"'"}t exist</p>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-[60px]">
          <Link to="/">Go Home</Link>
        </button>
      </div>
    </div>
  )
}

export default Error404Page
