// import axios from 'axios'
// import React, { useEffect, useState } from 'react'
// import { FaPlus, FaMinus } from 'react-icons/fa6'

// import ExpandableRow from './ExpandableRow'
// import { API_ENDPOINT } from '../../../../config/config'
// import Spinner from '../../../shared/Spinner/Spinner'
// import InviteModal from '../UserManagement/InviteModal'

// function AccessControls() {
//   const [openRow, setOpenRow] = useState(null)
//   const [users, setUsers] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [dimensions, setDimensions] = useState({})
//   const [updateControls, setUpdateControls] = useState(false)
//   const [showInviteModal, setShowInviteModal] = useState(false)

//   const toggleRow = name => {
//     setOpenRow(name === openRow ? null : name)
//   }
//   useEffect(() => {
//     const geographyApi = axios.get(API_ENDPOINT + '/api/geographies/',{withCredentials:true})
//     const productApi = axios.get(API_ENDPOINT + '/api/products/',{withCredentials:true})
//     const totalUsers = axios.get(API_ENDPOINT + '/api/access',{withCredentials:true})
//     setLoading(true)

//     Promise.all([geographyApi, productApi, totalUsers])
//       .then(res => {
//         setLoading(false)
//         setUsers(res[2].data.data)
//         const geographyPaths = res[0].data.data.data.map(item => ({
//           id: item.id,
//           key: item.id,
//           value: item.id,
//           name: item.path.join(' >> '),
//         }))
//         const productPaths = res[1].data.data.data.map(item => ({
//           id: item.id,
//           key: item.id,
//           value: item.id,
//           name: item.path.join(' >> '),
//         }))
//         setDimensions({ geographies: geographyPaths, products: productPaths })
//       })
//       .catch(err => {
//         // console.log(err)
//         setLoading(false)
//       })
//   }, [])
//   useEffect(() => {
//     axios
//       .get(API_ENDPOINT + '/api/access',{withCredentials:true})
//       .then(res => {
//         setUsers(res.data.data)
//       })
//       .catch(err => {
//         // console.log(err)
//       })
//   }, [updateControls])

//   return (
//     <>
//       {showInviteModal && (
//         <InviteModal
//           setShowInviteModal={setShowInviteModal}
//           showInviteModal={showInviteModal}
//           dimensions={dimensions}
//           setLoading={setLoading}
//           updateControls={updateControls}
//           setUpdateControls={setUpdateControls}
//         />
//       )}
//       <div className={`flex flex-col bg-white`}>
//         <div className="flex justify-end mb-2 mt-2 mr-2">
//           <button
//             className="bg-blue-100 text-blue-600 py-3 px-4 rounded-md hover:bg-blue-200 font-semibold  w-[150px] flex justify-center "
//             onClick={e => setShowInviteModal(!showInviteModal)}
//           >
//             <FaPlus size={'1.25em'} className="mx-1" />
//             INVITE
//           </button>
//         </div>
//         {loading && <Spinner />}
//         {users?.map(item => (
//           <ExpandableRow
//             updateControls={updateControls}
//             setUpdateControls={setUpdateControls}
//             setLoading={setLoading}
//             dimensions={dimensions}
//             key={item.id}
//             user={item}
//             isOpen={item.id === openRow}
//             toggleRow={() => toggleRow(item.id)}
//           />
//         ))}
//       </div>
//     </>
//   )
// }

// export default AccessControls
