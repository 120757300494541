import { GoogleOAuthProvider } from '@react-oauth/google'
import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import App from './App'
import Auth from './Auth/Auth'
import ChangePassword from './components/ChangePassword/ChangePassword'
import Error404Page from './components/Error404Page/Error404Page'
import RevenueChart from './components/Home/Alerts/Alerts'
import Clean from './components/Home/Clean/Clean'
import Connect from './components/Home/Connect/Connect'
import Dive from './components/Home/Dive/Dive'
import { mockDataRevenue } from './components/Home/Dive/ShowData/mockData'
import Home from './components/Home/Home'
import Metrics from './components/Home/Metrics/Metrics'
import Objects from './components/Home/Objects/Objects'
import Okr from './components/Home/Okr/Okr'
import Settings from './components/Home/Settings/Settings'
import Upload from './components/Home/Upload/Upload'
import Invite from './components/Invite/Invite'
import InviteLinkExpired from './components/Invite/InviteLinkExpired'
import LoginPage from './components/LoginPage/LoginPage'
import reduxStore from './config/Redux/ReduxStore'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <Error404Page />,
    children: [
      {
        path: '/',
        element: <App />,
      },
      {
        path: '/login',
        element: <Auth />,
        children: [
          {
            path: '',
            element: <LoginPage />,
          },
          {
            path: 'resetpassword',
            element: <Auth />,
            children: [
              {
                path: '',
                element: <ChangePassword />,
              },
            ],
          },
        ],
      },
      {
        path: '/home',
        element: <Auth />,
        children: [
          {
            path: '',
            element: <Home />,
            children: [
              {
                path: 'mis',
                element: <Dive />,
              },
              {
                path: 'upload',
                element: <Upload />,
              },
              {
                path: 'settings/*',
                element: <Settings />,
              },
              {
                path: 'performance',
                element: <Okr />,
              },
              {
                path: 'connect',
                element: <Connect />,
              },
              {
                path: 'clean',
                element: < Clean />,
              },
              {
                path: 'metrics',
                element: <Metrics/>,
              },
              // {
              //   path: 'alerts',
              //   element: (
              //     <RevenueChart />
              //   ),
              // },
              {
                path: 'data',
                element: <Objects />,
              },
            ],
          },
        ],
      },
      {
        path: '/invite',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Invite />,
          },
          {
            path: 'link-expired',
            element: <InviteLinkExpired />,
          },
        ],
      },
    ],
  },
])

function IndexRoot() {
  // const [data, setData] = useState(mockDataRevenue.data)
  return (
    <Provider store={reduxStore}>
      <div className="text-sm">
        <RouterProvider router={router} />
      </div>
    </Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<IndexRoot />)
