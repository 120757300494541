import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import * as React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogComponent({
  handleDeleteAndCancelUserBtn,
  setShowDialog,
  showDialog,
  warningTitle,
  warningText,
  yesButton,
  noButton,
}) {
  const handleOpen = () => {
    handleDeleteAndCancelUserBtn()
    setShowDialog(false)
  }
  const handleClose = () => {
    setShowDialog(false)
  }

  return (
    <React.Fragment>
      <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{warningTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{warningText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen}>{yesButton}</Button>
          <Button onClick={handleClose} style={{ backgroundColor: 'green', color: 'white' }}>
            {noButton}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
